import { TransactionAPI } from "@app/api/TransactionAPI";
import { getAccountName } from "@app/model/IDBHelpers";
import { MoneyFlowEntry } from "@app/model/ITransactionAPI";
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { ChartTooltip, Sankey, SankeyLabel, SankeyLink, SankeyNode, TooltipArea, TooltipTemplate, formatValue } from "reaviz";


export interface AccountSankeyProps extends WithTranslation {
    transactionAPI: TransactionAPI
    onAlert: (title: string, variant: string) => void
}

class AccountSankeyImpl extends React.Component<AccountSankeyProps> {

    state = {
        isLoading: true,
        overviewData: undefined as (undefined | MoneyFlowEntry[]),
    }

    componentDidMount(): void {
        this.props.transactionAPI.fetchMoneyFlow().then((result) => {
            let data = result.data!

            this.setState({ 
                overviewData: data,
                isLoading: false
            })
        })
    }

    renderSankeyNodes() {
        let nodes: JSX.Element[] = [];
        let seenSrc: number[] = [];
        let seenDest: number[] = [];

        if (!this.state.isLoading) {
            this.state.overviewData!.forEach(element => {
                if (!seenSrc.includes(element.src)) {
                    nodes.push(
                        <SankeyNode 
                            title={getAccountName(element.src)} id={`src-${element.src}`}
                            label={<SankeyLabel ellipsis="none" fill="--pf-v5-global--palette--blue-300" />} 
                        />
                    )
                    seenSrc.push(element.src);
                }
                if (!seenDest.includes(element.dest)) {
                    nodes.push(
                        <SankeyNode 
                            title={getAccountName(element.dest)} id={`dest-${element.dest}`}
                            label={<SankeyLabel ellipsis="none" fill="--pf-v5-global--palette--blue-300" />}
                        />
                    )
                    seenDest.push(element.dest);
                }
            })
        } else {
            nodes.push(<SankeyNode title="Empty" id="1" />)
            nodes.push(<SankeyNode title="Empty2" id="2" />)
        }

        return nodes
    }

    renderSankeyLinks() {
        let nodes: JSX.Element[] = [];

        if (!this.state.isLoading) {
            this.state.overviewData!.forEach(element => {
                nodes.push(
                    <SankeyLink source={`src-${element.src}`} target={`dest-${element.dest}`} value={Math.abs(element.total)} />
                )
            })
        } else {
            nodes.push(<SankeyLink source="1" target="2" value={0} gradient={false} />)
        }

        return nodes
    }

    render(): React.ReactNode {

        const sankey = (
            <Sankey
                tooltip={
                    <TooltipArea
                        tooltip={
                            <ChartTooltip
                                followCursor={true}
                                placement="bottom"
                            />
                        }
                    />
                }
                colorScheme="viridis"
                labelPosition="outside"
                nodes={this.renderSankeyNodes()}
                links={this.renderSankeyLinks()}
            />
        )

        return (
            <div style={{ margin: 'auto', width: '95%', height: '500px', textAlign: 'center' }}>                
                {sankey}
            </div>
        )
    }
}

export const AccountSankey = withTranslation()(AccountSankeyImpl);