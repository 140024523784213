import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Flex,
    FlexItem,
    PageSection,
} from '@patternfly/react-core';
import { TemplatePanel } from './EmailSettingsPage/TemplatePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';


export interface EmailSettingsPageProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class EmailSettingsPageImpl extends React.Component<EmailSettingsPageProps> {

    render() {
        const { t, onAlert, settingsAPI } = this.props;
        document.title = "Einstellungen";

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem><TemplatePanel onAlert={onAlert} settingsAPI={settingsAPI} name="signup" title="Neue Anmeldung"/></FlexItem>
                    <FlexItem><TemplatePanel onAlert={onAlert} settingsAPI={settingsAPI} name="resetpw" title="Passwort zurücksetzen"/></FlexItem>
                    <FlexItem><TemplatePanel onAlert={onAlert} settingsAPI={settingsAPI} name="inactive_notification" title="Benachrichtigung bei Inaktivität"/></FlexItem>
                    <FlexItem><TemplatePanel onAlert={onAlert} settingsAPI={settingsAPI} name="low_balance" title="Benachrichtigung bei Guthaben unter 3 Euro"/></FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const EmailSettingsPage = withTranslation()(EmailSettingsPageImpl);