import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    TextContent,
    AboutModal,
    TextList,
    TextListItem,
} from '@patternfly/react-core';


export interface AboutDialogProps extends WithTranslation {
    isOpen: boolean,
    onClose: () => void,
    frontendVersion: string,
    backendVersion: string
}

class AboutDialogImpl extends React.Component<AboutDialogProps> {
    render() {
        const { isOpen, t, onClose, frontendVersion, backendVersion } = this.props;

        return (
            <AboutModal
                isOpen={isOpen}
                onClose={onClose}
                trademark="BERTA developers"
                brandImageSrc=""
                brandImageAlt=""
                productName="BERTA"
            >
                <TextContent>
                    <TextList component="dl">
                        <TextListItem component="dt">Frontend Version</TextListItem>
                        <TextListItem component="dd">{frontendVersion}</TextListItem>
                        <TextListItem component="dt">Backend Version</TextListItem>
                        <TextListItem component="dd">{backendVersion}</TextListItem>
                    </TextList>
                </TextContent>
            </AboutModal>
        );
    }
}

export const AboutDialog = withTranslation()(AboutDialogImpl);