import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Flex,
    FlexItem,
    PageSection,
} from '@patternfly/react-core';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { TimeoutPanel } from './SettingsPage/TimeoutPanel';


export interface SettingsPageProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class SettingsPageImpl extends React.Component<SettingsPageProps> {

    render() {
        const { t, onAlert, settingsAPI } = this.props;
        document.title = "Einstellungen";

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem><TimeoutPanel onAlert={onAlert} settingsAPI={settingsAPI}/></FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const SettingsPage = withTranslation()(SettingsPageImpl);