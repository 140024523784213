import '@patternfly/react-core/dist/styles/base.css';
import '@app/styles/app.css';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import '@app/i18n';
import { JLoginPage } from '@app/ui/pages/BertaLoginPage';
import { ResetPasswordPage } from './ui/pages/LoginPage/ResetPasswordPage';
import { WithRouter, withRouter } from './RouterComponent';
import { UserPage } from './ui/pages/UserPage';
import { MainPage } from './ui/pages/MainPage';
import { BertaDashBoard } from './ui/BertaDashBoard';
import { BertaAPI } from './api/BertaAPI';
import { AccessCheck, RedirectState } from './AccessCheck';
import { AlertGroup, Alert, AlertVariant, AlertActionCloseButton, Bullseye, AlertActionLink } from '@patternfly/react-core';
import { SettingsPage } from './ui/pages/SettingsPage';
import { PaymentSettingsPage } from './ui/pages/SettingsPage/PaymentSettingsPage';
import { EmailSettingsPage } from './ui/pages/SettingsPage/EmailSettingsPage';
import { UsersPage } from './ui/pages/UsersPage';
import { AjaxError } from 'rxjs/ajax';
import { UserAdminPage } from './ui/pages/UserAdminPage';
import { RFIDRegisterCodePage } from './ui/pages/RFIDsPage/RFIDRegisterCodePage';
import { PayPalLogPage } from './ui/pages/PayPalLogPage';
import { StripeLogPage } from './ui/pages/StripeLogPage';
import { ProductPage } from './ui/pages/ProductPage';
import { RFIDsPage } from './ui/pages/RFIDsPage';
import { TransactionsPage } from './ui/pages/TransactionsPage';
import { StatsPage } from './ui/pages/StatsPage';
import { IUser } from './model/IUserAPI';
import { AccountsOverviewPage } from './ui/pages/TransactionsPage/AccountOverviewPage';
import { UserRegisterCodePage } from './ui/pages/UsersPage/UserRegisterCodePage';
import { APIKeyPage } from './ui/pages/SettingsPage/APIKeys';

interface AlertData {
    title: string,
    variant: string,
    key: number
}

class AppImpl extends React.Component<WithRouter> {
    private _api: BertaAPI;

    public state = {
        currentUser: undefined as (undefined | IUser),
        backendError: undefined as (undefined | AjaxError),
        alerts: [] as AlertData[]
    }

    constructor(props) {
        super(props);
        this._api = new BertaAPI();
    }

    componentDidMount() {
        this.fetchUserInfo();
    }

    fetchUserInfo = () => {
        return this._api.userAPI.fetchUser().then(
            (value) => {
                this.setState({ currentUser: value.data });
            },
            (error) => {
                if (error.isServerError) {
                    this.setState({ currentUser: undefined, backendError: error.error});
                }
                else {
                    this.addAlert(`Could not load login status: ${JSON.stringify(error)}`, "danger");
                    this.setState({ currentUser: undefined});
                }
            });
    }

    onLoggedIn = () => {
        this.fetchUserInfo().then(() => {
            const locState = this.props.location?.state as (undefined | RedirectState);
            const from = locState?.from?.pathname || "/";
            this.props.navigate(from);
        });
    }

    onLogout = () => {
        this._api.userAPI.logout().then(() => {
            this.fetchUserInfo();
        });
    }

    addAlert = (title: string, variant: string) => {
        const key = new Date().getTime();
        this.setState({
            alerts: [ ...this.state.alerts, { title: title, variant: variant, key }]
        });
    }

    public render() {

        const { currentUser, alerts, backendError } = this.state;

        if (backendError) {
            return (
                <Bullseye>
                    <Alert
                        variant="danger"
                        title="Backend Error"
                        actionLinks={
                            <React.Fragment>
                                <AlertActionLink onClick={() => {
                                    this.setState({backendError: undefined});
                                    this.fetchUserInfo();
                                }}>Retry</AlertActionLink>
                            </React.Fragment>
                        }
                    >
                        <p>There was an internal error trying to contact the backend. If the error persists, please contact the administrators.</p>
                        <p style={{marginTop: "0.5em", fontWeight: "bold"}}>Error Details:</p>
                        <p>
                            HTTP {backendError.status}: {backendError.message}<br/>
                            {backendError.response}
                        </p>
                    </Alert>
                </Bullseye>
            );
        }

        const loginPage = (
            <JLoginPage
                onLoggedIn={this.onLoggedIn}
                userAPI={this._api.userAPI}
            />
        );

        const mainPage = (
            <MainPage currentUser={currentUser} statsAPI={this._api.statsAPI}
                depositAPI={this._api.depositAPI} transferAPI={this._api.transferAPI} onAlert={this.addAlert}/>
        )

        const dashBoard = (
            <AccessCheck currentUser={currentUser}>
                <BertaDashBoard
                    onLogout={this.onLogout}
                    currentUser={currentUser}
                    systemAPI={this._api.systemAPI}
                    onAlert={this.addAlert} />
            </AccessCheck>
        );

        const transactionsPage = (
            <TransactionsPage
                onAlert={this.addAlert} 
                transferAPI={this._api.transferAPI} 
                statsAPI={this._api.statsAPI}
                userAPI={this._api.userAPI}
            />
        )

        const productPage = (
            <ProductPage onAlert={this.addAlert} productAPI={this._api.productAPI} />
        )

        const userPage = (
            <UserPage userAPI={this._api.userAPI} uid={currentUser?.id} onAlert={this.addAlert} rfidAPI={this._api.rfidAPI}/>
        )

        const rfidsPage = (
            <RFIDsPage onAlert={this.addAlert} rfidAPI={this._api.rfidAPI} />
        )

        const userRegisterCodePage = (
            <UserRegisterCodePage onAlert={this.addAlert} userAPI={this._api.userAPI} />
        )

        const rfidRegisterCodePage = (
            <RFIDRegisterCodePage onAlert={this.addAlert} rfidAPI={this._api.rfidAPI} />
        )

        return (
            <React.Fragment>
                <AlertGroup isToast isLiveRegion>
                    {alerts.map(({ key, variant, title }) => (
                        <Alert
                            variant={AlertVariant[variant]}
                            title={title}
                            timeout={3000}
                            actionClose={
                                <AlertActionCloseButton
                                    title={title}
                                    variantLabel={`${variant} alert`}
                                    onClose={() => {
                                        this.setState({ alerts: [...this.state.alerts.filter(el => el.key !== key)] });
                                    }}
                                />
                            }
                            key={key} />
                    ))}
                </AlertGroup>
                <Routes>
                    <Route path="/login" element={loginPage} />
                    <Route path="/login/reset-password" element={loginPage} />
                    <Route path="/register" element={loginPage} />
                    <Route path="/code" element={<ResetPasswordPage userAPI={this._api.userAPI} onFinished={() => this.props.navigate("/")} />} />
                    <Route path="/" element={dashBoard}>
                        <Route index element={mainPage} />
                        <Route path="top-up" element={mainPage} />
                        <Route path="me">
                            <Route index element={userPage} />
                            <Route path="deleteRfid/:id" element={userPage} />
                        </Route>
                        <Route path="products" element={productPage} />
                        <Route path="products/new" element={productPage} />
                        <Route path="users">
                            <Route index element={<UsersPage onAlert={this.addAlert} userAPI={this._api.userAPI} />} />
                            <Route path="register_codes">
                                <Route index element={userRegisterCodePage} />
                                <Route path="delete/:id" element={userRegisterCodePage}
                                    action={async({ params, request }) => {
                                        return this._api.rfidAPI.removeRFID(Number(params.id));
                                    }} />
                            </Route>
                            <Route path=":uid">
                                <Route index element={<UserAdminPage onAlert={this.addAlert} userAPI={this._api.userAPI} />} />
                            </Route>
                        </Route>
                        <Route path="transactions">
                            <Route index element={transactionsPage} />
                            <Route path="paypal" element={<PayPalLogPage onAlert={this.addAlert} depositAPI={this._api.depositAPI}/>}/>
                            <Route path="stripe" element={<StripeLogPage onAlert={this.addAlert} depositAPI={this._api.depositAPI}/>}/>
                            <Route path="create" element={transactionsPage} />
                            <Route path=":uid" element={transactionsPage} />
                            <Route path=":uid/create" element={transactionsPage} />
                            <Route path="account_overview" element={<AccountsOverviewPage onAlert={this.addAlert} transactionAPI={this._api.transferAPI}/>} />
                        </Route>
                        <Route path="rfids">
                            <Route index element={rfidsPage} />
                            <Route path="deleteRfid/:id" element={rfidsPage} />
                            <Route path="register_codes">
                                <Route index element={rfidRegisterCodePage}/>
                                <Route path="delete/:id" element={rfidRegisterCodePage}/>
                            </Route>
                        </Route>
                        <Route path="stats" element={<StatsPage onAlert={this.addAlert} statsAPI={this._api.statsAPI}/>}/>
                        <Route path="settings">
                            <Route index element={<SettingsPage onAlert={this.addAlert} settingsAPI={this._api.settingsAPI} />}/>
                            <Route path="payment" element={<PaymentSettingsPage onAlert={this.addAlert} settingsAPI={this._api.settingsAPI} />} />
                            <Route path="email" element={<EmailSettingsPage onAlert={this.addAlert} settingsAPI={this._api.settingsAPI} />} />
                            <Route path="apiKeys" element={<APIKeyPage onAlert={this.addAlert} settingsAPI={this._api.settingsAPI} />} />
                            <Route path="apiKeys/delete/:id" element={<APIKeyPage onAlert={this.addAlert} settingsAPI={this._api.settingsAPI} />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace />}></Route>
                </Routes>
            </React.Fragment>
        );
    }
}

export const App = withRouter(AppImpl);