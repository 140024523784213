import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { TableText, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { IRfidAPI, IRFIDInfo } from '@app/model/IRfidAPI';
import { Button } from '@patternfly/react-core';
import { TrashIcon } from '@patternfly/react-icons';
import { formatDateStr } from '@app/i18n';

export interface RFIDTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    rfidAPI: IRfidAPI,
    uid?: number,
    onRemoveRfid: (id: number) => void,
    reloadCounter?: number,
}

class RFIDsTableImpl extends RemoteTable<IRFIDInfo, RFIDTableProps> {

    headers = () => {
        let fields = [] as IRowHeader[]

        if (!this.props.uid){
            fields.push({name: "ID", defaultSort: "desc", sortKey: "id"});
            fields.push({name: this.props.t("berta:rfids.table.user"), defaultSort: "asc", sortKey: "uid"});
            fields.push({name: this.props.t("berta:rfids.table.username"), defaultSort: "asc", sortKey: "full_user_name"});
        }

        fields.push({name: this.props.t("berta:rfids.table.tag"), defaultSort: "asc", sortKey: "rfid"});
        fields.push({name: this.props.t("berta:rfids.table.comment"), defaultSort: "asc", sortKey: "comment"});
        fields.push({name: this.props.t("berta:rfids.table.lastUsed"), defaultSort: "asc", sortKey: "last_used"});
        fields.push({name: ""});

        return fields
     }

    defaultSortIndex = 0;
    hasFilter = true;

    _extraFilter = {
        counter: this.props.reloadCounter,
        uid: this.props.uid
    }

    getExtraFilter() {
        return this._extraFilter;
    }

    componentDidUpdate(prevProps: Readonly<RFIDTableProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.reloadCounter != this.props.reloadCounter){
            this._extraFilter = {
                counter: this.props.reloadCounter,
                uid: this.props.uid,
            }
        }
    }

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IRFIDInfo>, void>> {
        let uid : number;
        uid = query.extra.uid === undefined ? 0 : query.extra.uid;

        return this.props.rfidAPI.fetchRFIDs(query, uid, this.props.uid === undefined ? true : false);
    }

    renderRow(row: IRFIDInfo): JSX.Element {

        let adminColumns = [] as JSX.Element[]

        if (!this.props.uid){
            adminColumns = [
                (<Td>{row.id}</Td>),
                (<Td>{row.uid}</Td>),
                (<Td>{row.full_user_name}</Td>)
            ];
        }

        return (<Tr key={row.id}>
            {...adminColumns}
            <Td>{row.rfid}</Td>
            <Td>{row.comment}</Td>
            <Td>{formatDateStr(row.last_used)}</Td>
            <Td dataLabel="Buchen" modifier="fitContent">
                <TableText>
                    <Button variant="plain" onClick={() => { this.props.onRemoveRfid(row.id) }}><TrashIcon /></Button>
                </TableText>
            </Td>
        </Tr>);
    }
}

export const RFIDsTable = withTranslation()(withRouter(RFIDsTableImpl));