import { encodeQueryData, RemoteConnection } from "@app/RemoteConnection";
import { TableFilterQuery, TableRowData } from "@app/model/IDBHelpers";
import { IProductAPI, IProductEntry } from "@app/model/IProductAPI";



export class ProductAPI implements IProductAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchProducts(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IProductEntry>, void>(`products?${args}`);
    }

    updateProduct(product: IProductEntry) {
        return this._connection.postPromise<boolean, void>(`product/${product.id}`, product);
    }

    createProduct(product: IProductEntry) {
        return this._connection.postPromise<boolean, void>(`product/create`, product);
    }

    deleteProduct(productId: number) {
        return this._connection.postPromise<boolean, void>(`product/delete`, productId);
    }
}