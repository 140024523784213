import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Flex,
    FlexItem,
    PageSection,
} from '@patternfly/react-core';
import { UserdataPanel } from './UserSettingsPage/UserdataPanel';
import { IUserAPI } from '@app/model/IUserAPI';


export interface UserAdminPageProps extends WithTranslation, WithRouter {
    userAPI: IUserAPI,
    onAlert: (title: string, variant: string) => void
}

class UserAdminPageImpl extends React.Component<UserAdminPageProps> {

    render() {
        const { t, userAPI, onAlert, params } = this.props;
        document.title = "Benutzeradministration";

        const id = (params as any).uid;

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    { id &&
                        <FlexItem><UserdataPanel uid={Number(id)} userAPI={userAPI} onAlert={onAlert} isAdmin={true}/></FlexItem>
                    }
                </Flex>
            </PageSection>
        );
    }
}

export const UserAdminPage = withTranslation()(withRouter(UserAdminPageImpl));