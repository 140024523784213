import '@patternfly/react-core/dist/styles/base.css';
import '@app/styles/app.css';


import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    DropdownItem,
    Flex,
    Spinner,
    Bullseye,
    Dropdown,
    MenuToggle,
    DropdownList,
    EmptyStateVariant,
    EmptyStateIcon,
    EmptyState,
    Title
} from '@patternfly/react-core';
import { BuyCountEntry } from '@app/model/IStatsAPI';
import { SearchIcon } from '@patternfly/react-icons';
import { BarList, BarListSeries } from 'reaviz';

export interface BuyCardProps extends WithTranslation {
    products?: BuyCountEntry[],
    isLoading: boolean,
    onDurationChange?: (string) => void
}

class BuyCardImpl extends React.Component<BuyCardProps> {
    public state = {
        dropdownOpen: false,
        dropdownSelection: 3
    };    

    render() {
        const { products, t, isLoading } = this.props;
        const { dropdownOpen, dropdownSelection } = this.state;

        let chartData: any[] = [];

        if (products) {
            chartData = products.map(val => {
                return (
                    { key: val.product_name, data: val.count}
                );
            });
        }

        const onSelect = (_event, value) => {
            const index = dropdownKeys.indexOf(value as string);
            this.setState({
                dropdownOpen: !dropdownOpen,
                dropdownSelection: index
            });
            if (this.props.onDurationChange) {
                this.props.onDurationChange(dropdownKeys[index]);
            }
        };

        const dropdownKeys = ["week", "month", "year", "total"];
        const dropdownNames = dropdownKeys.map(val => t("berta:buyCard.duration." + val));
        const dropdownEntries = dropdownKeys.map((val, i) => {
            return (<DropdownItem value={val} key={i}>{t("berta:buyCard.duration." + val)}</DropdownItem>)
        });

        const headerActions = (
            <Dropdown 
                isOpen={dropdownOpen} 
                onOpenChange={(isOpen) => (this.setState({dropdownOpen: isOpen}))}
                onSelect={onSelect} 
                shouldFocusToggleOnSelect
                toggle={(toggleRef) => (
                    <MenuToggle 
                        ref={toggleRef} 
                        isExpanded={dropdownOpen} 
                        onClick={() => (this.setState({ dropdownOpen: !dropdownOpen }))}
                    >
                        {dropdownNames[dropdownSelection]}
                    </MenuToggle>
                )}
            >
                <DropdownList>{dropdownEntries}</DropdownList>
            </Dropdown>
        );

        return (
            <Card>
                <CardHeader actions={{ actions: headerActions }}>
                <CardTitle>{t("berta:buyCard.title")}</CardTitle>
                </CardHeader>
                <CardBody>
                    <Flex direction={{ default: 'column' }} >
                        {isLoading &&
                            <Spinner size="lg" />
                        }
                        {!isLoading && this.props.products?.length == 0 &&
                            <Bullseye>
                                <EmptyState variant={EmptyStateVariant.sm}>
                                    <EmptyStateIcon icon={SearchIcon} />
                                    <Title headingLevel="h2" size="lg">
                                        {t("berta:queries.noResults")}
                                    </Title>
                                </EmptyState>
                            </Bullseye>
                        }
                        {!isLoading && this.props.products?.length != 0 &&
                        <BarList 
                            style={{width:"100%"}}
                            data={chartData}
                            series={<BarListSeries labelPosition='top' valuePosition='end' />}
                        />
                        }
                    </Flex>
                </CardBody>
            </Card>
        );
    }
}

export const BuyCard = withTranslation()(BuyCardImpl);