import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    FormHelperText,
    HelperText,
    HelperTextItem,
    TextArea,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { IEmailTemplate } from '@app/model/ISettingsAPI';
import { ExclamationCircleIcon } from '@patternfly/react-icons';

export interface TemplatePanelProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI,
    name: string,
    title: string
}

class TemplatePanelImpl extends React.Component<TemplatePanelProps> {
    public state = {
        isLoading: true,
        saveStatus: undefined as (undefined | "success" | "saving"),
        data: {subject: "", text: ""} as IEmailTemplate,

        // Validation
        subjectValidated: undefined as (undefined | "error"),
        textValidated: undefined as (undefined | "error"),
    }

    validateSubject(subject: string) {
        this.setState({ subjectValidated: subject.length > 0 ? undefined : "error" })
        return subject.length > 0;
    }

    validateText(text: string) {
        this.setState({ textValidated: text.length > 0 ? undefined : "error" })
        return text.length > 0;
    }

    validateInputs() {
        return this.validateText(this.state.data.text) && this.validateSubject(this.state.data.subject);
    }

    componentDidMount() {
        this.props.settingsAPI.fetchEmailTemplate(this.props.name).then(value => {
            const data = value.data!;
            this.setState({
                data: data,
                isLoading: false
            });
        }).catch(error => {
            this.props.onAlert(`Could not load email template: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    componentDidUpdate(prevProps: Readonly<TemplatePanelProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (!this.state.isLoading && 
            this.state.data != prevState.data) {
            this.validateInputs();
        }
    }

    onSave = () => {
        if (this.validateInputs()) {
            this.setState({ saveStatus: "saving" });
            this.props.settingsAPI.postEmailTemplate(this.props.name, this.state.data).then(value => {
                if (value.data) {
                    this.setState({ saveStatus: "success" });
                }
                else {
                    this.setState({ saveStatus: "failed" });
                }
            }).catch(error => {
                const msg = error.error.message ? error.error.message : "";
                this.props.onAlert(`Could not store email template: ${msg}`, "danger");
                this.setState({ saveStatus: "failed" });
            });
        }
    }

    render() {
        const { t, title } = this.props;
        const { saveStatus, data, isLoading, textValidated, subjectValidated } = this.state;

        const allOK = (textValidated !== "error" && subjectValidated !== "error");

        return (
            <SaveablePanel
                title={title}
                saveStatus={saveStatus}
                isLoading={isLoading}
                onSave={this.onSave}
                saveDisabled={!allOK}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <FormGroup
                        label="Betreff"
                        fieldId="subject"
                    >
                        <TextInput
                            value={data.subject}
                            validated={subjectValidated}
                            id="subject"
                            onChange={(_event, value) => {
                                let data = this.state.data;
                                data.subject = value;
                                this.setState({ data: data });
                                this.validateSubject(value);
                            }}
                        />
                        <FormHelperText hidden={subjectValidated !== "error"}>
                            <HelperText>
                                <HelperTextItem variant={subjectValidated} {...(subjectValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                    May not be empty
                                </HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    </FormGroup>
                    <FormGroup
                        label="Text:"
                        fieldId="text"
                    >
                        <TextArea
                            resizeOrientation='vertical'
                            autoResize
                            validated={subjectValidated}
                            value={data.text}
                            id={"ta-" + data.subject}
                            onChange={(_event, value) => {
                                let data = this.state.data;
                                data.text = value;
                                this.setState({ data: data });
                                this.validateText(value);
                            }}
                        />
                        <FormHelperText hidden={textValidated !== "error"}>
                            <HelperText>
                                <HelperTextItem variant={textValidated} {...(textValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                    May not be empty
                                </HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    </FormGroup>
                </Form>
            </SaveablePanel>
        );
    }
}

export const TemplatePanel = withTranslation()(TemplatePanelImpl);