import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Checkbox,
    Form,
    FormGroup,
    FormHelperText,
    HelperText,
    HelperTextItem,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { IUserAPI } from '@app/model/IUserAPI';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { formatDate } from '@app/i18n';


export interface UserdataPanelProps extends WithTranslation {
    isAdmin?: boolean,
    userAPI: IUserAPI,
    uid: number,
    onAlert: (title: string, variant: string) => void
}

class UserdataPanelImpl extends React.Component<UserdataPanelProps> {
    public state = {
        saveStatus: undefined as (undefined | "success" | "saving"),
        firstname: "",
        lastname: "",
        email: "",
        oldPassword: "",
        oldPasswordValidated: undefined as (undefined | "error"),
        password: "",
        passwordValidated: undefined as (undefined | "error"),
        repeat: "",
        repeatValidated: undefined as (undefined | "error"),

        firstnameValid: true,
        lastnameValid: true,
        emailValid: true,

        snack_access: false,
        admin: false,
        active: false,

        // Miscs
        last_login: new Date(),
        register_date: new Date(),
        isLoading: true,
    }

    validateOldPassword(old: string) {
        const isOK = this.state.password.length == 0 || old.length > 0;
        if (isOK) {
            this.setState({ oldPasswordValidated: undefined });
            return true;
        } else {
            this.setState({ oldPasswordValidated: "error" });
            return false;
        }
    }

    validatePassword(password: string) {
        if (password.length >= 6 || password.length == 0) {
            this.setState({ passwordValidated: undefined });
            return true;
        } else {
            this.setState({ passwordValidated: "error" });
            return false;
        }
    }

    validateRepeat(repeat: string) {
        if (this.state.password == repeat) {
            this.setState({ repeatValidated: undefined });
            return true;
        } else {
            this.setState({ repeatValidated: "error" });
            return false;
        }
    }

    validateFields() {
        let result = this.state.firstnameValid && this.state.lastnameValid && this.state.emailValid;
        result = this.validatePassword(this.state.password) && result;
        result = this.validateRepeat(this.state.repeat) && result;
        if (this.props.isAdmin != true)
            result = this.validateOldPassword(this.state.oldPassword) && result;
        return result;
    }

    componentDidMount() {
        this.props.userAPI.fetchUser(this.props.uid).then(value => {
            const data = value.data!;
            const reg_date = new Date(data.register_date);
            const last_login = new Date(data.last_login);
            this.setState({
                firstname: data.firstname,
                lastname: data.lastname,
                email: data.email,
                register_date: reg_date,
                last_login: last_login,
                snack_access: data.snack_access,
                admin: data.admin,
                active: data.active,
                isLoading: false});
        }).catch(error => {
            this.props.onAlert(`Could not load user info: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    componentDidUpdate(prevProps: Readonly<UserdataPanelProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (!this.state.isLoading && 
            this.state.firstname !== prevState.firstname) {
            this.validateFields();
        }
    }

    onSave = () => {
        if (this.validateFields()) {
            this.setState({ saveStatus: "saving" });
            let info = {
                id: this.props.uid,
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                snack_access: this.state.snack_access,
                admin: this.state.admin,
                active: this.state.active,
                balance: 0
            };
            this.props.userAPI.updateUser(info, this.state.oldPassword, this.state.password).then(value => {
                if (value.data) {
                    this.setState({ saveStatus: "success" });
                }
                else {
                    this.setState({ saveStatus: "failed" });
                }
            }).catch(error => {
                const msg = error.error.message ? error.error.message : "";
                this.props.onAlert(`Could not store user info: ${msg}`, "danger");
                this.setState({ saveStatus: "failed" });
            });
        }
    }

    render() {
        const { t, isAdmin } = this.props;
        const { saveStatus,
            firstname, firstnameValid, lastname, lastnameValid,
            email, emailValid, password, passwordValidated, repeat, repeatValidated,
            register_date, last_login, snack_access, admin, active, isLoading, oldPassword,
            oldPasswordValidated } = this.state;

        const allOK = (firstnameValid && lastnameValid && emailValid && 
            passwordValidated != "error" &&
            repeatValidated != "error") && 
            oldPasswordValidated != "error";

        // const now 
        const millisToDays = (1000 * 3600 * 24)
        const last_login_diff = Math.ceil(Math.abs(last_login.getTime() - Date.now()) / millisToDays);
        const registered_diff = Math.ceil(Math.abs(register_date.getTime() - Date.now()) / millisToDays);

        return (
            <SaveablePanel
                title={t("berta:userSettings.panelTitle")}
                saveStatus={saveStatus}
                isLoading={isLoading}
                saveDisabled={!allOK}
                onSave={this.onSave}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <ValidatableInputField
                        title={t("inputfields:firstname")}
                        inputId='firstname'
                        validateFor={ValidationOptions.LengthMoreThan0}
                        value={firstname}
                        isDisabled={!isAdmin}
                        onChange={(value, valid) => {
                            this.setState({ firstname: value, firstnameValid: valid })
                        }}
                    />
                    <ValidatableInputField
                        title={t("inputfields:lastname")}
                        inputId='lastname'
                        validateFor={ValidationOptions.LengthMoreThan0}
                        value={lastname}
                        isDisabled={!isAdmin}
                        onChange={(value, valid) => {
                            this.setState({ lastname: value, lastnameValidated: valid })
                        }}
                    />
                    <ValidatableInputField
                        title={t("inputfields:email")}
                        inputId='email'
                        validateFor={ValidationOptions.Email}
                        value={email}
                        isDisabled={!isAdmin}
                        onChange={(value, valid) => {
                            this.setState({ email: value, emailValidated: valid })
                        }}
                    />
                    {!isAdmin &&
                        <FormGroup label={t("inputfields:oldPassword")} fieldId='password'>
                            <TextInput id="oldpassword" type="password" value={oldPassword} validated={oldPasswordValidated}
                                onChange={(_event, value) => {
                                    this.setState({ oldPassword: value });
                                    this.validateOldPassword(value);
                                }}
                            />
                            <FormHelperText hidden={oldPasswordValidated !== "error"}>
                                <HelperText>
                                    <HelperTextItem variant={oldPasswordValidated} {...(oldPasswordValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                        {t("inputfields:errors.empty")}
                                    </HelperTextItem>
                                </HelperText>
                            </FormHelperText>
                        </FormGroup>}
                    <FormGroup label={t("inputfields:password")} fieldId='password'>
                        <TextInput id="password" type="password" value={password} validated={passwordValidated}
                            onChange={(_event, value) => {
                                this.setState({ password: value });
                                this.validatePassword(value);
                            }}
                        />
                        <FormHelperText hidden={passwordValidated !== "error"}>
                            <HelperText>
                                <HelperTextItem variant={passwordValidated} {...(passwordValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                    {t("inputfields:errors.password")}
                                </HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    </FormGroup>
                    <FormGroup label={t("inputfields:repeatPassword")} fieldId='repeat'>
                        <TextInput id="repeat" type="password" value={repeat} validated={repeatValidated}
                            onChange={(_event, value) => {
                                this.setState({ repeat: value });
                                this.validateRepeat(value);
                            }}
                        />
                        <FormHelperText hidden={repeatValidated !== "error"}>
                            <HelperText>
                                <HelperTextItem variant={repeatValidated} {...(repeatValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                    Must be the same password
                                        {t("inputfields:errors.passwortEqual")}
                                </HelperTextItem>
                            </HelperText>
                        </FormHelperText>
                    </FormGroup>
                    {isAdmin && <React.Fragment>
                        <FormGroup
                            label={t("inputfields:fridgeAccess")}
                            fieldId="snack_access"
                        >
                            <Checkbox
                                id="snack_access"
                                aria-describedby="snack_access-helper"
                                isChecked={snack_access}
                                onChange={(_event, value) => this.setState({snack_access: value})}
                            />
                        </FormGroup>
                        <FormGroup
                            label={t("inputfields:admin")}
                            fieldId="admin"
                        >
                            <Checkbox
                                id="admin"
                                aria-describedby="admin-helper"
                                isChecked={admin}
                                onChange={(_event, value) => this.setState({admin: value})}
                            />
                        </FormGroup>
                        <FormGroup
                            label={t("inputfields:activated")}
                            fieldId="active"
                        >
                            <Checkbox
                                id="active"
                                aria-describedby="active-helper"
                                isChecked={active}
                                onChange={(_event, value) => this.setState({active: value})}
                            />
                        </FormGroup>
                        <FormGroup
                            label={t("inputfields:lastLogin")}
                            fieldId="last_login"
                        >
                            <TextInput
                                value={formatDate(last_login) + " - vor " + last_login_diff + " Tag(en)"}
                                id="last_login"
                                aria-describedby="last_login-helper"
                                isDisabled={true}
                            />
                        </FormGroup>
                        <FormGroup
                            label={t("inputfields:registered")}
                            fieldId="registered"
                        >
                            <TextInput
                                value={formatDate(register_date) + " - vor " + registered_diff + " Tag(en)"}
                                id="registered"
                                aria-describedby="registered-helper"
                                isDisabled={true}
                            />
                        </FormGroup>
                    </React.Fragment>}
                </Form>
            </SaveablePanel>
        );
    }
}

export const UserdataPanel = withTranslation()(UserdataPanelImpl);