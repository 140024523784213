import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Divider,
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';
import { TransactionAPI } from '@app/api/TransactionAPI';
import { AccountStatementTable } from './AccountStatementTable';
import { AccountSankey } from './AccountSankey';


export interface AccountsOverviewPageProps extends WithTranslation, WithRouter {
    transactionAPI: TransactionAPI
    onAlert: (title: string, variant: string) => void
}

class AccountsOverviewPageImpl extends React.Component<AccountsOverviewPageProps> {

    render() {
        document.title = "Account overview";

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>Account Overview</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <AccountStatementTable onAlert={this.props.onAlert} transactionAPI={this.props.transactionAPI} />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>Money flow</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <AccountSankey onAlert={this.props.onAlert} transactionAPI={this.props.transactionAPI}/>
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const AccountsOverviewPage = withTranslation()(withRouter(AccountsOverviewPageImpl));