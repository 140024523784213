import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Button,
    Form,
    FormGroup,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';
import { RFIDAddDialog } from './RFIDDialog';
import { RFIDsTable } from '../RFIDsPage/RFIDTable';
import { IRfidAPI } from '@app/model/IRfidAPI';
import { matchPath } from 'react-router-dom';


export interface RFIDPanelProps extends WithTranslation, WithRouter {
    rfidAPI: IRfidAPI,
    uid: number,
    onAlert: (title: string, variant: string) => void
}

class RFIDPanelImpl extends React.Component<RFIDPanelProps> {
    public state = {
        dialogOpen: false,
        reloadCounter: 0
    }

    onConfirm()
    {
        this.setState({ dialogOpen: false, reloadCounter: this.state.reloadCounter + 1 });
    }

    render() {
        const { t, rfidAPI, uid, params } = this.props;
        const { dialogOpen, reloadCounter } = this.state;

        const deleteRfidId = Number((params as any).id);
        const deleteRfid = matchPath("/me/deleteRfid/:id", location.pathname) ? true : false;

        if (deleteRfid) {
            this.props.rfidAPI.removeRFID(deleteRfidId, uid);
            this.setState({reloadCounter: reloadCounter + 1});
            this.props.onAlert(t("berta:rfids.table.deleteSuccess"), "success");

            this.props.navigate(`/me`);
        }

        return (
            <React.Fragment>
                <RFIDAddDialog
                    isOpen={dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                    onSubmitted={() => { 
                        this.onConfirm();
                    }}
                    uid={uid}
                    rfidAPI={rfidAPI}
                />
                <Panel variant="raised">
                    <PanelHeader>{t("berta:rfids.panelTitle")}</PanelHeader>
                    <PanelMain>
                    <PanelMainBody>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.onConfirm();
                            }}
                        >
                        
                        <FormGroup fieldId='rfidPanel'>
                            <Button variant="primary" onClick={() => {this.setState({dialogOpen: true})}}>{t("berta:rfids.addNew")}</Button>
                            <RFIDsTable 
                                onAlert={this.props.onAlert}
                                onRemoveRfid={(id) => {this.props.navigate(`deleteRfid/${id}`);}}
                                rfidAPI={this.props.rfidAPI}
                                reloadTriggerCounter={reloadCounter}
                                uid={uid}
                            />
                        </FormGroup>
                        </Form>
                    </PanelMainBody>
                    </PanelMain>
                </Panel>
            </React.Fragment>
        );
    }
}

export const RFIDPanel = withTranslation()(withRouter(RFIDPanelImpl));