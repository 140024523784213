import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';
import { IUserAPI } from '@app/model/IUserAPI';



import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { TableText, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { Button } from '@patternfly/react-core';
import { IRfidAPI, IRFIDRegisterCode } from '@app/model/IRfidAPI';
import { TrashIcon } from '@patternfly/react-icons';
import { matchPath } from 'react-router-dom';
import { formatDateStr } from '@app/i18n';

export interface RFIDRegisterCodeTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    rfidAPI: IRfidAPI,
    onRemove: (id: number) => void,
}

class RFIDRegisterCodeTableImpl extends RemoteTable<IRFIDRegisterCode, RFIDRegisterCodeTableProps> {
    public state = {
        rows: [] as IRFIDRegisterCode[],
        isLoading: true,
        totalRows: 0,
    }

    headers = () => [
        {name: "ID", defaultSort: "asc", sortKey: "id"},
        {name: "Register Code", defaultSort: "asc", sortKey: "register_code"},
        {name: "RFID", defaultSort: "asc", sortKey: "rfid"},
        {name: "Datum", defaultSort: "asc", sortKey: "date"},
        {name: ""},
    ] as IRowHeader[];

    defaultSortIndex = 0;
    hasFilter = true;


    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IRFIDRegisterCode>, void>> {
        return this.props.rfidAPI.fetchRFIDRegisterCodes(query);
    }

    renderRow(row: IRFIDRegisterCode): JSX.Element {
        return (<Tr>
            <Td>{row.id}</Td>
            <Td>{row.register_code}</Td>
            <Td>{row.rfid}</Td>
            <Td>{formatDateStr(row.date)}</Td>
            <Td dataLabel="Delete" modifier="fitContent">
                <TableText>
                    <Button variant="plain" onClick={() => {this.props.onRemove(row.id)}}><TrashIcon /></Button>
                </TableText>
            </Td>
        </Tr>);
    }
}

const RFIDRegisterCodeTable = withTranslation()(withRouter(RFIDRegisterCodeTableImpl));


export interface RFIDRegisterCodePageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    rfidAPI: IRfidAPI
}

class RFIDRegisterCodePageImpl extends React.Component<RFIDRegisterCodePageProps> {
    public state = {
        reloadCounter: 0
    }

    render() {
        const { t, rfidAPI, onAlert, params, navigate } = this.props;
        const { reloadCounter } = this.state;

        document.title = "RFID Register Codes";

        const deleteCodeId = Number((params as any).id);
        const deleteCode = matchPath("/rfids/register_codes/delete/:id", location.pathname) ? true : false;

        if (deleteCode) {
            this.props.rfidAPI.deleteRFIDRegisterCode(deleteCodeId);
            this.setState({ reloadCounter: reloadCounter + 1 });
            this.props.onAlert("RFID Registration Code erfolgreich gelöscht", "success");

            this.props.navigate(`/rfids/register_codes`);
        }


        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelMain>
                                <PanelMainBody>
                                    <RFIDRegisterCodeTable
                                        onAlert={onAlert}
                                        rfidAPI={rfidAPI}
                                        reloadTriggerCounter={reloadCounter}
                                        onRemove={(id) => {this.props.navigate(`delete/${id}`);}}
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const RFIDRegisterCodePage = withTranslation()(withRouter(RFIDRegisterCodePageImpl));