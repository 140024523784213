import { encodeQueryData, RemoteConnection } from "@app/RemoteConnection";
import { AccountOverviewEntry, ITransactionAPI, MoneyFlowEntry, Transaction } from "@app/model/ITransactionAPI";
import { TableFilterQuery, TableRowData } from "@app/model/IDBHelpers";

export class TransactionAPI implements ITransactionAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchTransactions(query: TableFilterQuery, uid?: number, 
            fetchFullName?: boolean, accountDest?: number, accountSrc?: number,
            accountOperator?: "AND" | "OR") {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "date_from": query.from ? query.from.toISOString() : undefined,
            "date_to": query.to ? query.to.toISOString() : undefined,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter,
            "uid": uid,
            "fetchFullName": fetchFullName,
            "accountDest": accountDest,
            "accountSrc": accountSrc,
            "operator": accountOperator,
        });
        return this._connection.getPromise<TableRowData<Transaction>, void>(`transactions?${args}`);
    }

    public createTransaction(t: Transaction) {
        return this._connection.postPromise<boolean, void>(`transaction/create`, t);
    }
    
    public fetchAccountOverview() {
        return this._connection.getPromise<AccountOverviewEntry[], void>(`transaction/account_overview`);
    }

    public fetchMoneyFlow() {
        return this._connection.getPromise<MoneyFlowEntry[], void>(`transaction/money_flow`);
    }
}