import { RemoteConnection } from "@app/RemoteConnection";
import { UserAPI } from "@app/api/UserAPI";
import { StatsAPI } from "@app/api/StatsAPI";
import { SystemAPI } from "@app/api/SystemAPI";
import { DepositAPI } from "@app/api/DepositAPI";
import { SettingsAPI } from "./SettingsAPI";
import { TransactionAPI } from "./TransactionAPI";
import { ProductAPI } from "./ProductAPI";
import { RfidAPI } from "./RfidAPI";


export class BertaAPI {
    private _connection: RemoteConnection;

    constructor() {
        const baseURL = window.location.origin + "/api/";
        this._connection = new RemoteConnection(baseURL);
        this.userAPI = new UserAPI(this._connection);
        this.statsAPI = new StatsAPI(this._connection);
        this.systemAPI = new SystemAPI(this._connection);
        this.depositAPI = new DepositAPI(this._connection);
        this.settingsAPI = new SettingsAPI(this._connection);
        this.transferAPI = new TransactionAPI(this._connection);
        this.productAPI = new ProductAPI(this._connection);
        this.rfidAPI = new RfidAPI(this._connection);
    }


    public userAPI: UserAPI;
    public statsAPI: StatsAPI;
    public systemAPI: SystemAPI;
    public depositAPI: DepositAPI;
    public settingsAPI: SettingsAPI;
    public transferAPI: TransactionAPI;
    public productAPI: ProductAPI;
    public rfidAPI: RfidAPI;
}