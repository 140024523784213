import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Button,
    Divider,
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';

import { ProductTable } from './ProductPage/ProductTable';
import { IProductAPI } from '@app/model/IProductAPI';
import { matchPath } from 'react-router-dom';
import { NewProductDialog } from './ProductPage/NewProductDialog';


export interface ProductPageProps extends WithTranslation, WithRouter {
    productAPI: IProductAPI,
    onAlert: (title: string, variant: string) => void
}

class ProductPageImpl extends React.Component<ProductPageProps> {
    public state = {
        reloadCounter: 0
    }

    deleteProduct(id: number) {
        this.props.productAPI.deleteProduct(id).then(result => {
            if (result.data) {
                this.props.onAlert(`Product deleted`, "success"); 
            }
        }).catch(error => {
            this.props.onAlert(`Could not delete product: ${JSON.stringify(error)}`, "danger"); 
        });
        this.setState({ reloadCounter: this.state.reloadCounter + 1 })
    }
    
    render() {
        const { t, productAPI, onAlert, navigate } = this.props;
        const { reloadCounter } = this.state;
        document.title = "Produkte";

        const dialogOpen = matchPath("/products/new", location.pathname) ? true : false;

        return (
            <React.Fragment>
                <NewProductDialog
                    isOpen={dialogOpen}
                    onClose={() => {
                        this.setState({reloadCounter: this.state.reloadCounter + 1});
                        navigate("/products");
                    }}
                    onAlert={onAlert}
                    productAPI={productAPI}
                    />
                <PageSection isFilled>
                    <Flex direction={{ default: 'column' }}>
                        <FlexItem>
                            <Panel variant="raised">
                                <PanelHeader>Übersicht</PanelHeader>
                                <Divider />
                                <PanelMain>
                                    <PanelMainBody>
                                        <ProductTable
                                            onAlert={onAlert}
                                            productAPI={productAPI}
                                            reloadTriggerCounter={reloadCounter}
                                            onDeleteProduct={(productId) => {this.deleteProduct(productId)}}
                                            />
                                        <Button variant="primary" type="submit" onClick={() => {navigate("/products/new")}}>Neues Produkt</Button>
                                    </PanelMainBody>
                                </PanelMain>
                            </Panel>
                        </FlexItem>
                    </Flex>
                </PageSection>
            </React.Fragment>
        );
    }
}

export const ProductPage = withTranslation()(withRouter(ProductPageImpl));