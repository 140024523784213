import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Checkbox,
    Form,
    FormGroup,
    FormSection,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { ValidatableInputField, ValidationOptions } from '../../ValidateableInputField';


export interface StripePanelProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class StripePanelImpl extends React.Component<StripePanelProps> {
    public state = {
        isLoading: true,
        saveStatus: undefined as (undefined | "success" | "saving"),

        clientSecret: "",
        cardEnabled: false,
        cardFixedFee: "",
        cardVariableFee: "",
        cardNote: "",
        giroEnabled: false,
        giroFixedFee: "",
        giroVariableFee: "",
        giroNote: "",
        bankEnabled: false,
        bankFixedFee: "",
        bankVariableFee: "",
        bankNote: "",
    }

    public valids = {
        clientSecretValid: false,
        cardFixedFeeValid: false,
        cardVariableFeeValid: false,
        giroFixedFeeValid: false,
        giroVariableFeeValid: false,
        bankFixedFeeValid: false,
        bankVariableFeeValid: false,
    }

    allFieldsValid() {
        let res = true;
        let valid: keyof typeof this.valids;
        for(valid in this.valids)  {
            res = this.valids[valid] && res;
        }
        return res;
    }

    componentDidMount() {
        this.props.settingsAPI.fetchStripeSettings().then(value => {
            const data = value.data!;
            this.setState({
                clientSecret: data.clientSecret,
                cardEnabled: data.cardEnabled,
                cardFixedFee: data.cardFixedFee.toFixed(2),
                cardVariableFee: data.cardVariableFee,
                cardNote: data.cardNote,
                giroEnabled: data.giroEnabled,
                giroFixedFee: data.giroFixedFee.toFixed(2),
                giroVariableFee: data.giroVariableFee,
                giroNote: data.giroNote,
                bankEnabled: data.bankEnabled,
                bankFixedFee: data.bankFixedFee.toFixed(2),
                bankVariableFee: data.bankVariableFee,
                bankNote: data.bankNote,
                isLoading: false
            });
        }).catch(error => {
            this.props.onAlert(`Could not load stripe settings: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    onSave = () => {
        if (this.allFieldsValid()) {
            this.setState({ saveStatus: "saving" });
            const info = {
                clientSecret: this.state.clientSecret,
                cardEnabled: this.state.cardEnabled,
                cardFixedFee: Number.parseFloat(this.state.cardFixedFee),
                cardVariableFee: Number.parseFloat(this.state.cardVariableFee),
                cardNote: this.state.cardNote,
                giroEnabled: this.state.giroEnabled,
                giroFixedFee: Number.parseFloat(this.state.giroFixedFee),
                giroVariableFee: Number.parseFloat(this.state.giroVariableFee),
                giroNote: this.state.giroNote,
                bankEnabled: this.state.bankEnabled,
                bankFixedFee: Number.parseFloat(this.state.bankFixedFee),
                bankVariableFee: Number.parseFloat(this.state.bankVariableFee),
                bankNote: this.state.bankNote
            };
            this.props.settingsAPI.postStripeSettings(info).then(value => {
                if (value.data) {
                    this.setState({ saveStatus: "success" });
                }
                else {
                    this.setState({ saveStatus: "failed" });
                }
            }).catch(error => {
                const msg = error.error.message ? error.error.message : "";
                this.props.onAlert(`Could not store stripe settings: ${msg}`, "danger");
                this.setState({ saveStatus: "failed" });
            });
        }
    }

    render() {
        const { t } = this.props;
        const { saveStatus, clientSecret,
            cardEnabled, cardFixedFee, cardVariableFee, cardNote,
            giroEnabled, giroFixedFee, giroVariableFee, giroNote,
            bankEnabled, bankFixedFee, bankVariableFee, bankNote,
            isLoading} = this.state;

        return (
            <SaveablePanel
                title="Stripe"
                saveStatus={saveStatus}
                isLoading={isLoading}
                onSave={this.onSave}
                saveDisabled={!this.allFieldsValid()}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <ValidatableInputField
                        inputId='clientSecret-stripe'
                        title='Secret API Key:'
                        validateFor={ValidationOptions.LengthMoreThan0}
                        value={clientSecret}
                        onChange={(value, valid) => {
                            this.setState({ clientSecret: value })
                            this.valids.clientSecretValid = valid;
                        }}
                    />

                    <FormSection title="Kreditkarte">
                        <FormGroup
                            label="Aktiviert:"
                            fieldId="cardEnabled"
                        >
                            <Checkbox
                                id="cardEnabled"
                                isChecked={cardEnabled}
                                onChange={(_e, value) => this.setState({ cardEnabled: value })}
                            />
                        </FormGroup>
                        <ValidatableInputField
                            inputId='cardFixedFee-stripe'
                            title='Feste Gebühr:'
                            validateFor={ValidationOptions.TwoDigitFixedPoint}
                            value={cardFixedFee}
                            onChange={(value, valid) => {
                                this.setState({ cardFixedFee: value })
                                this.valids.cardFixedFeeValid = valid;
                            }}
                        />
                        <ValidatableInputField
                            inputId='cardVariableFee-stripe'
                            title='Relative Gebühr:'
                            validateFor={ValidationOptions.Number}
                            value={cardVariableFee}
                            onChange={(value, valid) => {
                                this.setState({ cardVariableFee: value })
                                this.valids.cardVariableFeeValid = valid;
                            }}
                        />
                        <FormGroup
                            label="Hinweis"
                            fieldId="cardNote"
                        >
                            <TextInput
                                value={cardNote}
                                id='cardNote-stripe'
                                onChange={value => {
                                    this.setState({ cardNote: value });
                                }}
                            />
                        </FormGroup>
                    </FormSection>
                    <FormSection title="Giropay">
                        <FormGroup
                            label="Aktiviert:"
                            fieldId="giroEnabled"
                        >
                            <Checkbox
                                id="giroEnabled"
                                isChecked={giroEnabled}
                                onChange={(_e, value) => this.setState({ giroEnabled: value })}
                            />
                        </FormGroup>
                        <ValidatableInputField
                            inputId='giroFixedFee-stripe'
                            title='Feste Gebühr:'
                            validateFor={ValidationOptions.TwoDigitFixedPoint}
                            value={giroFixedFee}
                            onChange={(value, valid) => {
                                this.valids.giroFixedFeeValid = valid;
                                this.setState({ giroFixedFee: value })
                            }}
                        />
                        <ValidatableInputField
                            inputId='giroVariableFee-stripe'
                            title='Relative Gebühr:'
                            validateFor={ValidationOptions.Number}
                            value={giroVariableFee}
                            onChange={(value, valid) => {
                                this.setState({ giroVariableFee: value })
                                this.valids.giroVariableFeeValid = valid;
                            }}
                        />
                        <FormGroup
                            label="Hinweis"
                            fieldId="giroNote"
                        >
                            <TextInput
                                id='giroNote-stripe'
                                value={giroNote}
                                onChange={value => {
                                    this.setState({ giroNote: value });
                                }}
                            />
                        </FormGroup>
                    </FormSection>
                    <FormSection title="Lastschrift">
                        <FormGroup
                            label="Aktiviert:"
                            fieldId="bankEnabled"
                        >
                            <Checkbox
                                id="bankEnabled"
                                isChecked={bankEnabled}
                                onChange={(_e, value) => this.setState({ bankEnabled: value })}
                            />
                        </FormGroup>
                        <ValidatableInputField
                            inputId='bankFixedFee-stripe'
                            title='Feste Gebühr:'
                            validateFor={ValidationOptions.TwoDigitFixedPoint}
                            value={bankFixedFee}
                            onChange={(value, valid) => {
                                this.setState({ bankFixedFee: value })
                                this.valids.bankFixedFeeValid = valid;
                            }}
                        />
                        <ValidatableInputField
                            inputId='bankVariableFee-stripe'
                            title='Relative Gebühr:'
                            validateFor={ValidationOptions.Number}
                            value={bankVariableFee}
                            onChange={(value, valid) => {
                                this.setState({ bankVariableFee: value })
                                this.valids.bankVariableFeeValid = valid;
                            }}
                        />
                        <FormGroup
                            label="Hinweis"
                            fieldId="bankNote"
                        >
                            <TextInput
                                id='bankNote-stripe'
                                value={bankNote}
                                onChange={value => {
                                    this.setState({ bankNote: value });
                                }}
                            />
                        </FormGroup>
                    </FormSection>
                </Form>
            </SaveablePanel>
        );
    }
}

export const StripePanel = withTranslation()(StripePanelImpl);