import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { TableFilterQuery, TableRowData, Transfer } from '@app/model/ITransactionAPI';
import { ActionsColumn, TableText, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { IProductEntry, IProductAPI } from '@app/model/IProductAPI';
import { Badge, Button, Checkbox, Dropdown, DropdownItem, DropdownList, Flex, FlexItem, InputGroup, InputGroupText, MenuToggle, Select, SelectOption, SelectVariant, TextInput, ValidatedOptions } from '@patternfly/react-core';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { CalculatorIcon, PenIcon, SaveAltIcon, SaveIcon, ThumbsDownIcon, TrashIcon } from '@patternfly/react-icons';

export interface ProductTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    productAPI: IProductAPI,
    onDeleteProduct: (productId: number) => void,
}

interface IExtraState {
    categorySelOpen: boolean,
    category: string,
    
    name: string,
    price: string,
    inStock: boolean,
    auxdata: string,
    
    nameChanged: boolean,
    priceChanged: boolean,
    inStockChanged: boolean,
    categoryChanged: boolean,
    auxdataChanged: boolean,

    priceValid: boolean,
    saveLoading: boolean
}

class ProductTableImpl extends RemoteTable<IProductEntry, ProductTableProps> {

    public state = {
        rows: [] as IProductEntry[],
        extraState: new Map<number, IExtraState>(),
        isLoading: true,
        totalRows: 0
    }

    headers = () => [
        {name: "ID", defaultSort: "desc", sortKey: "id"},
        {name: "Name", defaultSort: "asc", sortKey: "name"},
        {name: "Preis", defaultSort: "asc", sortKey: "price"},
        {name: "Verfügbar", defaultSort: "desc", sortKey: "in_stock"},
        {name: "Kategorie", defaultSort: "asc", sortKey: "category"},
        {name: "Extra Daten", defaultSort: "asc", sortKey: "auxdata"},
        {name: ""}
    ] as IRowHeader[];

    defaultSortIndex = 3;
    hasFilter = true;

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IProductEntry>, void>> {
        return this.props.productAPI.fetchProducts(query).then(value => {
            this.state.extraState.clear();

            for(let row of value.data!.rows) {
                this.state.extraState[row.id] = {
                    categorySelOpen: false,
                    category: row.category,

                    name: row.name,
                    price: row.price,
                    inStock: row.in_stock,
                    auxdata: row.auxdata,

                    nameChanged: false,
                    priceChanged: false,
                    categoryChanged: false,
                    inStockChanged: false,
                    auxdataChanged: false,

                    priceValid: true,
                    saveLoading: false
                };
            }

            return value;
        });
    }

    saveButtonEnabled(rowId: number) {
        return this.state.extraState[rowId].priceValid && 
            (this.state.extraState[rowId].nameChanged ||
                this.state.extraState[rowId].priceChanged ||
                this.state.extraState[rowId].categoryChanged ||
                this.state.extraState[rowId].inStockChanged ||
                this.state.extraState[rowId].auxdataChanged)
    }

    saveProduct(id: number) {
        // this.state.extraState[id].saveLoading = true;
        this.props.productAPI.updateProduct({
            id: id,
            name: this.state.extraState[id].name,
            price: Number(this.state.extraState[id].price),
            in_stock: this.state.extraState[id].inStock,
            category: this.state.extraState[id].category,
            auxdata: this.state.extraState[id].auxdata
        }).then(result => {
            this.state.extraState[id].saveLoading = false;

            if (result.data) {
                this.state.extraState[id].nameChanged = false;
                this.state.extraState[id].priceChanged = false;
                this.state.extraState[id].inStockChanged = false;
                this.state.extraState[id].categoryChanged = false;
                this.state.extraState[id].auxdataChanged = false;
                this.props.onAlert(`Changes saved`, "success"); 
            } else {
                this.props.onAlert(`Error while saveing, propably not found in db`, "danger"); 
            }
        }).catch(error => {
            this.props.onAlert(`Could not save product: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    renderRow(row: IProductEntry): JSX.Element {
        const categories = ["snack", "drink", "coffee"];
        const categoryOptions = categories.map((val, i) => { return (<SelectOption value={val} key={i}>{val}</SelectOption>) });

        return (<Tr key={row.id}>
            <Td>{row.id}</Td>
            <Td>
                <TextInput type="text"
                    id={"name-" + row.id.toString()}
                    value={this.state.extraState[row.id].name}
                    onChange={(_event, value) => {
                        this.state.extraState[row.id].name = value;
                        this.state.extraState[row.id].nameChanged = true;
                        this.setState({ extraState: this.state.extraState });
                    }}
                    customIcon={this.state.extraState[row.id].nameChanged ? <PenIcon /> : "" }
                />
            </Td>
            <Td>
                <InputGroup>
                    <ValidatableInputField
                        withoutFormGroup={true}
                        title=''
                        inputId={"price-" + row.id.toString()}
                        value={this.state.extraState[row.id].price}
                        validateFor={ValidationOptions.TwoDigitFixedPoint}
                        extraIcon={this.state.extraState[row.id].priceChanged ? <PenIcon /> : "" }
                        onChange={(value, valid) => {
                            this.state.extraState[row.id].price = value;
                            this.state.extraState[row.id].priceValid = valid;
                            this.setState({ extraState: this.state.extraState })
                        }}
                        onTextChangeRaw={(_event, _text) => {
                            this.state.extraState[row.id].priceChanged = true;
                            this.setState({ extraState: this.state.extraState })
                        }}
                    />
                    <InputGroupText >€</InputGroupText>
                </InputGroup>
            </Td>
            <Td>
                <Flex>
                    <FlexItem>
                        <Checkbox
                            id={`check-${row.id}`}
                            isChecked={this.state.extraState[row.id].inStock}
                            onChange={() => {
                                this.state.extraState[row.id].inStock = !this.state.extraState[row.id].inStock;
                                this.state.extraState[row.id].inStockChanged = true;
                                this.setState({
                                    extraState: this.state.extraState
                                });
                            }}
                        />
                    </FlexItem>
                    <FlexItem>
                        {this.state.extraState[row.id].inStockChanged ? <PenIcon /> : ""}
                    </FlexItem>
                </Flex>
            </Td>
            <Td>
                <Select
                    isOpen={this.state.extraState[row.id].categorySelOpen}
                    onSelect={(_event, value) => {
                        this.state.extraState[row.id].category = value;
                        this.state.extraState[row.id].categorySelOpen = false;
                        this.state.extraState[row.id].categoryChanged = true;
                        this.setState({
                            extraState: this.state.extraState
                        });
                    }}
                    selected={this.state.extraState[row.id].category}
                    toggle={(toggleRef) => (
                        <MenuToggle ref={toggleRef} onClick={() => {
                                this.state.extraState[row.id].categorySelOpen = !this.state.extraState[row.id].categorySelOpen;
                                this.setState({extraState: this.state.extraState});
                            }}
                            isExpanded={this.state.extraState[row.id].categorySelOpen}
                        >
                            {this.state.extraState[row.id].category}
                            {this.state.extraState[row.id].categoryChanged ? <Badge isRead><PenIcon/></Badge> : ""}
                        </MenuToggle>
                    )}
                >
                    {categoryOptions}
                </Select>
            </Td>
            <Td>
                <TextInput type="text"
                    id={"auxdata-" + row.id.toString()}
                    value={this.state.extraState[row.id].auxdata}
                    onChange={(_event, value) => {
                            this.state.extraState[row.id].auxdata = value;
                            this.state.extraState[row.id].auxdataChanged = true;
                            this.setState({ extraState: this.state.extraState });
                        }}
                    customIcon={this.state.extraState[row.id].auxdataChanged ? <PenIcon /> : "" }
                    />
            </Td>
            <Td dataLabel="Speichern" modifier="fitContent">
                <Button isLoading={this.state.extraState[row.id].saveLoading} variant="plain" 
                    isDisabled={!this.saveButtonEnabled(row.id)}
                    onClick={() => {
                        this.state.extraState[row.id].saveLoading = true;
                        this.setState({ extraState: this.state.extraState });
                        this.saveProduct(row.id);
                    }}
                    icon={<SaveIcon />}
                />
                <Button variant='plain'
                    onClick={() => {this.props.onDeleteProduct(row.id)}}
                    icon={<TrashIcon />}
                />
            </Td>
        </Tr>);
    }
}

export const ProductTable = withTranslation()(withRouter(ProductTableImpl));