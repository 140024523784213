import React from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router";
import { Location } from "react-router-dom";

export interface WithRouter  {
    location: Location;
    navigate: NavigateFunction;
    params: URLSearchParams;
}

export function withRouter<P extends WithRouter>(Child: React.ComponentClass<P>) {
    return (props: Omit<P, keyof WithRouter>) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();
        return <Child {...props as P} navigate={navigate} location={location} params={params} />;
    }
}