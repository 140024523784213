import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Divider,
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';

import { IUserAPI } from '@app/model/IUserAPI';
import { UsersTable } from './UsersPage/UsersTable';


export interface UsersPageProps extends WithTranslation, WithRouter {
    userAPI: IUserAPI,
    onAlert: (title: string, variant: string) => void
}

class UsersPageImpl extends React.Component<UsersPageProps> {
    state = {
        reloadCounter: 0
    }

    setActiveUser(id: number, active: boolean) {
        this.props.userAPI.fetchUser(id).then((result) => {
            let user = result.data!
            user.active = active
            this.props.userAPI.updateUser(user).then((result) => {
                this.props.onAlert("User updated", "success");
                this.setState({ reloadCounter: this.state.reloadCounter + 1 })
            }).catch(error => {
                this.props.onAlert("Error occured during update", "danger")
            })
        }).catch(error => {
            this.props.onAlert("Error occured during update", "danger")
        })
    }

    render() {
        const { t, userAPI, onAlert, navigate } = this.props;
        const { reloadCounter } = this.state;
        document.title = "Benutzer";

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>Übersicht</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <UsersTable
                                        onAlert={onAlert}
                                        userAPI={userAPI}
                                        onEditUser={(id) => {navigate(`/users/${id}`);}}
                                        onListTransactions={(id) => {navigate(`/transactions/${id}`);}}
                                        onNewTransaction={(id) => {navigate(`/transactions/${id}/create`);}}
                                        onActivateUser={(id) => {this.setActiveUser(id, true)}}
                                        onDeactivateUser={(id) => {this.setActiveUser(id, false)}}
                                        reloadTriggerCounter={reloadCounter}
                                        />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const UsersPage = withTranslation()(withRouter(UsersPageImpl));