import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    ActionGroup,
    Button,
    Form,
    FormGroup,
    Select,
    SelectOption,
    TextInput,
    InputGroupText,
    MenuToggle,
    InputGroup,
    MenuItem,
    Menu,
    MenuContent,
    MenuList,
    Popper,
    SearchInput,
} from '@patternfly/react-core';
import { ITransactionAPI, Transaction } from '@app/model/ITransactionAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { ACCOUNTS } from '@app/model/IDBHelpers';
import { IStatsAPI, StatsFilterEntry } from '@app/model/IStatsAPI';


export interface CreateTransactionDialogProps extends WithTranslation {
    uid?: number,
    userName?: string,
    isOpen: boolean,
    onClose: () => void,
    onTransactionCreated: () => void,
    transferAPI: ITransactionAPI,
    statsAPI: IStatsAPI,
    onAlert: (title: string, variant: string) => void
}

class CreateTransactionDialogImpl extends React.Component<CreateTransactionDialogProps> {
    public state = {
        amount: 0,
        amountValid: false,
        comment: "",
        srcSelOpen: false,
        srcSelOption: 0,
        destSelOpen: false,
        destSelOption: 0,
        buttonLoading: false,

        // User Filter
        userFilter: undefined as (undefined | StatsFilterEntry[]),
        isLoadingUsers: true,
        userFilterID: 0,
        userSearchInputValue: "Loading ...",
        userSelAutocompleteOpen: false,
        userSelAutocompleteHint: "",
        userSelAutocompleteOptions: undefined as (undefined | JSX.Element[]),
        userSearchDeactivated: true
    }

    searchInputRef = React.createRef();
    autocompleteRef = React.createRef();

    onCreateTransactionClick = () => {
        const { transferAPI, onAlert, onTransactionCreated } = this.props;
        const { amount, comment, userFilterID, destSelOption, srcSelOption } = this.state;

        this.setState( {buttonLoading : true} );

        const date = new Date();
        let t: Transaction = {
            id: 0,
            date: date.toISOString(),
            uid: userFilterID,
            amount: amount,
            account_dest: destSelOption,
            account_src: srcSelOption,
            reference: comment,
            tid: 0, pid: 0, count: 0, comment: "", product_list: "",
            price: 0, category: "", full_user_name: ""
        }

        transferAPI.createTransaction(t).then((result) => {
            this.setState( {buttonLoading: false} );
            onAlert(`Transaction created`, "success");
            onTransactionCreated();
        }).catch((error) => {
            onAlert(`Could not create transaction: ${JSON.stringify(error)}`, "danger");
            this.setState( {buttonLoading : false} );
        }); 
    }

    componentDidUpdate(prevProps: Readonly<CreateTransactionDialogProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.isOpen != this.props.isOpen) {
            // Dialog was opened?
            this.fetchUsersForAutocomplete();
        }
    }

    fetchUsersForAutocomplete(): void {
        if (this.props.uid) {
            this.setState({
                userFilterID: this.props.uid!,
                userSearchInputValue: this.props.userName!,
                userSearchDeactivated: true,
            })
            return;
        }

        this.props.statsAPI.fetchUserFilter().then(result =>
            this.setState({
                userFilter: result.data!,
                userFilterID: 0,
                isLoadingUsers: false,
                userSearchInputValue: "",
                userSearchDeactivated: false
            })
        )
    }

    // Account selects
    onAccountSrcClick = (_event) => {
        this.setState({ srcSelOpen: !this.state.srcSelOpen })
    };

    onAccountSrcSelect = (_event, value) => {
        this.setState({
            srcSelOpen: false,
            srcSelOption: value,
        })
    };

    onAccountDestClick = (_event) => {
        this.setState({ destSelOpen: !this.state.destSelOpen })
    };

    onAccountDestSelect = (_event, value) => {
        this.setState({
            destSelOpen: false,
            destSelOption: value,
        })
    };

    // Search Input fields
    onSearchInputChange = (_event, newValue) => {
        if (this.state.isLoadingUsers)
            return;
        if (this.state.userFilter === undefined)
            return;

        if ( newValue !== '' && this.searchInputRef) {
            let options = this.state.userFilter
                .filter((option) => (option.text.toLowerCase().includes(newValue.toLowerCase())))
                .map((option) => (
                    <MenuItem itemId={option.id} key={option.text}>
                        {option.text}
                    </MenuItem>
                    )
                );
            if (options.length > 5) {
                options = options.slice(0, 5);
            }

            this.setState({
                userSelAutocompleteHint: options.length === 1 ? options[0].props.key : '',
                userSelAutocompleteOpen: true,
                userSelAutocompleteOptions: options,
                userSearchInputValue: newValue,
            })
        } else {
            this.setState({ userSelAutocompleteOpen: false, userSearchInputValue: newValue })
        }
    }

    onSearchInputClear = () => {
        this.setState({
            userSearchInputValue: "",
            userFilterID: 0,
            userSelAutocompleteOpen: false,
            isLoading: true
        })
    }

    onAutoCompleteSelect = (e, itemId) => {
        e.stopPropagation();
        let foundUser = this.state.userFilter?.find(( {id} ) => id == itemId);
        this.setState({ 
            userFilterID: itemId, 
            userSearchInputValue: foundUser ? foundUser.text : "",
            userSelAutocompleteOpen: false,
            isLoading: true,
        })
        this.searchInputRef.current.focus();
    }

    handleUserSelectionKeys = (event) => {
        if (this.state.userSelAutocompleteHint && (event.key === 'Tab' || event.key === 'ArrowRight') && this.searchInputRef.current === event.target) {
            this.setState({
                userSelAutocompleteHint: "",
                userSearchInputValue: "",
                userFilterID: 0,
                userSelAutocompleteOpen: false
            })
            if (event.key === 'ArrowRight')
                event.preventDefault();
        } else if (this.state.userSelAutocompleteOpen && this.searchInputRef.current && this.searchInputRef.current == event.target) {
            if (event.key === 'Escape') {
                this.setState({ userSelAutocompleteOpen: false });
                this.searchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                const firstElement = this.autocompleteRef.current.querySelector('li > button:not(:disabled)');
                firstElement && firstElement.focus();
                event.preventDefault();
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                this.setState({ userSelAutocompleteOpen: false });
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault();
                }
            }
        } else if (this.state.userSelAutocompleteOpen && this.autocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            event.preventDefault();
            this.setState({ userSelAutocompleteOpen: false });
            this.searchInputRef.current.focus();
        }
    }

    handleUserSelectionClickOutside = (event: MouseEvent) => {
        if (this.state.userSelAutocompleteOpen && !this.searchInputRef.current?.contains(event.target as Node)) {
            this.setState({userSelAutocompleteOpen: false});
        }
    }


    // Renderer
    render() {
        const { isOpen, t, onClose } = this.props;
        const { amount, amountValid, comment, buttonLoading, userSearchDeactivated,
            userFilterID, userSearchInputValue, userSelAutocompleteHint, userSelAutocompleteOpen, userSelAutocompleteOptions,
            srcSelOpen, srcSelOption, destSelOpen, destSelOption } = this.state;
    
        const accountSelectionOptions = ACCOUNTS.map((val, i) => {return (<SelectOption value={i} key={i}>{val}</SelectOption>)});
        const destToggle = (toggleRef) => 
        (
            <MenuToggle ref={toggleRef} onClick={this.onAccountDestClick} 
                    isExpanded={destSelOpen} style={{width: '300px'} as React.CSSProperties}>
                {ACCOUNTS[destSelOption]}
            </MenuToggle>
        )
        const srcToggle = (toggleRef) => (
            <MenuToggle ref={toggleRef} onClick={this.onAccountSrcClick} 
                    isExpanded={srcSelOpen} style={{ width: '300px' } as React.CSSProperties}>
                {ACCOUNTS[srcSelOption]}
            </MenuToggle>
        )

        const userSearchInput = (
            <SearchInput
                value={userSearchInputValue}
                onChange={this.onSearchInputChange}
                onClear={this.onSearchInputClear}
                ref={this.searchInputRef}
                hint={userSelAutocompleteHint}
                id="user-autocomplete-search"
                isDisabled={userSearchDeactivated}
            />
        );

        const autocomplete = (
            <Menu onSelect={this.onAutoCompleteSelect} ref={this.autocompleteRef}>
                <MenuContent>
                    <MenuList>{userSelAutocompleteOptions}</MenuList>
                </MenuContent>
            </Menu>
        );

        const userSearch = (
            <Popper
                triggerRef={this.searchInputRef}
                trigger={userSearchInput}
                popperRef={this.autocompleteRef}
                popper={autocomplete}
                isVisible={userSelAutocompleteOpen}
                enableFlip={false}
                appendTo={() => document.querySelector("#user-autocomplete-search")}
            />
        )

        window.addEventListener('click', this.handleUserSelectionClickOutside);
        window.addEventListener('keydown', this.handleUserSelectionKeys);

        return (
        <Modal
            variant={ModalVariant.medium}
            title={t("berta:newtransaction.title", "Manuelle Buchung")}
            isOpen={isOpen}
            onClose={onClose}
        >
            <TextContent>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onCreateTransactionClick();
                    }}
                >
                    <FormGroup label="Quellkonto" fieldId='srcAcc'>
                        <Select
                            toggle={srcToggle}
                            isOpen={srcSelOpen}
                            onSelect={this.onAccountSrcSelect}
                            selected={ACCOUNTS[srcSelOption]}
                            isScrollable={true}
                        >
                            {accountSelectionOptions}
                        </Select>
                    </FormGroup>
                    <FormGroup label="Zielkonto" fieldId='srcAcc'>
                        <Select
                            toggle={destToggle}
                            isOpen={destSelOpen}
                            onSelect={this.onAccountDestSelect}
                            selected={ACCOUNTS[destSelOption]}
                            isScrollable={true}
                        >
                            {accountSelectionOptions}
                        </Select>
                    </FormGroup>
                    <FormGroup label="User" fieldId="user">
                        {userSearch}
                    </FormGroup>
                    <FormGroup label="Betrag" fieldId='amount'>
                        <InputGroup>
                            <ValidatableInputField
                                withoutFormGroup={true}
                                title=''
                                inputId={"amount"}
                                value={amount.toString()}
                                validateFor={ValidationOptions.TwoDigitFixedPoint}
                                onChange={(value, valid) => {
                                    this.setState({ amount: Number(value), amountValid: valid })
                                }}
                            />
                            <InputGroupText >€</InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="Kommentar" fieldId='fee'>
                        <TextInput
                            type="text"
                            value={comment} 
                            onChange={(_e, value) => this.setState({comment: value})} 
                        />
                    </FormGroup>
                    <ActionGroup>
                        <Button
                            variant="primary"
                            isDisabled={!(amountValid && userFilterID != 0)}
                            onClick={this.onCreateTransactionClick}
                            isLoading={buttonLoading}
                        >
                            Speichern
                        </Button>
                    </ActionGroup>
                </Form>
            </TextContent>
        </Modal>
        );
    }
}

export const CreateTransactionDialog = withTranslation()(CreateTransactionDialogImpl);