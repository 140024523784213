import React from 'react';
import { Breadcrumb, BreadcrumbItem, Nav, NavExpandable, NavItem, NavList } from '@patternfly/react-core';
import { createPath } from 'history';
import { withTranslation, WithTranslation } from 'react-i18next';

import { DashBoard, matchBreadCrumb, BreadcrumbData, matchNav, NavData, filterNav } from '@app/ui/DashBoard';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { ISystemAPI } from '@app/model/ISystemAPI';
import { IUser } from '@app/model/IUserAPI';

function getSettingsNav(path: string) {
    return matchNav(path, [
        [
            {
                path: "/apiKeys",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.settings.api",
                        to: "/settings/apiKeys",
                        children: []
                    };
                    return [false, element];
                },
            },
            {
                path: "/email",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.settings.email",
                        to: "/settings/email",
                        children: []
                    };
                    return [false, element];
                },
            },
            {
                path: "/payment",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.settings.payment",
                        to: "/settings/payment",
                        children: []
                    };
                    return [false, element];
                },
            }
        ]
    ]);
}

function getTransactionsNav(path: string) {
    return matchNav(path, [
        [
            {
                path: "/account_overview",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.transactions.overview",
                        to: "/transactions/account_overview",
                        children: []
                    };
                    return [false, element];
                },
            },
            {
                path: "/paypal",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.transactions.paypal",
                        to: "/transactions/paypal",
                        children: []
                    };
                    return [false, element];
                },
            },
            {
                path: "/stripe",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.transactions.stripe",
                        to: "/transactions/stripe",
                        children: []
                    };
                    return [false, element];
                },
            }
        ]
    ]);
}

function getUsersNav(path: string) {
    return matchNav(path, [
        [
            {
                path: "/register_codes",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.users.registerCodes",
                        to: "/users/register_codes",
                        children: []
                    };
                    return [false, element];
                },
            }
        ]
    ]);
}

function getRFIDNav(path: string) {
    return matchNav(path, [
        [
            {
                path: "/register_codes",
                nav: (path) => {
                    const element = {
                        name: "berta:nav.rfids.registerCodes",
                        to: "/rfids/register_codes",
                        children: []
                    };
                    return [false, element];
                }
            }
        ]
    ])
}

function getSettingsCrumbs(path: string) {
    const matchedCrumbs = matchBreadCrumb(path, [
        {
            path: "/apiKeys",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/settings/apiKeys`, name: "berta:crumbs.settings.api" }, ...children];
            },
        },
        {
            path: "/email",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/settings/email`, name: "berta:crumbs.settings.email" }, ...children];
            },
        },
        {
            path: "/payment",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/settings/payment`, name: "berta:crumbs.settings.payment" }, ...children];
            },
        },
    ]);
    return matchedCrumbs;
}

function getUsersCrumbs(path: string) {
    const matchedCrumbs = matchBreadCrumb(path, [
        {
            path: "/register_codes",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/users/register_codes`, name: "berta:crumbs.users.registerCodes" }, ...children];
            },
        },
        {
            path: "/:uid",
            crumb: (path, match) => {
                const id = match.params.uid;
                const children = matchBreadCrumb(path, [
                    {
                        path: "/transactions",
                        crumb: (path, match) => {
                            const children = [];
                            return [{ to: `/users/${id}/transactions`, name: "berta:crumbs.users.transactions" }, ...children];
                        },
                    }
                ]);
                return [{ to: `/users/${id}`, name: id }, ...children];
            },
        }
    ]);
    return matchedCrumbs;
}

function getRFIDCrumbs(path: string) {
    const matchedCrumbs = matchBreadCrumb(path, [
        {
            path: "/register_codes",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/rfids/register_cdes`, name: "berta:crumbs.rfids.registerCodes" }, ...children];
            },
        },
    ]);
    return matchedCrumbs;
}

function getTransactionsCrumbs(path: string) {
    const matchedCrumbs = matchBreadCrumb(path, [
        {
            path: "/account_overview",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/transactions/account_overview`, name: "berta:crumbs.transactions.overview" }, ...children];
            },
        },
        {
            path: "/stripe",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/transactions/stripe`, name: "berta:crumbs.transactions.stripe" }, ...children];
            },
        },
        {
            path: "/paypal",
            crumb: (path, match) => {
                const children = [];
                return [{ to: `/transactions/paypal`, name: "berta:crumbs.transactions.paypal" }, ...children];
            },
        },
    ]);
    return matchedCrumbs;
}

export interface BertaDashBoardProps extends WithTranslation, WithRouter {
    currentUser?: IUser,
    systemAPI: ISystemAPI,
    onAlert: (title: string, variant: string) => void
    onLogout: () => void
}

class BertaDashBoardImpl extends React.Component<BertaDashBoardProps> {
    onCrumbClick(event, navigate, to) {
        event.preventDefault();
        navigate(to);
    }

    private renderCrumbs(matchedCrumbs: BreadcrumbData[]): (undefined | JSX.Element) {
        const { navigate, t } = this.props;

        if (matchedCrumbs.length > 0) {
            return (
                <Breadcrumb>
                    <BreadcrumbItem key="main-bc" to="/" onClick={(event) => this.onCrumbClick(event, navigate, "/")}>Berta</BreadcrumbItem>
                    {matchedCrumbs.map((crumb, i) => {
                        //Last element
                        if (i + 1 == matchedCrumbs.length) {
                            return (<BreadcrumbItem key={"bc-" + crumb.name} isActive>{t(crumb.name)}</BreadcrumbItem>);
                        }
                        else {
                            return (
                            <BreadcrumbItem
                                to={crumb.to}
                                key={"bc-" + crumb.name}
                                onClick={(event) => this.onCrumbClick(event, navigate, crumb.to)}
                            >
                                {t(crumb.name)}
                            </BreadcrumbItem>
                            );
                        }
                    })}
                </Breadcrumb>
            );
        }
        else {
            return undefined;
        }
    }

    private renderNavElement(navData: NavData): JSX.Element {
        const { t } = this.props;

        if (navData.children.length > 0) {
            
            return (<NavExpandable
                title={t(navData.name)}
                key={navData.name}
                isActive={navData.isActive}
                groupId={navData.to}
                isExpanded={navData.isActive}>
                {navData.children.map((child) => this.renderNavElement(child))}
            </NavExpandable>);
        }
        else {
            return (<NavItem
                preventDefault
                key={navData.name}
                to={navData.to}
                itemId={navData.to}
                isActive={navData.isActive}>
                {t(navData.name)}
            </NavItem>);
        }
    }

    private renderNav(matchedNav: NavData[]): (undefined | JSX.Element) {
        const { navigate } = this.props;

        if (matchedNav.length > 0) {
            const elements = matchedNav.map((nav) => this.renderNavElement(nav));

            return (
                <Nav
                    onSelect={(_event, item) => {
                        if (item.itemId)
                            navigate(item.itemId as string);
                        }}
                    onToggle={(_event, item) => {
                        if (item.groupId)
                            navigate(item.groupId as string);
                        }}
                    >
                    <NavList>
                        {elements}
                    </NavList>
                </Nav>
            );
        }
        else {
            return undefined;
        }
    }

    public render() {
        const { location, currentUser, systemAPI, onAlert } = this.props;

        const matchedCrumbs = matchBreadCrumb(createPath(location), [
            {
                path: "/settings",
                crumb: (path, match) => {
                    const children = getSettingsCrumbs(path);
                    return [{ to: "/settings", name: "berta:crumbs.settings.root" }, ...children];
                },
            },
            {
                path: "/me",
                crumb: (path, match) => [{ to: "/me", name: "berta:crumbs.meSettings" }]
            },
            {
                path: "/users",
                crumb: (path, match) => {
                    const children = getUsersCrumbs(path);
                    return [{ to: "/users", name: "berta:crumbs.users.root" }, ...children];
                },
            },
            {
                path: "/products",
                crumb: (path, match) => [{ to: "/products", name: "berta:crumbs.products" }]
            },
            {
                path: "/rfids",
                crumb: (path, match) => {
                    const children = getRFIDCrumbs(path);
                    return [{ to: "/rfids", name: "berta:crumbs.rfids.root" }, ...children];
                }
            },
            {
                path: "/stats",
                crumb: (path, match) => [{ to: "/stats", name: "berta:crumbs.stats" }]
            },
            {
                path: "/transactions",
                crumb: (path, match) => {
                    const children = getTransactionsCrumbs(path);
                    return [{ to: "/transactions", name: "berta:crumbs.transactions.root" }, ...children];
                },
            },
        ]);


        let matchedNav = matchNav(createPath(location), [
            [
                {
                    path: "/",
                    pathExact: true,
                    nav: (path) => {
                        const element = {
                            name: "berta:nav.root",
                            to: "/",
                            children: []
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/users",
                    nav: (path) => {
                        const children = getUsersNav(path);
                        const element = {
                            name: "berta:nav.users.root",
                            to: "/users",
                            role: "admin",
                            children: children
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/products",
                    nav: (path) => {
                        const children = [];
                        const element = {
                            name: "berta:nav.products",
                            to: "/products",
                            role: "admin",
                            children: children
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/transactions",
                    nav: (path) => {
                        const children = getTransactionsNav(path);
                        const element = {
                            name: "berta:nav.transactions.root",
                            to: "/transactions",
                            role: "admin",
                            children: children
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/rfids",
                    nav: (path) => {
                        const children = getRFIDNav(path);
                        const element = {
                            name: "berta:nav.rfids.root",
                            to: "/rfids",
                            role: "admin",
                            children: children
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/stats",
                    nav: (path) => {
                        const children = [];
                        const element = {
                            name: "berta:nav.stats",
                            to: "/stats",
                            role: "admin",
                            children: children
                        };
                        return [false, element];
                    },
                },
                {
                    path: "/settings",
                    nav: (path) => {
                        const element = {
                            name: "berta:nav.settings.root",
                            to: "/settings",
                            role: "admin",
                            children: getSettingsNav(path)
                        };
                        return [false, element];
                    },
                }
            ],
            [
                {
                    path: "/me",
                    nav: (path) => {
                        const element = {
                            name: "berta:nav.meSettings",
                            to: "/me",
                            children: []
                        };
                        return [false, element];
                    },
                }
            ]
        ]);
        matchedNav = filterNav((element) => {
            if (element.role == undefined)
                return true;
            else if (currentUser == undefined)
                return false;
            else if (element.role == "admin")
                return currentUser.admin;
            else
                return false;
        }, matchedNav);

        return (
            <DashBoard
                currentUser={currentUser}
                systemAPI={systemAPI}
                onAlert={onAlert}
                onLogout={this.props.onLogout}
                navigation={this.renderNav(matchedNav)}
                breadCrumb={this.renderCrumbs(matchedCrumbs)}
                />
        );
    }
}

export const BertaDashBoard = withTranslation()(withRouter(BertaDashBoardImpl));