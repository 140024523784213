import { Divider, Flex, FlexItem, PageSection, Panel, PanelHeader, PanelMain, PanelMainBody } from "@patternfly/react-core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ProductRankingChart } from "./StatsPage/ProductRankingChart";
import { IStatsAPI } from "@app/model/IStatsAPI";
import { IUserAPI } from "@app/model/IUserAPI";
import { UserProductRanking } from "./StatsPage/ProductRanking";
import { UserRankingTable } from "./StatsPage/UserRankingTable";


export interface StatsPageProps {
    onAlert: (title: string, variant: string) => void,
    statsAPI: IStatsAPI,
}


class StatsPageImpl extends React.Component<StatsPageProps>{
    public state = {
    }

    render() {
        document.title = "Statistiken";

        return (
        <React.Fragment>
            <PageSection variant="light" isFilled>
                <Flex direction={{default: 'column'}}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>Produkt-Ranking</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <ProductRankingChart
                                        onAlert={this.props.onAlert}
                                        statsAPI={this.props.statsAPI}
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>User-Ranking</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <UserRankingTable
                                        onAlert={this.props.onAlert}
                                        statsAPI={this.props.statsAPI} 
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        </React.Fragment>
        )
    }
}

export const StatsPage = withTranslation()(StatsPageImpl);
