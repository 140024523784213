import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Flex,
    FlexItem,
    PageSection,
} from '@patternfly/react-core';
import { MainPanel } from './PaymentSettingsPage/MainPanel';
import { StripePanel } from './PaymentSettingsPage/StripePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { PayPalPanel } from './PaymentSettingsPage/PayPalPanel';

export interface PaymentSettingsPageProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class PaymentSettingsPageImpl extends React.Component<PaymentSettingsPageProps> {

    render() {
        const { t, onAlert, settingsAPI } = this.props;
        document.title = "Einstellungen";

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem><MainPanel onAlert={onAlert} settingsAPI={settingsAPI}/></FlexItem>
                    <FlexItem><StripePanel onAlert={onAlert} settingsAPI={settingsAPI}/></FlexItem>
                    <FlexItem><PayPalPanel onAlert={onAlert} settingsAPI={settingsAPI}/></FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const PaymentSettingsPage = withTranslation()(PaymentSettingsPageImpl);