import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';



import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { TableFilterQuery, TableRowData, Transfer } from '@app/model/ITransactionAPI';
import { Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { IPayPalLogEntry, IDepositAPI } from '@app/model/IDepositAPI';

export interface PayPalLogTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    depositAPI: IDepositAPI
}

class PayPalLogTableImpl extends RemoteTable<IPayPalLogEntry, PayPalLogTableProps> {

    headers = () => [
        {name: "ID", defaultSort: "desc", sortKey: "id"},
        {name: "PayPal Transaction", defaultSort: "asc", sortKey: "paypal_id"},
        {name: "Amount", defaultSort: "asc", sortKey: "amount"},
        {name: "Fee", defaultSort: "asc", sortKey: "fee"},
        {name: "E-Mail", defaultSort: "asc", sortKey: "email"},
    ] as IRowHeader[];

    defaultSortIndex = 0;
    hasFilter = true;

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IPayPalLogEntry>, void>> {
        return this.props.depositAPI.fetchPayPalLog(query);
    }

    renderRow(row: IPayPalLogEntry): JSX.Element {
        return (<Tr key={row.id}>
            <Td>{row.id}</Td>
            <Td>{row.paypal_id}</Td>
            <Td>{row.amount.toFixed(2)} €</Td>
            <Td>{row.fee.toFixed(2)} €</Td>
            <Td>{row.email}</Td>
        </Tr>);
    }
}

const PayPalLogTable = withTranslation()(withRouter(PayPalLogTableImpl));


export interface PayPalLogPageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    depositAPI: IDepositAPI
}

class PayPalLogPageImpl extends React.Component<PayPalLogPageProps> {

    render() {
        const { t, depositAPI, onAlert, params, navigate } = this.props;
        document.title = "PayPal Log";


        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelMain>
                                <PanelMainBody>
                                    <PayPalLogTable onAlert={onAlert} depositAPI={depositAPI} />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const PayPalLogPage = withTranslation()(withRouter(PayPalLogPageImpl));