import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    Text,
    TextVariants,
    Button,
    Form,
    Alert,
} from '@patternfly/react-core';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';


export interface ResetPasswordDialogProps extends WithTranslation {
    isOpen: boolean,
    errorText?: string,
    successText?: string,
    onClose: () => void,
    onResetPassword: (email: string) => void
}

class ResetPasswordDialogImpl extends React.Component<ResetPasswordDialogProps> {
    public state = {
        email: "",
        emailValid: undefined as (undefined | "error")
    }

    confirmForm() {
        const { onResetPassword } = this.props;

        if (this.state.emailValid) {
            onResetPassword(this.state.email);
        }
    }

    render() {
        const { isOpen, t, onClose, errorText, successText } = this.props;
        const { email, emailValid } = this.state;

        let errorElement = undefined as (undefined | JSX.Element);
        if (errorText) {
            errorElement = (<Alert variant="danger" title={t(errorText)} style={{marginBottom: "1.5em"}} />);
        }
        if (successText) {
            errorElement = (<Alert variant="success" title={t(successText)} style={{marginBottom: "1.5em"}} />);
        }

        return (
            <Modal
                variant={ModalVariant.small}
                title={t("berta:passwordReset.pageTitle", "Reset Password")}
                isOpen={isOpen}
                onClose={() => { onClose(); }}
                actions={[
                    <Button key="confirm" variant="primary"
                        disabled={!emailValid}
                        onClick={() => {
                            this.confirmForm();
                        }}>{t("berta:passwordReset.submitButton")}</Button>
                ]}
            >
                {errorElement}
                <TextContent>
                    <Text component={TextVariants.p}>
                        {t("berta:passwordReset.hintReset")}
                    </Text>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.confirmForm();
                        }}
                    >
                        <ValidatableInputField
                            title={t("berta:passwordReset.email")}
                            inputId='email'
                            validateFor={ValidationOptions.Email}
                            onChange={(value, valid) => {
                                this.setState({
                                    email: value,
                                    emailValid: valid
                                })
                            }}
                            value={email}
                        />
                    </Form>
                </TextContent>
            </Modal>
        );
    }
}

export const ResetPasswordDialog = withTranslation()(ResetPasswordDialogImpl);