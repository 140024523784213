import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Divider,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    Flex,
    FlexItem,
    Form,
    Level,
    LevelItem,
    PageSection,
    Panel,
    PanelHeader,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';

import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { TableText, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { Button } from '@patternfly/react-core';
import { TrashIcon } from '@patternfly/react-icons';
import { matchPath } from 'react-router-dom';
import { IAPIKey, ISettingsAPI } from '@app/model/ISettingsAPI';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { formatDateStr } from '@app/i18n';

export interface APIKeyTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    settingsAPI: ISettingsAPI,
    onRemove: (id: number) => void,
}

class APIKeysTableImpl extends RemoteTable<IAPIKey, APIKeyTableProps> {
    public state = {
        rows: [] as IAPIKey[],
        isLoading: true,
        totalRows: 0,
    }

    headers = () => [
        {name: "ID", defaultSort: "asc", sortKey: "id"},
        {name: "Key", defaultSort: "asc", sortKey: "api_key"},
        {name: "Kommentar", defaultSort: "asc", sortKey: "comment"},
        {name: "Zuletzt genutzt", defaultSort: "asc", sortKey: "last_used"},
        {name: ""},
    ] as IRowHeader[];

    defaultSortIndex = 0;
    hasFilter = true;


    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IAPIKey>, void>> {
        return this.props.settingsAPI.fetchAPIKeys(query);
    }

    renderRow(row: IAPIKey): JSX.Element {
        return (<Tr>
            <Td>{row.id}</Td>
            <Td>{row.api_key}</Td>
            <Td>{row.comment}</Td>
            <Td>{formatDateStr(row.last_used)}</Td>
            <Td dataLabel="Delete" modifier="fitContent">
                <TableText>
                    <Button variant="plain" onClick={() => {this.props.onRemove(row.id)}}><TrashIcon /></Button>
                </TableText>
            </Td>
        </Tr>);
    }
}

const APIKeyTable = withTranslation()(withRouter(APIKeysTableImpl));


// Actual Page
export interface APIKeyPageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: ISettingsAPI
}

class APIKeyPageImpl extends React.Component<APIKeyPageProps> {
    public state = {
        reloadCounter: 0,
        createNewKeyStatus: undefined as (undefined | "success" | "saving"),
        createNewKeyLoading: false,
        createNewKey: "",
        createNewKeyValid: true,
        createNewComment: "",
        createNewCommentValid: true,
        createNewExpanded: false,
    }

    createNewDrawerRef = React.createRef();

    onCreateNewClose = () => {
        this.setState({ createNewExpanded: false });
    }

    onCreateNewDrawerExpand = () => {
        this.createNewDrawerRef.current && this.createNewDrawerRef.current.focus();
    }

    onCreateNewOpen = () => {
        this.setState({ createNewExpanded: !this.state.createNewExpanded });
    }

    onCreateNew = () => {
        if (this.state.createNewKeyValid && this.state.createNewCommentValid) {
            let d = new Date();
            this.props.settingsAPI.postCreateAPIKey({
                id: 0,
                api_key: this.state.createNewKey,
                comment: this.state.createNewComment,
                last_used: d.toISOString(),
            }).then((result) => {
                this.setState({
                    createNewComment: "", 
                    createNewKey: "",
                    reloadCounter: this.state.reloadCounter + 1,
                });
                this.props.onAlert("API-Key angelegt", "success");
                this.onCreateNewClose();
            }).catch((error) => {
                this.props.onAlert(`Could not add API key: ${JSON.stringify(error)}`, "danger");
            });
        }
    }

    render() {
        const { t, settingsAPI, onAlert, params, navigate } = this.props;
        const { reloadCounter, createNewComment, createNewCommentValid, 
            createNewKey, createNewKeyValid, createNewKeyLoading, createNewKeyStatus,
            createNewExpanded } = this.state;

        document.title = "API Keys";

        const deleteCodeId = Number((params as any).id);
        const deleteCode = matchPath("/settings/apiKeys/delete/:id", location.pathname) ? true : false;

        if (deleteCode) {
            this.props.settingsAPI.postDeleteAPIKey(deleteCodeId);
            this.setState({ reloadCounter: reloadCounter + 1 });
            this.props.onAlert("API Key erfolgreich gelöscht", "success");

            this.props.navigate(`/settings/apiKeys`);
        }


        const createNewPanel = (
            <SaveablePanel
                title="API-Keys"
                saveStatus={createNewKeyStatus}
                isLoading={createNewKeyLoading}
                onSave={this.onCreateNew}
                saveDisabled={!(createNewCommentValid && createNewKeyValid)}
            >
            <Form isHorizontal
                onSubmit={(e) => {
                    e.preventDefault();
                    this.onCreateNew();
                }}
            >
                <ValidatableInputField
                    inputId='key'
                    title='API Key'
                    validateFor={ValidationOptions.LengthMoreThan0}
                    value={createNewKey}
                    onChange={(value, valid) => {
                        this.setState({ createNewKey: value, createNewKeyValid: valid })
                    }} 
                />
                <ValidatableInputField
                    inputId='comment'
                    title='Kommentar'
                    validateFor={ValidationOptions.LengthMoreThan0}
                    value={createNewComment}
                    onChange={(value, valid) => {
                        this.setState({ createNewComment: value, createNewCommentValid: valid })
                    }} 
                />
            </Form>
            </SaveablePanel>
        )
        
        const createNewDrawerPanelContent = (
            <DrawerPanelContent>
                <DrawerHead>
                    <span tabIndex={createNewExpanded ? 0 : -1} ref={this.createNewDrawerRef}>
                    {createNewPanel}
                    </span>
                    <DrawerActions>
                        <DrawerCloseButton onClick={this.onCreateNewClose} />
                    </DrawerActions>
                </DrawerHead>
            </DrawerPanelContent>
        )

        const createNewDrawer = (
            <Drawer isExpanded={createNewExpanded} position="bottom" onExpand={this.onCreateNewDrawerExpand}>
                <DrawerContent panelContent={createNewDrawerPanelContent}>
                    <DrawerContentBody>
                        <PanelMainBody>
                            <APIKeyTable
                                onAlert={onAlert}
                                settingsAPI={settingsAPI}
                                reloadTriggerCounter={reloadCounter}
                                onRemove={(id) => { this.props.navigate(`delete/${id}`); }}
                            />
                        </PanelMainBody>
                    </DrawerContentBody>
                </DrawerContent>
            </Drawer>
        )

        return (
            <React.Fragment>
                <PageSection variant='light' isFilled>
                    <Flex direction={{ default: 'column' }}>
                        <FlexItem>
                            <Panel variant="raised">
                                <PanelHeader>
                                    <Level>
                                        <LevelItem>API-Keys</LevelItem>
                                        <LevelItem>
                                            <Button onClick={this.onCreateNewOpen} variant="primary">
                                                Neu erstellen
                                            </Button>
                                        </LevelItem>
                                    </Level>
                                </PanelHeader>
                                <Divider />
                                <PanelMain>
                                    {createNewDrawer}
                                </PanelMain>
                            </Panel>
                        </FlexItem>
                    </Flex>
                </PageSection>
            </React.Fragment>
        );
    }
}

export const APIKeyPage = withTranslation()(withRouter(APIKeyPageImpl));