import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { ITransactionAPI, Transaction } from '@app/model/ITransactionAPI';
import { TableFilterQuery, TableRowData, getAccountName } from '@app/model/IDBHelpers';
import { Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { formatDateStr } from '@app/i18n';

export interface TransactionTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    uid?: number,
    transferAPI: ITransactionAPI,
    showAccounts?: boolean,
    accountDestFilter?: number | undefined,
    accountSrcFilter?: number | undefined,
    accountFilterOperator?: "AND" | "OR" | undefined,
    fetchOnMount: boolean
}

class TransactionTableImpl extends RemoteTable<Transaction, TransactionTableProps> {

    headers = () => {
        let fields = [
            {name: this.props.t("berta:transactions.table.date"), defaultSort: "desc", sortKey: "date"}] as IRowHeader[];

        if (!this.props.uid) {
            fields.push({name: this.props.t("berta:transactions.table.user"), defaultSort: "desc", sortKey: "uid"});
            fields.push({name: this.props.t("berta:transactions.table.username")})
        }

        fields = fields.concat([
            {name: this.props.t("berta:transactions.table.amount"), defaultSort: "asc", sortKey: "amount"},
            {name: this.props.t("berta:transactions.table.products"), defaultSort: "asc", sortKey: "product_list"},
            {name: this.props.t("berta:transactions.table.comment")}
        ]);

        if (this.props.showAccounts) {
            fields.push({name: this.props.t("berta:transactions.table.srcAcc"), defaultSort: "desc", sortKey: "account_src"});
            fields.push({name: this.props.t("berta:transactions.table.destAcc"), defaultSort: "desc", sortKey: "account_dest"});
        }

        return fields;
    }

    defaultSortIndex = 0;
    hasFilter = true;
    hasFrom = true;
    hasTo = true;
    fetchOnMount = this.props.fetchOnMount;

    _extraFilter = {
        uid: this.props.uid,
        accountDestFilter: this.props.accountDestFilter,
        accountSrcFilter: this.props.accountSrcFilter
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.uid !== prevProps.uid ||
            prevProps.accountFilterOperator != this.props.accountFilterOperator ||
            prevProps.accountDestFilter != this.props.accountDestFilter ||
            prevProps.accountSrcFilter != this.props.accountSrcFilter)
        {
            this._extraFilter = {
                uid: this.props.uid,
                accountDestFilter: this.props.accountDestFilter,
                accountSrcFilter: this.props.accountSrcFilter
            };
        }
    }

    getExtraFilter() : any {
        return this._extraFilter;
    }

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<Transaction>, void>> {
        let uid : number;
        uid = query.extra.uid != undefined ? query.extra.uid : 0;

        return this.props.transferAPI.fetchTransactions(query, uid,
                this.props.uid == undefined, query.extra.accountDestFilter, 
                query.extra.accountSrcFilter, this.props.accountFilterOperator);
    }

    renderRow(row: Transaction): JSX.Element {
        const {t} = this.props;

        return (<Tr key={row.id}>
            <Td>{formatDateStr(row.date)}</Td>
            {!this.props.uid && <Td>{row.uid}</Td>}
            {!this.props.uid && <Td>{row.full_user_name}</Td>}
            <Td>{t("berta:formats.currency", {val: row.amount})}</Td>
            <Td>{row.product_list}</Td>
            <Td>{row.comment}</Td>
            {this.props.showAccounts && <Td>{getAccountName(row.account_src)}</Td>}
            {this.props.showAccounts && <Td>{getAccountName(row.account_dest)}</Td>}
        </Tr>);
    }
}

export const TransactionTable = withTranslation()(withRouter(TransactionTableImpl));