import { TransactionAPI } from "@app/api/TransactionAPI";
import { Bullseye, Button, Spinner } from "@patternfly/react-core";
import { Tr, Td, Table, Thead, Tbody } from "@patternfly/react-table";
import React from "react"
import { WithTranslation, withTranslation } from "react-i18next"
import { EyeIcon } from '@patternfly/react-icons';
import { AccountOverviewEntry } from '@app/model/ITransactionAPI';
import { getAccountName } from '@app/model/IDBHelpers';
import { WithRouter, withRouter } from "@app/RouterComponent";

export interface AccountStatementTableProps extends WithTranslation, WithRouter {
    transactionAPI: TransactionAPI
    onAlert: (title: string, variant: string) => void
}

class AccountStatementTableImpl extends React.Component<AccountStatementTableProps> {
    state = {
        isLoading: true,
        overviewData: undefined as (undefined | AccountOverviewEntry[]),
    }

    componentDidMount(): void {
        this.props.transactionAPI.fetchAccountOverview().then((result) => {
            let data = result.data!
            let sorted = data.sort((a, b) => a.account - b.account);

            this.setState({ 
                overviewData: sorted,
                isLoading: false
            })
        })
    }

    onViewAccount(account: number) {
        this.props.navigate(`/transactions?account=${account}`);
    }

    renderRows() {
        let rows: JSX.Element[] = []
        if(!this.state.isLoading) {
            this.state.overviewData!.forEach(element => {
                rows.push(
                    <Tr key={element.account}>
                        <Td>{element.account}</Td>
                        <Td>{getAccountName(element.account)}</Td>
                        <Td style={{textAlign: "right"}}>{new Intl.NumberFormat('de-DE', {style: "currency", currency: "EUR"}).format(element.inFlow)}</Td>
                        <Td style={{textAlign: "right"}}>{element.inEntries}</Td>
                        <Td style={{textAlign: "right"}}>{new Intl.NumberFormat('de-DE', {style: "currency", currency: "EUR"}).format(element.outFlow)}</Td>
                        <Td style={{textAlign: "right"}}>{element.outEntries}</Td>
                        <Td style={{textAlign: "right"}}>{new Intl.NumberFormat('de-DE', {style: "currency", currency: "EUR"}).format(element.total)}</Td>
                        <Td>
                            <Button
                                icon={<EyeIcon />}
                                variant="plain"
                                onClick={() => this.onViewAccount(element.account)}
                            /> 
                        </Td>
                    </Tr>
                )
            });
        }
        return rows;
    }
    
    render(): React.ReactNode {
        const { isLoading } = this.state;

        let rows = undefined as JSX.Element[] | undefined
        if(isLoading) {
            rows = [
                <Tr key="loading">
                    <Td colSpan={2}>
                        <Bullseye>
                            <Spinner size="xl" />
                        </Bullseye>
                    </Td>
                </Tr>
            ];
        } else {
            rows = this.renderRows();
        }

        const table = (
            <React.Fragment>
                <Table variant="compact" borders={true}>
                    <Thead>
                        <Tr>
                            <Td>Nr.</Td>
                            <Td>Name</Td>
                            <Td style={{textAlign: "right"}}>Eingehend</Td>
                            <Td style={{textAlign: "right"}}># Eingehend</Td>
                            <Td style={{textAlign: "right"}}>Ausgehend</Td>
                            <Td style={{textAlign: "right"}}># Ausgehend</Td>
                            <Td style={{textAlign: "right"}}>Summe</Td>
                            <Td></Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows}
                    </Tbody>
                </Table>
            </React.Fragment>
        )

        return (
            <React.Fragment>
                {table}
            </React.Fragment>
        )
    }

}

export const AccountStatementTable = withTranslation()(withRouter(AccountStatementTableImpl));