import { lastValueFrom, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { ajax, AjaxError } from "rxjs/ajax";

export function encodeQueryData(data) {
    let ret = [] as string[];
    for (let d in data) {
        if (data[d] != undefined)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
 }

export class ResultOrError<R, E> {
    data?: R;
    error?: E;

    constructor(data?: R, error?: E) {
        this.data = data;
        this.error = error;
    }

    isServerError() {
        return this.error && this.error instanceof AjaxError;
    }
}

export class RemoteConnection {
    private _baseURL: string;

    constructor(baseURL: string) {
        this._baseURL = baseURL;
    }

    get<R, E>(path: string, result: Subject<ResultOrError<R, E>>, mapResult?: (any) => R) {
        // Fetch AJAX. If we get an HTTP error, call 
        ajax.getJSON<any>(this._baseURL + path)
            .pipe(
                tap({
                    next: (resp) => {
                        if (resp.error) {
                            result.next(new ResultOrError<R, E>(undefined, resp.error));
                        }
                        else {
                            const param = mapResult ? mapResult(resp) : resp;
                            result.next(new ResultOrError<R, E>(param));
                        }
                    },
                    error: (err) => {
                        result.next(new ResultOrError<R, E>(undefined, err));
                    }
                })
            )
            .subscribe();
    }

    getPromise<R, E>(path: string, mapResult?: (any) => R): Promise<ResultOrError<R, E>> {
        const observable = ajax.get<any>(this._baseURL + path);
        return lastValueFrom(observable).then(
            (value) => {
                if (value.response.error) {
                    return new ResultOrError<R, E>(undefined, value.response.error);
                }
                else {
                    const param = mapResult ? mapResult(value.response) : value.response;
                    return new ResultOrError<R, E>(param);
                }
            },
            (evalue) => {
                throw new ResultOrError<R, E>(undefined, evalue);
            });
    }

    postPromise<R, E>(path: string, value: any, mapResult?: (any) => R): Promise<ResultOrError<R, E>> {
        const body = JSON.stringify(value);
        const observable = ajax.post<any>(this._baseURL + path, body, { "Content-Type": "application/json;charset=UTF-8" });
        return lastValueFrom(observable).then(
            (value) => {
                if (value.response.error) {
                    return new ResultOrError<R, E>(undefined, value.response.error);
                }
                else {
                    const param = mapResult ? mapResult(value.response) : value.response;
                    return new ResultOrError<R, E>(param);
                }
            },
            (evalue) => {
                throw new ResultOrError<R, E>(undefined, evalue);
            });
    }
}