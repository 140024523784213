import { encodeQueryData, RemoteConnection} from "@app/RemoteConnection";
import { IDepositAPI, IPayPalLogEntry, IStripeLogEntry, PaymentMethod, StripeCreateResult } from "@app/model/IDepositAPI";
import { TableFilterQuery, TableRowData } from "@app/model/IDBHelpers";
import { IDepositSettings } from "@app/model/ISettingsAPI";

export class DepositAPI implements IDepositAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchLimits() {
        return this._connection.getPromise<IDepositSettings, void>("deposit/limits");
    }

    public fetchMethods() {
        return this._connection.getPromise<PaymentMethod[], void>("deposit/methods");
    }

    public createStripePayment(amount: number, type: string) {
        return this._connection.postPromise<StripeCreateResult, void>("stripe/payment", {
            amount: amount,
            type: type
        });
    }

    public fetchPayPalLog(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IPayPalLogEntry>, void>(`paypal/log?${args}`);
    }

    public fetchStripeLog(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IStripeLogEntry>, void>(`stripe/log?${args}`);
    }
}