import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Checkbox,
    Form,
    FormGroup,
    FormSelect,
    FormSelectOption,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';


export interface PayPalPanelProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class PayPalPanelImpl extends React.Component<PayPalPanelProps> {
    public state = {
        isLoading: true,
        saveStatus: undefined as (undefined | "success" | "saving"),

        enabled: false,
        env: "",
        clientID: "",
        clientSecret: "",
        fixedFee: "",
        variableFee: ""

    }

    componentDidMount() {
        this.props.settingsAPI.fetchPayPalSettings().then(value => {
            const data = value.data!;
            this.setState({
                enabled: data.enabled,
                env: data.env,
                clientID: data.clientID,
                clientSecret: data.clientSecret,
                fixedFee: data.fixedFee.toFixed(2),
                variableFee: data.variableFee,
                isLoading: false
            });
        }).catch(error => {
            this.props.onAlert(`Could not load paypal settings: ${JSON.stringify(error)}`, "danger");
        });
    }

    onSave = () => {
        this.setState({ saveStatus: "saving" });
        const info = {
            enabled: this.state.enabled,
            env: this.state.env,
            clientID: this.state.clientID,
            clientSecret: this.state.clientSecret,
            fixedFee: Number.parseFloat(this.state.fixedFee),
            variableFee: Number.parseFloat(this.state.variableFee)
        };
        this.props.settingsAPI.postPayPalSettings(info).then(value => {
            if (value.data) {
                this.setState({ saveStatus: "success" });
            }
            else {
                this.setState({ saveStatus: "failed" });
            }
        }).catch(error => {
            const msg = error.error.message ? error.error.message : "";
            this.props.onAlert(`Could not store paypal settings: ${msg}`, "danger");
            this.setState({ saveStatus: "failed" });
        });
    }

    render() {
        const { t } = this.props;
        const { saveStatus, clientSecret, clientID, env,
            enabled, fixedFee, variableFee,
            isLoading } = this.state;

        return (
            <SaveablePanel
                title="PayPal"
                saveStatus={saveStatus}
                isLoading={isLoading}
                onSave={this.onSave}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <FormGroup
                        label="Aktiviert:"
                        fieldId="paypalEnabled"
                    >
                        <Checkbox
                            id="paypalEnabled"
                            isChecked={enabled}
                            onChange={(value) => this.setState({ enabled: value })}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Environment:"
                        fieldId="paypalEnv"
                    >
                        <FormSelect
                            value={env}
                            onChange={(value, event) => this.setState({ env: value })}
                            id="paypalEnv"
                        >
                            <FormSelectOption value="production" label="Production" />
                            <FormSelectOption value="sandbox" label="Sandbox" />
                        </FormSelect>
                    </FormGroup>
                    <FormGroup
                        label="Client ID:"
                        fieldId="paypalCientID"
                    >
                        <TextInput
                            id='clientID-paypal'
                            value={clientID}
                            onChange={value => {
                                this.setState({ clientID: value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Client Secret:"
                        fieldId="paypalClientSecret"
                    >
                        <TextInput
                            id='clientSecret-paypal'
                            value={clientSecret}
                            onChange={value => {
                                this.setState({ clientSecret: value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Feste Gebühr:"
                        fieldId="paypalFixedFee"
                    >
                        <TextInput
                            id='fixedFee-paypal'
                            value={fixedFee}
                            onChange={value => {
                                this.setState({ fixedFee: value });
                            }}
                        />
                    </FormGroup>
                    <FormGroup
                        label="Relative Gebühr"
                        fieldId="paypalVariableFee"
                    >
                        <TextInput
                            id='variableFee-paypal'
                            value={variableFee}
                            onChange={value => {
                                this.setState({ variableFee: value });
                            }}
                        />
                    </FormGroup>
                </Form>
            </SaveablePanel>
        );
    }
}

export const PayPalPanel = withTranslation()(PayPalPanelImpl);