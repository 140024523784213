import React from "react";
import { WithRouter, withRouter } from "@app/RouterComponent";
import { IRfidAPI } from "@app/model/IRfidAPI";
import { WithTranslation, withTranslation } from "react-i18next";
import { Alert, Divider, Flex, FlexItem, PageSection, Panel, PanelHeader, PanelMain, PanelMainBody } from "@patternfly/react-core";
import { RFIDsTable } from "./RFIDsPage/RFIDTable";
import { matchPath } from "react-router-dom";


export interface RFIDsPageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    rfidAPI: IRfidAPI
}

class RFIDsPageImpl extends React.Component<RFIDsPageProps> {
    public state = {
        reloadCounter: 0
    }

    render() {
        const { t, onAlert, rfidAPI, params } = this.props;
        const { reloadCounter } = this.state;
        
        document.title = "RFID Tags";

        const deleteRfidId = Number((params as any).id);
        const deleteRfid = matchPath("/rfids/deleteRfid/:id", location.pathname) ? true : false;

        if (deleteRfid) {
            this.props.rfidAPI.removeRFID(deleteRfidId);
            this.setState({ reloadCounter: reloadCounter + 1 });
            this.props.onAlert("RFID Tag erfolgreich gelöscht", "success");

            this.props.navigate(`/rfids`);
        }

        return (
            <PageSection variant="light" isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>RFID Tags</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <RFIDsTable
                                        onAlert={onAlert}
                                        rfidAPI={rfidAPI}
                                        onRemoveRfid={(id) => {this.props.navigate(`deleteRfid/${id}`);}}
                                        reloadTriggerCounter={reloadCounter}
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        )
    }
}

export const RFIDsPage = withTranslation()(withRouter(RFIDsPageImpl));
