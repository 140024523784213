import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    Text,
    TextVariants,
    ActionGroup,
    Button,
    Form,
    FormGroup,
    Select,
    SelectOption,
    TextInput,
    InputGroup,
    InputGroupText,
    ValidatedOptions,
    Checkbox,
    MenuToggle,
} from '@patternfly/react-core';
import { DepositLimits, PaymentMethod, IDepositAPI } from '@app/model/IDepositAPI';
import { IProductAPI } from '@app/model/IProductAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { SaveIcon } from '@patternfly/react-icons';

export interface NewProductDialogrops extends WithTranslation {
    isOpen: boolean,
    onClose: () => void,
    productAPI: IProductAPI,
    onAlert: (title: string, variant: string) => void
}

class NewProductDialogImpl extends React.Component<NewProductDialogrops> {
    public state = {
        name: "",
        price: "0.00",
        inStock: false,
        auxdata: "",
        category: "snack",
        categorySelOpen: false,

        nameValid: true,
        priceValid: true,
    }

    createProduct = () => {
        const { productAPI, onAlert, onClose } = this.props;
        const { name, price, inStock, auxdata, category } = this.state;

        productAPI.createProduct({
            id: 0,
            name: name,
            price: Number(price),
            in_stock: inStock,
            category: category,
            auxdata: auxdata
        }).then((result) => {
            this.setState({name: "", price: "0.00", inStock: false, auxdata: "", category: "snack"});
            onAlert(`Produkt angelegt`, "success");
            onClose();
        }).catch((error) => {
            onAlert(`Could not create product: ${JSON.stringify(error)}`, "danger");
        });
    }

    render() {
        const { isOpen, t, onClose } = this.props;
        const { name, price, category, auxdata, inStock, categorySelOpen } = this.state;

        const categories = ["snack", "drink", "coffee"];
        const categoryOptions = categories.map((val, i) => { return (<SelectOption value={val} key={i}>{val}</SelectOption>) });

        return (
            <React.Fragment>
                <Modal
                    variant={ModalVariant.medium}
                    title={t("berta:products.new.title", "Neues Produkt")}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <TextContent>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                this.createProduct();
                            }}
                        >
                            <ValidatableInputField
                                title="Name"
                                inputId="name"
                                validateFor={ValidationOptions.LengthMoreThan0}
                                value={name}
                                onChange={(value, valid) => {
                                    this.setState({ name: value, nameValid: valid })
                                }}
                            />
                            <FormGroup label="Preis" fieldId='total'>
                                <InputGroup>
                                <ValidatableInputField
                                    withoutFormGroup={true}
                                    title=''
                                    inputId={"price"}
                                    value={price}
                                    validateFor={ValidationOptions.TwoDigitFixedPoint}
                                    onChange={(value, valid) => {
                                        this.setState({ price: value, priceValid: valid })
                                    }}
                                />
                                <InputGroupText >€</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup label="Verfügbar?" fieldId='inStock'>
                                <Checkbox id="instock"
                                    isChecked={inStock}
                                    onChange={() => {this.setState({ inStock: !inStock })}}
                                />
                            </FormGroup>
                            <FormGroup label="Kategorie" fieldId='category'>
                                <Select
                                    isOpen={categorySelOpen}
                                    onSelect={(_event, value) => {
                                        this.setState({ category: value, categorySelOpen: false })
                                    }}
                                    selected={category}
                                    toggle={(toggleRef) => (
                                        <MenuToggle ref={toggleRef} onClick={() => {
                                                this.setState({ categorySelOpen: !categorySelOpen })
                                            }}
                                            isExpanded={categorySelOpen}
                                        >
                                            {category}
                                        </MenuToggle>
                                    )}
                                >
                                    {categoryOptions}
                                </Select>
                            </FormGroup>
                            <FormGroup label="Extra Daten" fieldId='auxdata'>
                                <TextInput
                                    type="text" value={auxdata}
                                    onChange={(_event, value) => this.setState({ auxdata: value })} 
                                />
                            </FormGroup>
                            <ActionGroup>
                                <Button variant="primary" onClick={this.createProduct}><SaveIcon /> Speichern</Button>
                            </ActionGroup>
                        </Form>
                    </TextContent>
                </Modal>
            </React.Fragment>
        );
    }
}

export const NewProductDialog = withTranslation()(NewProductDialogImpl);