import { encodeQueryData, RemoteConnection } from "@app/RemoteConnection";
import { ISignupData, ISignupResult, IUserAPI, IUser, LoginResult, IUserRegisterCode } from "@app/model/IUserAPI";
import { TableFilterQuery, TableRowData } from "@app/model/IDBHelpers";

export class User implements IUser {
    id = -1;
    firstname = "";
    lastname = "";
    email = "";
    password = "";
    register_date = "";
    last_login = "";
    admin = false;
    snack_access = false;
    active = false;
    balance = 0.0;


    constructor(json: any) {
        if (json.id) {
            this.id = json.id;
            this.firstname = json.firstname;
            this.lastname = json.lastname;
            this.email = json.email;
            this.password = json.password;
            this.register_date = json.register_date;
            this.last_login = json.last_login;
            this.admin = json.admin;
            this.snack_access = json.snack_access;
            this.active = json.active;
            this.balance = json.balance;
        }
    }

    isLoggedin() {
        return this.id != -1;
    }
}

export class UserAPI implements IUserAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchUser(id?: number) {
        if (id !== undefined) {
            return this._connection.getPromise<User, void>(`user/${id}`, (data) => new User(data));
        }
        else {
            return this._connection.getPromise<User, void>("me", (data) => new User(data));
        }
    }

    updateUser(info: IUser, passwordOld?: string, passwordNew?: string) {
        const data = {
            firstname: info.firstname,
            lastname: info.lastname,
            email: info.email,
            passwordNew: passwordNew? passwordNew : "",
            passwordOld: passwordOld? passwordOld : "",
            snack_access: info.snack_access,
            admin: info.admin,
            active: info.active
        };
        return this._connection.postPromise<boolean, void>(`user/${info.id}`, data);
    }

    login(email: string, password: string) {
        return this._connection.postPromise<LoginResult, void>("login_user",
            { user: email, password: password });
    }

    logout() {
        return this._connection.getPromise<void, void>("logout_user");
    }

    public fetchUsers(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IUser>, void>(`users?${args}`);
    }
 

    postSignup(data: ISignupData) {
        return this._connection.postPromise<ISignupResult, string>(`user/create`, data);
    }

    resetPassword(code: string, password: string) {
        const data = {
            code: code,
            password: password
        };
        return this._connection.postPromise<boolean, void>(`user/reset_password`, data);
    }

    requestPasswordReset(email: string) {
        const data = {
            email: email
        };
        return this._connection.postPromise<boolean, void>(`user/request_password`, data);
    }

    fetchUserRegisterCodes(query: TableFilterQuery) {
        let args = encodeQueryData({
            "page": query.page,
            "perPage": query.perPage,
            "sortBy": query.sortBy,
            "sortDir": query.sortDirection,
            "filter": query.filter == ""? undefined : query.filter
        });
        return this._connection.getPromise<TableRowData<IUserRegisterCode>, void>(`user/register_codes?${args}`);
    }

    deleteUserRegisterCode(id: number) {
        const data = {
            id: id
        };
        return this._connection.postPromise<boolean, void>(`user/deleteRegisterCode`, data);
    }
}