import { ResultOrError } from "@app/RemoteConnection";

export interface BuyCountEntry {
    product_name: string,
    count: number
}

export interface StatsEntry {
    name: string,
    date: string,
    count: number,
    dayofyear: number,
    yearweek: number,
    yearmonth: number,
    year : number,
}

export interface StatsEntryForFrontend {
    productOrName: string,
    datetext: string,
    count: number
}

export interface StatsFilterEntry {
    id: number,
    text: string
}

export enum StatsPeriod {
    All = 0,
    LastWeek = 1,
    LastMonth = 2,
    LastYear = 3,
    Last5Years = 4,
}

export enum StatsResolution {
    Yearly = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
}

export const StatsPeriodStrings: string[] = [
    "Alles", "Letzte Woche", "Letzter Monat", "Letztes Jahr", "Letzte 5 Jahre"
]

export const StatsResolutionStrings: string[] = [
    "Jährlich", "Täglich", "Wöchentlich", "Monatlich"
]


export interface IStatsAPI {
    fetchBuyCount: (uid: number, from: Date) => Promise<ResultOrError<BuyCountEntry[], void>>,
    fetchUserRanking: (pid: number, period: StatsPeriod, top_n: number) => Promise<ResultOrError<StatsEntryForFrontend[], void>>,
    fetchProductsStats: (uid: number, pid: number[], period: StatsPeriod, resolution: StatsResolution,
        min_count: number) => Promise<ResultOrError<StatsEntry[], void>>,
    fetchUserFilter: () => Promise<ResultOrError<StatsFilterEntry[], void>>,
    fetchProductFilter: () => Promise<ResultOrError<StatsFilterEntry[], void>>,
}