import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Divider,
    Text, TextVariants,
    Panel, PanelFooter, PanelHeader, PanelMain, PanelMainBody, Button, HelperText, HelperTextItem, Flex, FlexItem, Bullseye, Spinner
} from '@patternfly/react-core';


export interface SaveablePanelProps extends WithTranslation {
    title: string,
    children?: React.ReactNode,
    isLoading?: boolean,
    saveStatus?: ("saving" | "success" | "failed"),
    saveErrorMsg?: (undefined | string),
    saveDisabled?: boolean,
    onSave?: () => void,
    hideFooter?: boolean
}

class SaveablePanelImpl extends React.Component<SaveablePanelProps> {
    public state = {
    }

    render() {
        const { t, isLoading, saveStatus, saveErrorMsg, onSave, saveDisabled,
            title, children, hideFooter} = this.props;

        let footer = undefined as (undefined | JSX.Element);
        let content = undefined as (undefined | React.ReactNode);
        if (isLoading) {
            content = (
                <Bullseye>
                    <Spinner size="xl" />
                </Bullseye>
            );
        }
        else {
            if (children) {
                content = children;
            }

            let saveContent = undefined as (undefined | JSX.Element);
            if (saveStatus == "success") {
                saveContent = (
                    <HelperTextItem variant="success" hasIcon>
                        {t("berta:saveablePanel.saved")}
                    </HelperTextItem>
                );
            }
            else if (saveStatus == "failed") {
                saveContent = (
                    <HelperTextItem variant="error" hasIcon>
                        {saveErrorMsg ? saveErrorMsg : t("berta:saveablePanel.error")}
                    </HelperTextItem>
                );
            }
            else if (saveStatus == "saving") {
                saveContent = (
                    <Spinner size="md" />
                );
            }

            if (!hideFooter) {
                footer = (
                    <PanelFooter>
                        <Flex>
                            <FlexItem align={{ default: 'alignRight' }}>
                                <HelperText>
                                    {saveContent}
                                </HelperText>
                            </FlexItem>
                            <FlexItem>
                                <Button
                                    isDisabled={saveDisabled || saveStatus == "saving" ? true : false}
                                    onClick={() => { if (onSave) { onSave(); } }}>{t("berta:saveablePanel.save")}</Button>
                            </FlexItem>
                        </Flex>
                    </PanelFooter>
                );
            }
        }

        return (
            <Panel variant="raised">
                <PanelHeader>{title}</PanelHeader>
                <Divider />
                <PanelMain>
                    <PanelMainBody>
                        {content}
                    </PanelMainBody>
                </PanelMain>
                {footer}
            </Panel>
        );
    }
}

export const SaveablePanel = withTranslation()(SaveablePanelImpl);