import { IStatsAPI, StatsEntryForFrontend, StatsFilterEntry, StatsPeriod, StatsPeriodStrings, StatsResolution, StatsResolutionStrings } from "@app/model/IStatsAPI";
import { Chart, ChartAxis, ChartBar, ChartLegend, ChartStack, ChartThemeColor, ChartTooltip } from "@patternfly/react-charts";
import { Badge, Bullseye, EmptyState, EmptyStateBody, EmptyStateIcon, EmptyStateVariant, Menu, MenuContent, MenuItem, MenuList, MenuToggle, NumberInput, Popper, SearchInput, Select, SelectOption, Spinner, Title, Toolbar, ToolbarContent, ToolbarFilter, ToolbarItem } from "@patternfly/react-core";
import { FilterIcon, SearchIcon } from "@patternfly/react-icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserProductRankingProps } from "./ProductRanking";
import { Table, Tbody, Td, Thead, Tr } from "@patternfly/react-table";


export interface UserRankingTableProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    statsAPI: IStatsAPI
}

class UserRankingTableImpl extends React.Component<UserRankingTableProps>{

    public state = {
        data: undefined as (undefined | StatsEntryForFrontend[]),
        productFilter: undefined as (undefined | StatsFilterEntry[]),
        isLoading: true,

        // User Filter
        productFilterID: 32,
        productSearchInputValue: "Kaffee",
        productSelAutocompleteOpen: false,
        productSelAutocompleteHint: "",
        productSelAutocompleteOptions: undefined as (undefined | JSX.Element[]),

        // Queries
        periodSelection: 4,
        periodSelectionOpen: false,
        topNValue: 20,
    }

    searchInputRef = React.createRef();
    autocompleteRef = React.createRef();

    componentDidMount() {
        // and products
        this.props.statsAPI.fetchProductFilter().then(result =>
            this.setState({ productFilter: result.data!, productsSelected: [] })
        )

        this.refetchData()
    }

    componentDidUpdate(prevProps: Readonly<UserProductRankingProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.state.productFilterID != prevState.productFilterID || 
            this.state.topNValue != prevState.topNValue ||
            this.state.periodSelection != prevState.periodSelection) {
            this.refetchData();
        }
    }


    // Search Input fields
    onSearchInputChange = (_event, newValue) => {
        if ( newValue !== '' && this.searchInputRef) {
            let options = this.state.productFilter!
                .filter((option) => (option.text.toLowerCase().includes(newValue.toLowerCase())))
                .map((option) => (
                    <MenuItem itemId={option.id} key={option.text}>
                        {option.text}
                    </MenuItem>
                    )
                );
            if (options.length > 10) {
                options = options.slice(0, 10);
            }

            this.setState({
                productSelAutocompleteHint: options.length === 1 ? options[0].props.key : '',
                productSelAutocompleteOpen: true,
                productSelAutocompleteOptions: options,
                productSearchInputValue: newValue
            })
        } else {
            this.setState({ productSelAutocompleteOpen: false, productSearchInputValue: newValue })
        }
    }

    onSearchInputClear = () => {
        this.setState({ productSelAutocompleteOpen: false, productSearchInputValue: "", productFilterId: 0 })
    }

    onAutoCompleteSelect = (e, itemId) => {
        e.stopPropagation();
        let foundUser = this.state.productFilter?.find(( {id} ) => id == itemId);
        this.setState({ 
            productFilterID: itemId, 
            productSearchInputValue: foundUser ? foundUser.text : "",
            productSelAutocompleteOpen: false
        })
        this.searchInputRef.current.focus();
    }

    handleProductSelectionKeys = (event) => {
        if (this.state.productSelAutocompleteHint && (event.key === 'Tab' || event.key === 'ArrowRight') && this.searchInputRef.current === event.target) {
            this.setState({
                productSelAutocompleteHint: "",
                productSearchInputValue: "",
                productFilterID: 0,
                productSelAutocompleteOpen: false
            })
            if (event.key === 'ArrowRight')
                event.preventDefault();
        } else if (this.state.productSelAutocompleteOpen && this.searchInputRef.current && this.searchInputRef.current == event.target) {
            if (event.key === 'Escape') {
                this.setState({ productSelAutocompleteOpen: false });
                this.searchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                const firstElement = this.autocompleteRef.current.querySelector('li > button:not(:disabled)');
                firstElement && firstElement.focus();
                event.preventDefault();
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                this.setState({ productSelAutocompleteOpen: false });
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault();
                }
            }
        } else if (this.state.periodSelectionOpen && this.autocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            event.preventDefault();
            this.setState({ periodSelectionOpen: false });
            this.searchInputRef.current.focus();
        }
    }

    // Online stuff
    refetchData = () => {
        this.props.statsAPI.fetchUserRanking(this.state.productFilterID,
            this.state.periodSelection, this.state.topNValue).then(result => {
                this.setState({data: result.data!, isLoading: false})
            });
    }

    // Render rows
    renderRows = () => {
        let retRows: JSX.Element[] = [];
        if (! this.state.isLoading) {
            this.state.data!.forEach((v, idx) => {
                retRows.push(
                    <Tr key={idx}>
                        <Td>{v.productOrName}</Td>
                        <Td>{v.count}</Td>
                    </Tr>
                )
            })
        }
        return retRows
    }

    // Resoultion and Period
    onPeriodSelect = (_event, value) => {
        this.setState({
            periodSelectionOpen: false,
            periodSelection: value,
            isLoading: true,
        })
    }

    // Click outside handler
    handleProductSelectionClickOutside = (event: MouseEvent) => {
        if (this.state.productSelAutocompleteOpen && !this.searchInputRef.current?.contains(event.target as Node)) {
            this.setState({productSelAutocompleteOpen: false});
        }
    }

    // Count Dinger
    normalizeBetween = (value, min, max) => {
        if (min !== undefined && max !== undefined) {
            return Math.max(Math.min(value, max), min);
        } else if (value <= min) {
            return min;
        } else if (value >= max) {
            return max;
        }
        return value;
    };
    
    stepper = (value: number) => {
        this.setState({topNValue : this.normalizeBetween(this.state.topNValue + value, 0, 100)})
    }

    onTopNValueChange = (event) => {
        const value = (event.target as HTMLInputElement).value;
        this.setState({ topNValue : this.normalizeBetween(+value, 0, 100)});
    }

    render() {
        const { isLoading, productSearchInputValue, productSelAutocompleteOpen, productSelAutocompleteHint, 
            productSelAutocompleteOptions, data, topNValue, periodSelection, periodSelectionOpen } = this.state;

        window.addEventListener('click', this.handleProductSelectionClickOutside)
        window.addEventListener('keydown', this.handleProductSelectionKeys)

        const productSearchInput = (
            <SearchInput
                value={productSearchInputValue}
                onChange={this.onSearchInputChange}
                onClear={this.onSearchInputClear}
                ref={this.searchInputRef}
                hint={productSelAutocompleteHint}
                id="product-autocomplete-search"
            />
        );

        const productAutocomplete = (
            <Menu ref={this.autocompleteRef} onSelect={this.onAutoCompleteSelect}>
                <MenuContent>
                    <MenuList>{productSelAutocompleteOptions}</MenuList>
                </MenuContent>
            </Menu>
        );

        const periodToggle = (toggleRef) => (
            <MenuToggle ref={toggleRef} onClick={() => {this.setState({ periodSelectionOpen: !periodSelectionOpen})}} 
                    isExpanded={periodSelectionOpen} style={{ width: '200px' } as React.CSSProperties}>
                {StatsPeriodStrings[periodSelection]}
            </MenuToggle>
        )


        const periodSelectionOptions = StatsPeriodStrings.map((val, i) => {return <SelectOption value={i} key={i}>{val}</SelectOption> });

        let rows = undefined as JSX.Element[] | undefined;
        if(isLoading) {
            rows = [
                <Tr key="loading">
                    <Td colSpan={2}>
                        <Bullseye>
                            <Spinner size="xl" />
                        </Bullseye>
                    </Td>
                </Tr>
            ];
        }
        else if (data!.length == 0) {
            rows = [
                <Tr key="no-results">
                    <Td colSpan={2}>
                        <Bullseye>
                            <EmptyState variant={EmptyStateVariant.sm}>
                                <EmptyStateIcon icon={SearchIcon} />
                                <Title headingLevel="h2" size="lg">
                                    No results found
                                </Title>
                                <EmptyStateBody>Clear all filters and try again.</EmptyStateBody>
                            </EmptyState>
                        </Bullseye>
                    </Td>
                </Tr>
            ];
        }
        else {
            rows = this.renderRows();
        }

        const toolbar = (
            <React.Fragment>
            <Toolbar clearAllFilters={() => {this.setState({ productFilterID: 0 })}}>
            <ToolbarContent>
                <ToolbarItem variant="label">Produkt:</ToolbarItem>
                <ToolbarItem>
                    <Popper
                        triggerRef={this.searchInputRef}
                        trigger={productSearchInput}
                        popperRef={this.autocompleteRef}
                        popper={productAutocomplete}
                        isVisible={productSelAutocompleteOpen}
                        enableFlip={false}
                        appendTo={() => document.querySelector("#product-autocomplete-search")}
                    />
                </ToolbarItem>
                <ToolbarItem variant="label">Zeitraum:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={periodToggle}
                        isOpen={periodSelectionOpen}
                        onSelect={this.onPeriodSelect}
                        selected={StatsPeriodStrings[periodSelection]}
                        onOpenChange={(isOpen) => {this.setState({periodSelectionOpen: isOpen})}}
                    >
                        {periodSelectionOptions}
                    </Select>
                </ToolbarItem>
                <ToolbarItem variant="label">Anzahl:</ToolbarItem>
                <ToolbarItem>
                    <NumberInput
                        value={topNValue}
                        onMinus={() => this.stepper(-5)}
                        onChange={this.onTopNValueChange}
                        onPlus={() => this.stepper(5)}
                    />
                </ToolbarItem>
            </ToolbarContent>
            </Toolbar>
            </React.Fragment>
        )

        const table = (
            <React.Fragment>
                <Table variant="compact" borders={true}>
                    <Thead>
                        <Tr>
                            <Td>Username</Td>
                            <Td>Anzahl</Td>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows}
                    </Tbody>
                </Table>
            </React.Fragment>
        )

        return (
            <React.Fragment>
                {toolbar}
                {table}
            </React.Fragment>
        );
    }
}

export const UserRankingTable = withTranslation()(UserRankingTableImpl);
