import React from 'react';
import {
    LoginForm,
    LoginMainFooterBandItem,
    LoginPage,
} from '@patternfly/react-core';
import { withTranslation, WithTranslation } from 'react-i18next';
import bertabackground from '../../../assets/bertabackground.jpg';

import ExclamationCircleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-circle-icon';
import { ResetPasswordDialog } from './LoginPage/ResetPasswortDialog';
import { RegisterWizard } from './LoginPage/RegisterWizard';
import { withRouter, WithRouter } from '@app/RouterComponent';
import { Link, matchPath } from 'react-router-dom';
import { IUserAPI } from '@app/model/IUserAPI';

export interface BertaLoginPageProps extends WithTranslation, WithRouter {
    textContent?: string,
    showRegister?: boolean,
    showResetPass?: boolean,
    onLoggedIn: () => void,
    userAPI: IUserAPI
}

class BertaLoginPageImpl extends React.Component<BertaLoginPageProps> {
    //static contextType = GlobalContext;

    public state = {
        showHelperText: false,
        helperText: "",
        usernameValue: '',
        isValidUsername: true,
        passwordValue: '',
        isValidPassword: true,
        isRememberMeChecked: false,
        resetPasswordError: "",
        resetPasswordSuccess: "",
    };

    handleUsernameChange = (_event, value) => {
        this.setState({ usernameValue: value });
    };

    handlePasswordChange = (_event, passwordValue) => {
        this.setState({ passwordValue });
    };

    onRememberMeClick = () => {
        this.setState({ isRememberMeChecked: !this.state.isRememberMeChecked });
    };

    onLoginButtonClick = event => {
        event.preventDefault();
        this.setState({ isValidUsername: !!this.state.usernameValue });
        this.setState({ isValidPassword: !!this.state.passwordValue });
        const dataInvalid = !this.state.usernameValue || !this.state.passwordValue;
        this.setState({ showHelperText: dataInvalid, helperText: "berta:login.userPassEmpty" });
        
        if (!dataInvalid) {
            this.props.userAPI.login(this.state.usernameValue, this.state.passwordValue).then((value) => {
                if (value.data!.loginOK) {
                    this.props.onLoggedIn();
                }
                else {
                    this.setState({
                        showHelperText: true,
                        helperText: "berta:login.invalidLoginCred"
                    });
                }
            }).catch((value) => {
                this.setState({
                    showHelperText: true,
                    helperText: "berta:login.serverError"
                });
            });
        }
    };

    onResetPassword = email => {
        this.props.userAPI.requestPasswordReset(email).then(result => {
            this.setState({ resetPasswordSuccess: "berta:login.passwortResetSuccess" });
        }).catch(error => {
            this.setState({ resetPasswordError: "berta:login.passwortResetError"});
        });
    }

    public render() {
        const { t, textContent, showRegister, showResetPass } = this.props;
        const { resetPasswordError, resetPasswordSuccess, helperText} = this.state;

        const registerWizardOpen = matchPath("/register", this.props.location.pathname) ? true : false;
        const resetPasswordOpen = matchPath("/login/reset-password", this.props.location.pathname) ? true : false;

        if (registerWizardOpen)
            document.title = t("berta:register.pageTitle");
        else if (resetPasswordOpen)
            document.title = t("berta:passwordReset.pageTitle");
        else
            document.title = t("berta:login.pageTitle");

        const helperElem = (
            <React.Fragment>{t(helperText)}</React.Fragment>
        );

        const signUpForAccountMessage = (showRegister == undefined || showRegister) ? (
            <LoginMainFooterBandItem>
                {t("berta:login.needAccount", "Need an account?")} <Link to="/register">{t("berta:login.signup", "Sign up.")}</Link>
            </LoginMainFooterBandItem>
        ) : undefined;
        const forgotCredentials = (showResetPass == undefined || showResetPass) ? (
            <LoginMainFooterBandItem>
                <Link to="/login/reset-password">{t("berta:login.forgotPW", "Forgot the password?")}</Link>
            </LoginMainFooterBandItem>
        ) : undefined;

        const loginForm = (
            <LoginForm
                showHelperText={this.state.showHelperText}
                helperText={helperElem}
                helperTextIcon={<ExclamationCircleIcon />}
                usernameLabel={t("berta:login.mail", "Email")}
                usernameValue={this.state.usernameValue}
                onChangeUsername={this.handleUsernameChange}
                isValidUsername={this.state.isValidUsername}
                passwordLabel={t("berta:login.password", "Password")}
                passwordValue={this.state.passwordValue}
                onChangePassword={this.handlePasswordChange}
                isValidPassword={this.state.isValidPassword}
                // FIXME: Not implemented for now
                //rememberMeLabel={t("berta:login.keepLoggedIn", "Keep me logged in")}
                isRememberMeChecked={this.state.isRememberMeChecked}
                onChangeRememberMe={this.onRememberMeClick}
                
                onLoginButtonClick={this.onLoginButtonClick}
                loginButtonLabel={t("berta:login.login", "Log in")}
            />
        );

        return (
            <React.Fragment>
            <LoginPage
                textContent={textContent}
                loginTitle={t("berta:login.title", "Log in to your account")}
                backgroundImgSrc={bertabackground}
                signUpForAccountMessage={signUpForAccountMessage}
                forgotCredentials={forgotCredentials}
            >
                {loginForm}
            </LoginPage>
            <ResetPasswordDialog
                isOpen={resetPasswordOpen}
                onClose={() => this.props.navigate("/login")}
                onResetPassword={this.onResetPassword}
                errorText={resetPasswordError}
                successText={resetPasswordSuccess}
            />
            <RegisterWizard
                userAPI={this.props.userAPI}
                isOpen={registerWizardOpen}
                onClose={() => this.props.navigate("/login")}
            />
            </React.Fragment>
        );
    }
}

export const JLoginPage = withTranslation()(withRouter(BertaLoginPageImpl));