import React from 'react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@patternfly/react-table';
import {
    Toolbar, ToolbarItem, ToolbarContent, Button, ButtonVariant,
    InputGroup, Pagination, PaginationVariant, EmptyState,
    EmptyStateIcon, Title, EmptyStateBody, Bullseye, EmptyStateVariant, Spinner,
    DatePicker, ToolbarGroup, SearchInput
} from '@patternfly/react-core';
import { withTranslation, WithTranslation } from 'react-i18next';

import ClearIcon from '@patternfly/react-icons/dist/esm/icons/close-icon';
import SearchIcon from '@patternfly/react-icons/dist/esm/icons/search-icon';
import { TableFilterQuery } from '@app/model/IDBHelpers';


export interface IRowHeader {
    name: string,
    defaultSort?: ("asc" | "desc"),
    sortKey?: string,
}

export interface RemoteTableUIProps extends WithTranslation {
    hasFrom: boolean,
    hasTo: boolean,
    hasFilter: boolean,
    isLoading: boolean,
    headers: IRowHeader[],
    rows: JSX.Element[],
    totalRows: number,
    defaultRows: number,
    defaultSortIndex?: number,
    extraFilter: any,
    reloadTriggerCounter?: number,
    fetchOnMount: boolean,
    onFetchData: (query: TableFilterQuery) => void,
}

class RemoteTableUIImpl extends React.Component<RemoteTableUIProps> {
    filterTimeout = 0;

    constructor(props) {
        super(props);
        this.filterTimeout = 0;
    }

    public state = {
        filter: "",
        from: undefined as (Date | undefined),
        to: undefined as (Date | undefined),
        toText: "",
        fromText: "",
        sortIndex: this.props.defaultSortIndex,
        sortDirection:
            this.props.defaultSortIndex == undefined ? "asc" : this.props.headers[this.props.defaultSortIndex].defaultSort,
        currentPage: 1,
        perPage: this.props.defaultRows,
        isLoadingOverwrite: false,
    };

    refetchData = () => {
        const { filter, sortIndex, sortDirection, from, to, currentPage, perPage } = this.state;
        const { headers, extraFilter } = this.props;
        const sortBy = sortIndex == undefined ? undefined : headers[sortIndex].sortKey;

        this.props.onFetchData({
            filter: filter,
            sortBy: sortBy,
            sortDirection: sortDirection == "asc" ? "ASC" : "DESC",
            page: currentPage,
            perPage: perPage,
            from: from,
            to: to,
            extra: extraFilter
        });
        this.setState({ isLoadingOverwrite: false });
    }

    componentDidUpdate(prevProps) {
        if (this.props.extraFilter != prevProps.extraFilter || 
                this.props.reloadTriggerCounter != prevProps.reloadTriggerCounter) {
            this.refetchData();
        }
    }

    componentDidMount() {
        if (this.props.fetchOnMount)
            this.refetchData();
    }

    onChangeFilter = (value: string) => {
        this.setState({ filter: value, isLoadingOverwrite: true });
        if (this.filterTimeout) clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(() => {
            this.refetchData();
        }, 1000);
    }

    public render() {
        const { headers, rows, isLoading, totalRows, t } = this.props;
        const { sortIndex, sortDirection, filter, toText,
            fromText, currentPage, perPage, isLoadingOverwrite } = this.state;


        const headersElem = headers.map((elem, i) => {
            if (elem.defaultSort) {
                return (
                    <Th
                        key={i}
                        sort={{
                            columnIndex: i,
                            sortBy: {
                                index: sortIndex,
                                direction: sortDirection,
                                defaultDirection: elem.defaultSort
                            },
                            onSort: (event, index, direction) => {
                                this.setState({ sortIndex: index, sortDirection: direction }, this.refetchData);
                            }

                        }}
                        modifier='truncate'
                    >
                        {elem.name}
                    </Th>
                );
            }
            else {
                return (<Th key={i}>{elem.name}</Th>);
            }
        });

        const toolbar = (
            <Toolbar id="toolbar-items">
                <ToolbarContent>
                    {this.props.hasFilter &&
                        <ToolbarGroup>
                            <ToolbarItem>
                                <SearchInput
                                    placeholder="Filter"
                                    value={filter}
                                    onChange={(event, value) => {
                                        this.onChangeFilter(value);
                                    }}
                                    onClear={() => {
                                        this.onChangeFilter("");
                                    }}
                                />
                            </ToolbarItem>
                        </ToolbarGroup>
                    }
                    <ToolbarGroup align={{ default: 'alignRight' }}>
                        {this.props.hasFrom && 
                            <React.Fragment>
                                <ToolbarItem variant="label">{t("berta:queries.from")}</ToolbarItem>
                                <ToolbarItem>
                                    <InputGroup>
                                        <DatePicker value={fromText} helperText="" invalidFormatText="" onChange={(_event, str, date) => {
                                            this.setState({ from: date, fromText: str }, this.refetchData);
                                        }} />
                                        <Button variant={ButtonVariant.control} onClick={() => {
                                            this.setState({ from: undefined }, this.refetchData);
                                        }}><ClearIcon /></Button>
                                    </InputGroup>
                                </ToolbarItem>
                            </React.Fragment>
                        }
                        {this.props.hasTo && 
                            <React.Fragment>
                                <ToolbarItem variant="label">{t("berta:queries.to")}</ToolbarItem>
                                <ToolbarItem>
                                    <InputGroup>
                                        <DatePicker value={toText} helperText="" invalidFormatText="" onChange={(_event, str, date) => {
                                            this.setState({ to: date, toText: str }, this.refetchData);
                                        }} />
                                        <Button variant={ButtonVariant.control} onClick={() => {
                                            this.setState({ from: undefined }, this.refetchData);
                                        }} ><ClearIcon /></Button>
                                    </InputGroup>
                                </ToolbarItem>
                            </React.Fragment>
                        }
                    </ToolbarGroup>
                </ToolbarContent>
            </Toolbar>
        );

        let rowElem = undefined as JSX.Element[] | undefined;
        if (isLoading || isLoadingOverwrite) {
            rowElem = [
                <Tr key="loading">
                    <Td colSpan={headers.length}>
                        <Bullseye>
                            <Spinner size="xl" />
                        </Bullseye>
                    </Td>
                </Tr>
            ];
        }
        else if (rows.length == 0) {
            rowElem = [
                <Tr key="no-results">
                    <Td colSpan={headers.length}>
                        <Bullseye>
                            <EmptyState variant={EmptyStateVariant.sm}>
                                <EmptyStateIcon icon={SearchIcon} />
                                <Title headingLevel="h2" size="lg">
                                    {t("berta:queries.noResults")}
                                </Title>
                                <EmptyStateBody>{t("berta:queries.noResultsHint")}</EmptyStateBody>
                            </EmptyState>
                        </Bullseye>
                    </Td>
                </Tr>
            ];
        }
        else {
            rowElem = rows;
        }

        return (
            <React.Fragment>
                {toolbar}
                <Table
                    variant="compact"
                    borders={true}
                >
                    <Thead>
                        <Tr key="rtable-headers">
                            {headersElem}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rowElem}
                    </Tbody>
                </Table>
                <Pagination
                    itemCount={totalRows}
                    widgetId="pagination-options-menu-bottom"
                    perPage={perPage}
                    page={currentPage}
                    variant={PaginationVariant.bottom}
                    onSetPage={(event, page) => {
                        this.setState({ currentPage: page }, this.refetchData);
                    }}
                    onPerPageSelect={(event, perPage) => {
                        this.setState({ perPage: perPage }, this.refetchData);
                    }}
                />
            </React.Fragment>
        );
    }
}

// Don't know how to pass generics through HOCs, so have to type extraFilter as any....
export const RemoteTableUI = withTranslation()(RemoteTableUIImpl);