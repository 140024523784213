import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Flex,
    FlexItem,
    PageSection,
} from '@patternfly/react-core';
import { UserdataPanel } from './UserSettingsPage/UserdataPanel';
import { RFIDPanel } from './UserSettingsPage/RFIDPanel';
import { IUserAPI } from '@app/model/IUserAPI';
import { IRfidAPI } from '@app/model/IRfidAPI';
import { WithRouter, withRouter } from '@app/RouterComponent';


export interface UserPageProps extends WithTranslation, WithRouter {
    userAPI: IUserAPI,
    uid: number,
    rfidAPI: IRfidAPI,
    onAlert: (title: string, variant: string) => void
}

class UserPageImpl extends React.Component<UserPageProps> {

    render() {
        const { t, uid, userAPI, rfidAPI, onAlert, params } = this.props;
        document.title = t("berta:userSettings.pageTitle");

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem><UserdataPanel uid={uid} userAPI={userAPI} onAlert={onAlert}/></FlexItem>
                    <FlexItem><RFIDPanel uid={uid} onAlert={onAlert} rfidAPI={rfidAPI}/></FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const UserPage = withTranslation()(withRouter(UserPageImpl));
