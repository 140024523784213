import React, { useDebugValue } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Form,
    TextInput
} from '@patternfly/react-core';
import { SaveablePanel } from '@app/ui/SaveablePanel';
import { SettingsAPI } from '@app/api/SettingsAPI';
import { ValidatableInputField, ValidationOptions } from '../ValidateableInputField';
import { number } from 'prop-types';


export interface TimeoutPanelProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    settingsAPI: SettingsAPI
}

class TimeoutPanelImpl extends React.Component<TimeoutPanelProps> {
    public state = {
        isLoading: true,
        saveStatus: undefined as (undefined | "success" | "saving"),
        rfidRegisterCodes: 0,
        rfidRegisterCodesValid: false,
        userRegisterCodes: 0,
        userRegisterCodesValid: false,
        userNotify: 0,
        userNotifyValid: false,
        userDeactivate: 0,
        userDeactivateValid: false,
        userDelete: 0,
        userDeleteValid: false,
    }

    componentDidMount() {
        this.props.settingsAPI.fetchTimeoutSettings().then(value => {
            const data = value.data!;
            this.setState({
                rfidRegisterCodes: data.rfidRegisterCodes,
                userRegisterCodes: data.userRegisterCodes,
                userNotify: data.userNotify,
                userDeactivate: data.userDeactivate,
                userDelete: data.userDelete,
                isLoading: false});
        }).catch(error => {
            this.props.onAlert(`Could not load connector settings: ${JSON.stringify(error)}`, "danger"); 
        });
    }

    allValid = () => {
        const { rfidRegisterCodesValid, userDeactivateValid, userDeleteValid,
            userNotifyValid, userRegisterCodesValid } = this.state;

        return rfidRegisterCodesValid && userDeactivateValid && userDeleteValid &&
            userNotifyValid && userRegisterCodesValid;
    }

    onSave = () => {
        if (this.allValid()) {
            this.setState({ saveStatus: "saving" });

            this.props.settingsAPI.postTimeoutSettings({ 
                rfidRegisterCodes: this.state.rfidRegisterCodes,
                userRegisterCodes: this.state.userRegisterCodes,
                userNotify: this.state.userNotify,
                userDeactivate: this.state.userDeactivate,
                userDelete: this.state.userDelete,
            }).then(value => {
                if (value.data) {
                    this.setState({ saveStatus: "success" });
                }
                else {
                    this.setState({ saveStatus: "failed" });
                }
            }).catch(error => {
                const msg = error.error.message ? error.error.message : "";
                this.props.onAlert(`Could not store connector settings: ${msg}`, "danger");
                this.setState({ saveStatus: "failed" })
            });
        }
    }

    render() {
        const { t } = this.props;
        const { saveStatus, isLoading, rfidRegisterCodes, rfidRegisterCodesValid,
            userDeactivate, userDeactivateValid, userDelete, userDeleteValid,
            userNotify, userNotifyValid, userRegisterCodes, userRegisterCodesValid } = this.state;

        const allValid = rfidRegisterCodesValid && userDeactivateValid && userDeleteValid &&
            userNotifyValid && userRegisterCodesValid;

        return (
            <SaveablePanel
                title="Autodelete / Autoremove Timeouts"
                saveStatus={saveStatus}
                isLoading={isLoading}
                onSave={this.onSave}
                saveDisabled={!allValid}
            >
                <Form isHorizontal
                    onSubmit={(e) => {
                        e.preventDefault();
                        this.onSave();
                    }}
                >
                    <ValidatableInputField
                        inputId='rfid'
                        title='RFID Registration Codes'
                        validateFor={ValidationOptions.Number}
                        value={rfidRegisterCodes.toString()}
                        onChange={(value, valid) => {
                            this.setState({ rfidRegisterCodes: Number(value), rfidRegisterCodesValid: valid })
                        }} 
                    />
                    <ValidatableInputField
                        inputId='urc'
                        title='User Registration Codes'
                        validateFor={ValidationOptions.Number}
                        value={userRegisterCodes.toString()}
                        onChange={(value, valid) => {
                            this.setState({ userRegisterCodes: Number(value), userRegisterCodesValid: valid })
                        }} 
                    />
                    <ValidatableInputField
                        inputId='uin'
                        title='User Inactive Notification'
                        validateFor={ValidationOptions.Number}
                        value={userNotify.toString()}
                        onChange={(value, valid) => {
                            this.setState({ userNotify: Number(value), userNotifyValid: valid })
                        }} 
                    />
                    <ValidatableInputField
                        inputId='iud'
                        title='Inactive User Deactivate'
                        validateFor={ValidationOptions.Number}
                        value={userDeactivate.toString()}
                        onChange={(value, valid) => {
                            this.setState({ userRegisterCodes: Number(value), userDeactivateValid: valid })
                        }} 
                    />
                    <ValidatableInputField
                        inputId='iudel'
                        title='Inactive User Delete (not done atm)'
                        validateFor={ValidationOptions.Number}
                        value={userDelete.toString()}
                        onChange={(value, valid) => {
                            this.setState({ userDelete: Number(value), userDeleteValid: valid })
                        }} 
                    />
                </Form>
            </SaveablePanel>
        );
    }
}

export const TimeoutPanel = withTranslation()(TimeoutPanelImpl);