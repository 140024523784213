import React from 'react';
import { Navigate } from 'react-router-dom';
import { IUser } from './model/IUserAPI';
import { withRouter, WithRouter } from './RouterComponent';

export interface RedirectState {
    from: Location
}

export interface AccessCheckProps extends WithRouter {
    loginPage?: string,
    mainPage?: string,
    role?: string, //TODO: test delete
    currentUser?: IUser,
    children?: React.ReactNode
}

class AccessCheckImpl extends React.Component<AccessCheckProps> {
    render() {
        const { role, location, currentUser } = this.props;

        const loginPage = this.props.loginPage ? this.props.loginPage : "/login";
        const mainPage = this.props.mainPage ? this.props.mainPage : "/";

        // Wait till we loaded user data so we know if we're logged in
        if (currentUser == undefined) {
            return <></>
        }
        else if (currentUser.isLoggedin()) {
            if (role == undefined || currentUser.admin)
                return (this.props.children);
            else
                return (<Navigate to={mainPage} replace />);
        }
        else {
            return (<Navigate to={loginPage} state={{ from: location }} replace />);
        }
    }
}

export const AccessCheck = withRouter(AccessCheckImpl);