import { RemoteConnection, ResultOrError } from "@app/RemoteConnection";
import { ISystemAPI, ServerInfo } from "@app/model/ISystemAPI";

export class SystemAPI implements ISystemAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchServerInfo() {
        return this._connection.getPromise<ServerInfo, void>("server_info");
    }
}