import { RemoteConnection, ResultOrError } from "@app/RemoteConnection";
import { IStatsAPI, BuyCountEntry, StatsEntry, StatsPeriod, StatsResolution, StatsFilterEntry, StatsEntryForFrontend } from "@app/model/IStatsAPI";

export class StatsAPI implements IStatsAPI {
    private _connection: RemoteConnection;

    constructor(connection: RemoteConnection) {
        this._connection = connection;
    }

    public fetchBuyCount(uid: number, from: Date) {
        const url = `stats/buy_count?uid=${uid}&from=${encodeURIComponent(from.toISOString())}`;
        return this._connection.getPromise<BuyCountEntry[], void>(url);
    }
    
    public fetchUserRanking(pid: number, period: StatsPeriod, top_n: number) {
        const url = `stats/user_ranking?pid=${pid}&period=${period}&top_n=${top_n}`;
        return this._connection.getPromise<StatsEntryForFrontend[], void>(url);
    }
    
    public fetchUserFilter() {
        const url = `stats/user_stats_filter`;
        return this._connection.getPromise<StatsFilterEntry[], void>(url);
    }
    
    public fetchProductFilter() {
        const url = `stats/product_stats_filter`;
        return this._connection.getPromise<StatsFilterEntry[], void>(url);
    }

    public fetchProductsStats(uid: number, pid: number[], period: StatsPeriod, resolution: StatsResolution,
            min_count: number) {
        const url = `stats/products_stats?uid=${uid}&pids=${pid}&period=${period}&resolution=${resolution}&min_count=${min_count}`;
        return this._connection.getPromise<StatsEntry[], void>(url);
    }
}