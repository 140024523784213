import React from 'react';
import { IRowHeader, RemoteTableUI } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';

export interface RemoteTableProps {
    onAlert: (title: string, variant: string) => void,
    reloadTriggerCounter?: number,
}

export abstract class RemoteTable<T, Props extends RemoteTableProps> extends React.Component<Props> {
    public state = {
        rows: [] as T[],
        isLoading: true,
        totalRows: 0,
        reloadCounter: 0,
    }

    loadTimer = undefined as (undefined | number);

    onFetchData = (query: TableFilterQuery) => {

        this.loadTimer = window.setTimeout(() => {
            // Call might have been pending, so we called clearTimeout but will still see this callback
            if (this.loadTimer) {
                this.setState({isLoading: true});
            }
        }, 300);
        

        this.fetchRows(query).then(result => {
            if (this.loadTimer) {
                clearTimeout(this.loadTimer);
                this.loadTimer = undefined;
            }

            this.setState({
                rows: result.data!.rows,
                totalRows: result.data!.total,
                isLoading: false
            });
        }).catch(error => {
            if (this.loadTimer) {
                clearTimeout(this.loadTimer);
                this.loadTimer = undefined;
            }
            this.props.onAlert(`Could not load transactions: ${JSON.stringify(error)}`, "danger");
        });
    }

    componentWillUnmount() {
        if (this.loadTimer) {
            clearTimeout(this.loadTimer);
            this.loadTimer = undefined;
        }
    }

    public render() {
        const { isLoading, rows, totalRows} = this.state;
        const rowElems = rows.map(element => this.renderRow(element));

        return (
            <RemoteTableUI
                headers={this.headers()}
                defaultSortIndex={this.defaultSortIndex}
                rows={rowElems}
                reloadTriggerCounter={this.props.reloadTriggerCounter}
                isLoading={isLoading}
                totalRows={totalRows}
                extraFilter={this.getExtraFilter()}
                onFetchData={this.onFetchData}
                defaultRows={this.defaultRows}
                hasFilter={this.hasFilter}
                hasFrom={this.hasFrom}
                hasTo={this.hasTo}
                fetchOnMount={this.fetchOnMount}
            />
        );
    }

    // Customize in subclass
    defaultSortIndex = 0;
    hasFilter = false;
    hasFrom = false;
    hasTo = false;
    defaultRows = 20;
    fetchOnMount = true;

    getExtraFilter() : any {
        return undefined;
    }
    abstract fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<T>, void>>;
    abstract renderRow(row: T): JSX.Element;
    abstract headers(): IRowHeader[];
}