import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { ActionsColumn, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { IUser, IUserAPI } from '@app/model/IUserAPI';
import { CheckCircleIcon } from '@patternfly/react-icons';
import { formatDateStr } from '@app/i18n';

export interface UsersTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    userAPI: IUserAPI,
    onEditUser: (id: number) => void,
    onListTransactions: (id: number) => void,
    onNewTransaction: (id: number) => void,
    onActivateUser: (id: number) => void
    onDeactivateUser: (id: number) => void
}

class UsersTableImpl extends RemoteTable<IUser, UsersTableProps> {

    headers = () => [
        {name: "ID", defaultSort: "desc", sortKey: "id"},
        {name: "Vorname", defaultSort: "asc", sortKey: "firstname"},
        {name: "Nachname", defaultSort: "asc", sortKey: "lastname"},
        {name: "Email", defaultSort: "asc", sortKey: "email"},
        {name: "Anmeldedatum", defaultSort: "asc", sortKey: "register_date"},
        {name: "Letzter Login", defaultSort: "asc", sortKey: "last_login"},
        {name: "Aktiviert", defaultSort: "asc", sortKey: "active"},
        {name: "Snacks", defaultSort: "asc", sortKey: "snack_access"},
        {name: "Admin", defaultSort: "asc", sortKey: "admin"},
        {name: "Guthaben", defaultSort: "asc", sortKey: "balance"},
        // {name: ""},
        {name: ""}
    ] as IRowHeader[];

    defaultSortIndex = 0;
    hasFilter = true;

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IUser>, void>> {
        return this.props.userAPI.fetchUsers(query);
    }

    renderRow(row: IUser): JSX.Element {
        const { onEditUser, onListTransactions, onNewTransaction, onActivateUser, onDeactivateUser } = this.props;
        const { t } = this.props;

        const userActions = [
            {
                title: 'Neue Buchung',
                onClick: () => { onNewTransaction(row.id); }
            },
            {
                title: 'Buchungen',
                onClick: () => { onListTransactions(row.id); }
            },
            {
                title: 'Bearbeiten',
                onClick: () => { onEditUser(row.id); }
            },
            {
                title: row.active ? 'Deaktivieren' : 'Aktivieren',
                onClick: () => {
                    row.active ? onDeactivateUser(row.id) : onActivateUser(row.id)
                }
            }
          ];

        return (<Tr key={row.id}>
            <Td>{row.id}</Td>
            <Td>{row.firstname}</Td>
            <Td>{row.lastname}</Td>
            <Td>{row.email}</Td>
            <Td>{formatDateStr(row.register_date)}</Td>
            <Td>{formatDateStr(row.last_login)}</Td>
            <Td style={{textAlign: 'center'}}>{row.active ? <CheckCircleIcon /> : ""}</Td>
            <Td style={{textAlign: 'center'}}>{row.snack_access ? <CheckCircleIcon /> : ""}</Td>
            <Td style={{textAlign: 'center'}}>{row.admin ? <CheckCircleIcon /> : ""}</Td>
            <Td style={{textAlign: 'right'}}>{t("berta:formats.currency", {val: row.balance})}</Td>
            <Td isActionCell>
                <ActionsColumn
                    items={userActions}
                />
            </Td>
        </Tr>);
    }
}

export const UsersTable = withTranslation()(withRouter(UsersTableImpl));