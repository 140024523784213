import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Modal,
    ModalVariant,
    TextContent,
    Text,
    TextVariants,
    Button,
    Form,
    FormGroup,
    TextInput,
    Alert,
    FormHelperText,
    HelperText,
    HelperTextItem
} from '@patternfly/react-core';
import { IUserAPI } from '@app/model/IUserAPI';
import { withRouter, WithRouter } from '@app/RouterComponent';
import { ExclamationCircleIcon } from '@patternfly/react-icons';

export interface ResetPasswordPageProps extends WithRouter, WithTranslation {
    onFinished: () => void,
    userAPI: IUserAPI
}

class ResetPasswordPageImpl extends React.Component<ResetPasswordPageProps> {
    public state = {
        password: "",
        passwordValidated: undefined as (undefined | "error"),
        repeat: "",
        repeatValidated: undefined as (undefined | "error"),
        errorText: "",
        successText: ""
    }

    validatePassword(password: string) {
        if (password.length >= 6) {
            this.setState({ passwordValidated: undefined });
            return true;
        }
        else {
            this.setState({ passwordValidated: "error" });
            return false;
        }
    }

    validateRepeat(repeat: string) {
        if (this.state.password == repeat) {
            this.setState({ repeatValidated: undefined });
            return true;
        }
        else {
            this.setState({ repeatValidated: "error" });
            return false;
        }
    }

    validateFields() {
        return this.validatePassword(this.state.password) && this.validateRepeat(this.state.repeat);
    }

    confirmForm() {
        const { password } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        let code = params.get('v');
        if (!code)
            code = "";

        if (this.validateFields()) {
            this.props.userAPI.resetPassword(code!, password).then(result => {
                if (result.data) {
                    this.setState({ successText: "berta:passwordReset.Success"});
                }
                else {
                    this.setState({ errorText: "berta:passwordReset.Error"});
                }
            }).catch(error => {
                this.setState({ errorText: this.props.t("berta:restResponses.error") + JSON.stringify(error)});
            });
        }
    }

    render() {
        const { t, onFinished } = this.props;
        const { password, passwordValidated, repeat, repeatValidated, errorText, successText } = this.state;

        document.title = t("berta:passwordReset.pageTitle")

        let errorElement = undefined as (undefined | JSX.Element);
        if (errorText) {
            errorElement = (<Alert variant="danger" title={errorText} style={{ marginBottom: "1.5em" }} />);
        }
        let successElement = undefined as (undefined | JSX.Element);
        if (successText) {
            successElement = (
            <Alert variant="success" title={successText} style={{ marginBottom: "1.5em" }}> 
                <Button variant="plain" onClick={this.props.onFinished}>{t("berta:passwordReset.back")}</Button>
            </Alert>);
        }

        return (
            <Modal
                variant={ModalVariant.small}
                title={t("berta:passwordReset.dialogTitle", "Reset Password")}
                isOpen={true}
                onClose={() => onFinished()}
                showClose={false}
                actions={[
                    <Button key="confirm" variant="primary"
                        onClick={() => {
                            this.confirmForm();
                        }}>{t("berta:passwordReset.changePW")}</Button>
                ]}
            >
                {errorElement}
                {successElement}
                <TextContent>
                    <Text component={TextVariants.p}>
                        {t("berta:passwordReset.hintChange")}
                    </Text>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            this.confirmForm();
                        }}
                    >
                        <FormGroup label={t("inputfields:password")} fieldId='password'>
                            <TextInput id="password" type="password" value={password} validated={passwordValidated}
                                onChange={(_event, value) => {
                                    this.setState({ password: value });
                                    this.validatePassword(value);
                                }}
                            />
                            <FormHelperText hidden={passwordValidated !== "error"}>
                                <HelperText>
                                    <HelperTextItem variant={passwordValidated} {...(passwordValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                        At least 6 characters
                                    </HelperTextItem>
                                </HelperText>
                            </FormHelperText>
                        </FormGroup>
                        <FormGroup label={t("inputfields:repeatPassword")} fieldId='repeat'>
                            <TextInput id="repeat" type="password" value={repeat} validated={repeatValidated}
                                onChange={(_event, value) => {
                                    this.setState({ repeat: value });
                                    this.validateRepeat(value);
                                }}
                            />
                            <FormHelperText hidden={repeatValidated !== "error"}>
                                <HelperText>
                                    <HelperTextItem variant={repeatValidated} {...(repeatValidated === 'error' && { icon: <ExclamationCircleIcon />})}>
                                        {t("inputfields:errors.passwordEqual")}
                                    </HelperTextItem>
                                </HelperText>
                            </FormHelperText>
                        </FormGroup>
                    </Form>
                </TextContent>
            </Modal>
        );
    }
}

export const ResetPasswordPage = withTranslation()(withRouter(ResetPasswordPageImpl));