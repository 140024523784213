import { WithRouter, withRouter } from "@app/RouterComponent";
import { TransactionAPI } from "@app/api/TransactionAPI";
import {
    Button,
    Divider, Flex, FlexItem, MenuToggle, PageSection, Panel, PanelHeader,
    PanelMain, PanelMainBody, Select, SelectOption, Toolbar, ToolbarContent,
    ToolbarItem
} from "@patternfly/react-core";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TransactionTable } from "./MainPage/TransactionTable";
import { ACCOUNTS } from "@app/model/IDBHelpers";
import { IUserAPI } from "@app/model/IUserAPI";
import { CreateTransactionDialog } from "./TransactionsPage/CreateTransactionDialog";
import { matchPath } from "react-router-dom";
import { IStatsAPI } from "@app/model/IStatsAPI";


export interface TransactionsPageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    transferAPI: TransactionAPI,
    statsAPI: IStatsAPI,
    userAPI: IUserAPI
}

class TransactionsPageImpl extends React.Component<TransactionsPageProps>{
    public state = {
        srcSelOpen: false,
        srcSelOption: 0,
        destSelOpen: false,
        destSelOption: 0,
        lockAccountSel: false,
        andOrOpen: false,
        andOrOption: "OR" as ("OR" | "AND" | undefined),
        reloadCounter: 0,
        uid: undefined,
        userName: undefined,
        createDialogOpen: false,
        createDialogReturnRoute: "",
        justLoadData: false,
    }

    onAccountSrcClick = (_event) => {
        this.setState({ srcSelOpen: !this.state.srcSelOpen })
    };

    onAccountSrcSelect = (_event, value) => {
        if (this.state.lockAccountSel)
            return;

        this.setState({
            srcSelOpen: false,
            srcSelOption: value,
        })
    };

    onAndOrClick = (_event) => {
        this.setState({ andOrOpen: !this.state.andOrOpen })
    };

    onAndOrSelect = (_event, value) => {
        if (this.state.lockAccountSel)
            return;

        this.setState({
            andOrOpen: false,
            andOrOption: value,
        });
    }

    onAccountDestClick = (_event) => {
        this.setState({ destSelOpen: !this.state.destSelOpen })
    };

    onAccountDestSelect = (_event, value) => {
        if (this.state.lockAccountSel)
            return;

        this.setState({
            destSelOpen: false,
            destSelOption: value,
        })
    };

    // handleSrcAccountClickOutside = (event: MouseEvent) => {
    //     if (this.state.srcSelOpen && !this..current?.contains(event.target as Node)) {
    //         this.setState({srcSelOpen: false});
    //     }
    // }

    componentDidMount(): void {
        this.matchPathsAndGetUserInfo();
    }

    matchPathsAndGetUserInfo = () => {
        const createGeneral = matchPath("/transactions/create", location.pathname) ? true : false;
        const createUser = matchPath("/transactions/:uid/create", location.pathname) ? true : false;
        const urlParams = new URLSearchParams(this.props.params);
        const uid = urlParams.get('uid') != null ? Number(urlParams.get('uid')) : undefined;
        let accountSel = -1;

        // Take care of get arguments
        if (this.props.location.search != "") {
            var account = this.props.location.search.split("=")[1]
            if (!isNaN(parseFloat(account))) {  // Check for number
                accountSel = Number(account)
            }
        }

        let retRoute = "";

        if (createUser && uid) {
            retRoute = `/transactions/${uid}`;
        }
        if (createGeneral) {
            retRoute = "/transactions"
        }

        if (uid) {
            this.props.userAPI.fetchUser(uid).then((result) => {
                this.setState({ 
                    userName: result.data!.firstname + " " + result.data!.lastname,
                    uid: uid,
                    createDialogOpen: createUser || createGeneral,
                    createDialogReturnRoute: retRoute,
                });
            })
        } else {
            this.setState({
                createDialogOpen: createUser || createGeneral,
                createDialogReturnRoute: retRoute,
            });
        }

        if (accountSel != -1) {
            this.setState({
                srcSelOption: accountSel,
                destSelOption: accountSel,
                lockAccountSel: true,
            });
        } 
        if (accountSel == -1 && !uid) {
            this.setState({
                justLoadData: true
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<TransactionsPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevState.srcSelOption != this.state.srcSelOption ||
            prevState.destSelOption != this.state.destSelOption ||
            prevState.justLoadData != this.state.justLoadData ||
            prevState.uid != this.state.uid) {
            this.setState({ reloadCounter: this.state.reloadCounter + 1 });
        }
    }


    render() {
        const { t, transferAPI, statsAPI, onAlert, navigate, params } = this.props;
        const { reloadCounter, andOrOpen, andOrOption, lockAccountSel,
            srcSelOpen, srcSelOption, destSelOpen, destSelOption,
            uid, userName, createDialogOpen, createDialogReturnRoute } = this.state;

        const accountSelectionOptions = ACCOUNTS.map((val, i) => {return (<SelectOption value={i} key={i}>{val}</SelectOption>)});

        let title = userName ? "Transactions - " + userName : "Transactions"
        document.title = title;

        const destToggle = (toggleRef) => 
        (
            <MenuToggle ref={toggleRef} onClick={this.onAccountDestClick} isExpanded={destSelOpen} 
                    style={{width: '220px'} as React.CSSProperties} isDisabled={lockAccountSel}>
                {ACCOUNTS[destSelOption]}
            </MenuToggle>
        )
        const andOrToggle = (toggleRef) => (
            <MenuToggle ref={toggleRef} onClick={this.onAndOrClick} isExpanded={andOrOpen}
                    style={{ width: '100px' } as React.CSSProperties} isDisabled={lockAccountSel}>
                {andOrOption}
            </MenuToggle>
        )
        const srcToggle = (toggleRef) => (
            <MenuToggle ref={toggleRef} onClick={this.onAccountSrcClick} isExpanded={srcSelOpen} 
                    style={{ width: '220px' } as React.CSSProperties} isDisabled={lockAccountSel}>
                {ACCOUNTS[srcSelOption]}
            </MenuToggle>
        )
        
        const toolbarItems = (
            <React.Fragment>
                <ToolbarItem variant="label">Quellkonto:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={srcToggle}
                        isOpen={srcSelOpen}
                        onSelect={this.onAccountSrcSelect}
                        selected={ACCOUNTS[srcSelOption]}
                        isScrollable={true}
                    >
                        {accountSelectionOptions}
                    </Select>
                </ToolbarItem>
                <ToolbarItem variant="label">Operator:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={andOrToggle}
                        isOpen={andOrOpen}
                        onSelect={this.onAndOrSelect}
                        selected={andOrOption}
                        isScrollable={true}
                    >
                        <SelectOption value="AND" key={1}>AND</SelectOption>
                        <SelectOption value="OR" key={2}>OR</SelectOption>
                    </Select>
                </ToolbarItem>
                <ToolbarItem variant="label">Zielkonto:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={destToggle}
                        isOpen={destSelOpen}
                        onSelect={this.onAccountDestSelect}
                        selected={ACCOUNTS[destSelOption]}
                        isScrollable={true}
                    >
                        {accountSelectionOptions}
                    </Select>
                </ToolbarItem>
                <ToolbarItem>
                    <Button
                        variant="primary"
                        onClick={() => {this.props.navigate(`create`); this.matchPathsAndGetUserInfo();}}
                    >
                        Neue Buchung
                    </Button>
                </ToolbarItem>
            </React.Fragment>
        )

        return (
            <PageSection variant="light" isFilled>
                <CreateTransactionDialog
                    uid={uid}
                    userName={userName}
                    isOpen={createDialogOpen}
                    onClose={() => {
                        navigate(createDialogReturnRoute);
                        this.matchPathsAndGetUserInfo();
                    }}
                    onAlert={onAlert}
                    transferAPI={transferAPI}
                    statsAPI={statsAPI}
                    onTransactionCreated={() => {
                        navigate(createDialogReturnRoute);
                        this.setState({reloadCounter: this.state.reloadCounter + 1})
                        this.matchPathsAndGetUserInfo();
                    }}
                />
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelHeader>{title}</PanelHeader>
                            <Divider />
                            <PanelMain>
                                <PanelMainBody>
                                    <Toolbar>
                                        <ToolbarContent>{toolbarItems}</ToolbarContent>
                                    </Toolbar>

                                    <TransactionTable
                                        onAlert={onAlert}
                                        transferAPI={transferAPI}
                                        reloadTriggerCounter={reloadCounter}
                                        accountDestFilter={destSelOption}
                                        accountSrcFilter={srcSelOption}
                                        accountFilterOperator={andOrOption}
                                        fetchOnMount={false}
                                        showAccounts={true}
                                        uid={uid}
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        )
    }
}

export const TransactionsPage = withTranslation()(withRouter(TransactionsPageImpl));
