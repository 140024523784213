import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import langDE from '@app/locale/de.json';
import langEN from '@app/locale/en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      de: langDE,
      en: langEN
    }
  });

export const supportedLanguages = [
  {"code": "de", "flagIcon": "DE", "name": "Deutsch"},
  {"code": "en", "flagIcon": "GB", "name": "English"},
];

export function resolveLanguageCode(c: string) {
  const res = supportedLanguages.find(({code}) => code === c)
  return res == undefined ? {"code": "de", "flagIcon": "DE", "name": "Deutsch"} : res;
}

export function formatDate(d: Date) {
  return i18n.t("berta:formats.date", 
    { 
      val: d,
      formatParams: {
        val: {
          weekday: "short",
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: "numeric",
          minute: "numeric",
        }
      }
    }
  )
}

export function formatDateStr(d: str) {
  const date = new Date(d);
  return i18n.t("berta:formats.date", 
    { 
      val: date,
      formatParams: {
        val: {
          weekday: "short",
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: "numeric",
          minute: "numeric",
        }
      }
    }
  )
}

export default i18n;
