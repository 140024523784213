
export interface TableFilterQuery {
    filter: string,
    sortBy?: string,
    sortDirection: ("ASC" | "DESC"),
    page: number,
    perPage: number,
    from?: Date,
    to?: Date,
    extra: any
}

export interface TableRowData<T> {
    rows: T[]
    total: number
}

export function getAccountName(id: number): string {
    if (id > ACCOUNTS.length)
        return ACCOUNTS[0];
    return ACCOUNTS[id];
} 

export const ACCOUNTS = [
    "(none)",
    "Girokonto",
    "Barkasse",
    "Kaffeekasse",
    "Kuehlschrankkasse",
    "Nutzerguthaben",
    "Reparaturen",
    "Wareneinkauf Kaffee",
    "Wareneinkauf Kuehlschrank",
    "Restesammler",
    "Stripe-Buchungen",
    "eingezahltes Nutzerguthaben",
    "PayPal-Einzahlungen",
    "Einzahlungsgebuehren",
    "Bareinzahlungen"]