import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelMain,
    PanelMainBody,
    Text,
    TextVariants
} from '@patternfly/react-core';



import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { IStripeLogEntry, IDepositAPI } from '@app/model/IDepositAPI';

export interface StripeLogTableProps extends RemoteTableProps, WithTranslation, WithRouter {
    depositAPI: IDepositAPI
}

class StripeLogTableImpl extends RemoteTable<IStripeLogEntry, StripeLogTableProps> {

    headers = () => [
        {name: "ID", defaultSort: "desc", sortKey: "id"},
        {name: "Status", defaultSort: "asc", sortKey: "status"},
        {name: "Stripe ID", defaultSort: "asc", sortKey: "stripeID"},
        {name: "User ID", defaultSort: "asc", sortKey: "uid"},
        {name: "Created", defaultSort: "asc", sortKey: "created"},
        {name: "Checked", defaultSort: "asc", sortKey: "checked"},
        {name: "Payment URL", defaultSort: "asc", sortKey: "payURL"},
        {name: "Intent ID", defaultSort: "asc", sortKey: "intentID"},
        {name: "Amount", defaultSort: "asc", sortKey: "amount"},
        {name: "Fee", defaultSort: "asc", sortKey: "fee"},
    ] as IRowHeader[];

    defaultSortIndex = 0;
    hasFilter = true;

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IStripeLogEntry>, void>> {
        return this.props.depositAPI.fetchStripeLog(query);
    }
    
    renderRow(row: IStripeLogEntry): JSX.Element {
        let createdString = "-";
        if (row.created != "0001-01-01T00:00:00") {
            const created = new Date(row.created);
            createdString = `${created.toLocaleDateString()} ${created.toLocaleTimeString()}`;
        }

        let checkedString = "-";
        if (row.checked != "0001-01-01T00:00:00") {
            const checked = new Date(row.checked);
            checkedString = `${checked.toLocaleDateString()} ${checked.toLocaleTimeString()}`;
        }
        
        return (<Tr key={row.id}>
            <Td>{row.id}</Td>
            <Td>{row.status}</Td>
            <Td>{row.stripeID}</Td>
            <Td>{row.uid}</Td>
            <Td>{`${createdString}`}</Td>
            <Td>{`${checkedString}`}</Td>
            <Td>{row.payUrl && <Text component={TextVariants.a} href={row.payUrl}>Link</Text>}</Td>
            <Td>{row.intentID}</Td>
            <Td>{row.amount.toFixed(2)} €</Td>
            <Td>{row.fee ? row.fee.toFixed(2) : "-"} €</Td>
        </Tr>);
    }
}

const StripeLogTable = withTranslation()(withRouter(StripeLogTableImpl));


export interface StripeLogPageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    depositAPI: IDepositAPI
}

class StripeLogPageImpl extends React.Component<StripeLogPageProps> {

    render() {
        const { t, depositAPI, onAlert, params, navigate } = this.props;
        document.title = "Stripe Log";


        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelMain>
                                <PanelMainBody>
                                    <StripeLogTable onAlert={onAlert} depositAPI={depositAPI} />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const StripeLogPage = withTranslation()(withRouter(StripeLogPageImpl));