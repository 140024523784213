import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import {
    Flex,
    FlexItem,
    PageSection,
    Panel,
    PanelMain,
    PanelMainBody,
} from '@patternfly/react-core';
import { IUserAPI, IUserRegisterCode } from '@app/model/IUserAPI';


import { RemoteTable, RemoteTableProps } from '@app/ui/RemoteTable';
import { TableFilterQuery, TableRowData } from '@app/model/IDBHelpers';
import { TableText, Td, Tr } from '@patternfly/react-table';
import { IRowHeader } from '@app/ui/RemoteTableUI';
import { ResultOrError } from '@app/RemoteConnection';
import { Button } from '@patternfly/react-core';
import { TrashIcon } from '@patternfly/react-icons';
import { matchPath } from 'react-router-dom';
import { formatDateStr } from '@app/i18n';

export interface UserRegisterCodeTableProps extends RemoteTableProps, WithTranslation  {
    userAPI: IUserAPI
    onRemove: (id: number) => void,
}

class UserRegisterCodeTableImpl extends RemoteTable<IUserRegisterCode, UserRegisterCodeTableProps> {
    public state = {
        rows: [] as IUserRegisterCode[],
        isLoading: true,
        totalRows: 0,
    }

    headers = () => [
        {name: "ID"},
        {name: "UID", defaultSort: "asc", sortKey: "uid"},
        {name: "User Name"},
        {name: "Type", defaultSort: "asc", sortKey: "type"},
        {name: "Code", defaultSort: "asc", sortKey: "code"},
        {name: "Datum", defaultSort: "asc", sortKey: "date"},
        {name: ""},
    ] as IRowHeader[];

    defaultSortIndex = 5;
    hasFilter = true;

    fetchRows(query: TableFilterQuery): Promise<ResultOrError<TableRowData<IUserRegisterCode>, void>> {
        return this.props.userAPI.fetchUserRegisterCodes(query);
    }

    renderRow(row: IUserRegisterCode): JSX.Element {
        const date = new Date(row.date);
        return (<Tr>
            <Td>{row.id}</Td>
            <Td>{row.uid}</Td>
            <Td>{row.full_user_name}</Td>
            <Td>{row.type}</Td>
            <Td>{row.code}</Td>
            <Td>{formatDateStr(row.date)}</Td>
            <Td dataLabel="Delete" modifier="fitContent">
                <TableText>
                    <Button variant="plain" onClick={() => {this.props.onRemove(row.id)}}><TrashIcon /></Button>
                </TableText>
            </Td>
        </Tr>);
    }
}

const UserRegisterCodeTable = withTranslation()(UserRegisterCodeTableImpl);


export interface UserRegisterCodePageProps extends WithTranslation, WithRouter {
    onAlert: (title: string, variant: string) => void,
    userAPI: IUserAPI
}

class UserRegisterCodePageImpl extends React.Component<UserRegisterCodePageProps> {
    public state = {
        reloadCounter: 0
    }

    render() {
        const { t, userAPI, onAlert, params, navigate } = this.props;
        const { reloadCounter } = this.state;

        document.title = "User Register Codes";
        
        const deleteCodeId = Number((params as any).id);
        const deleteCode = matchPath("/users/register_codes/delete/:id", location.pathname) ? true : false;

        if (deleteCode) {
            this.props.userAPI.deleteUserRegisterCode(deleteCodeId);
            this.setState({ reloadCounter: reloadCounter + 1 });
            this.props.onAlert("Registration Code erfolgreich gelöscht", "success");

            this.props.navigate(`/users/register_codes`);
        }

        return (
            <PageSection variant='light' isFilled>
                <Flex direction={{ default: 'column' }}>
                    <FlexItem>
                        <Panel variant="raised">
                            <PanelMain>
                                <PanelMainBody>
                                    <UserRegisterCodeTable 
                                        onAlert={onAlert}
                                        userAPI={userAPI}
                                        reloadTriggerCounter={reloadCounter}
                                        onRemove={(id) => {this.props.navigate(`delete/${id}`);}}
                                    />
                                </PanelMainBody>
                            </PanelMain>
                        </Panel>
                    </FlexItem>
                </Flex>
            </PageSection>
        );
    }
}

export const UserRegisterCodePage = withTranslation()(withRouter(UserRegisterCodePageImpl));