import {
    IStatsAPI, StatsEntryForFrontend, StatsFilterEntry, StatsPeriod,
    StatsPeriodStrings, StatsResolution, StatsResolutionStrings
} from "@app/model/IStatsAPI";
import {
    Chart, ChartAxis, ChartBar, ChartLegend, ChartStack, ChartThemeColor,
    ChartTooltip, ChartVoronoiContainer
} from "@patternfly/react-charts";
import {
    Badge, Bullseye, EmptyState, EmptyStateBody, EmptyStateIcon,
    EmptyStateVariant, Menu, MenuContent, MenuItem, MenuList, MenuToggle, Popper,
    SearchInput, Select, SelectOption, Spinner, Title, Toolbar, ToolbarContent,
    ToolbarFilter, ToolbarItem, getResizeObserver
} from "@patternfly/react-core";
import { FilterIcon, SearchIcon, TintSlashIcon } from "@patternfly/react-icons";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";


export interface ProductRankingChartProps extends WithTranslation {
    onAlert: (title: string, variant: string) => void,
    statsAPI: IStatsAPI,
}


class ProductRankingChartImpl extends React.Component<ProductRankingChartProps>{

    public state = {
        width: 0,
        extraHeight: 0,
        data: undefined as (undefined | Map<string, StatsEntryForFrontend[]>),
        userFilter: undefined as (undefined | StatsFilterEntry[]),
        productFilter: undefined as (undefined | StatsFilterEntry[]),
        isLoading: true,
        isLoadingUsersProducts: true,
        noResults: true,

        // User Filter
        userFilterID: 0,
        userSearchInputValue: "Loading ...",
        userSelAutocompleteOpen: false,
        userSelAutocompleteHint: "",
        userSelAutocompleteOptions: undefined as (undefined | JSX.Element[]),

        // Prodcuts filter
        productsMenuOpen: false,
        productsSelected: [],
        
        // Queries
        resolutionSelection: 3,
        resolutionSelectionOpen: false,
        periodSelection: 3,
        periodSelectionOpen: false,
        minCount: 1,
    }

    containerRef = React.createRef();
    observer = () => {};

    searchInputRef = React.createRef();
    autocompleteRef = React.createRef();
    productsContainerRef = React.createRef();
    productsToggleRef = React.createRef();
    productsSelectionMenuRef = React.createRef();

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    // Page stuff
    handleResize = () => {
        if (this.containerRef.current && this.containerRef.current.clientWidth) {
            this.setState({ width: this.containerRef.current.clientWidth });
        }
    }
    handleLegendAllowWrap = (extraHeight) => {
        if (extraHeight !== this.state.extraHeight) {
            this.setState({ extraHeight });
        }
    }
    getHeight = (baseHeight) => {
        const { extraHeight } = this.state;
        return baseHeight + extraHeight;
    };

    componentDidMount() {
        this.observer = getResizeObserver(this.containerRef.current, this.handleResize);
        this.handleResize();

        // Fetch all users
        this.props.statsAPI.fetchUserFilter().then(result =>
            this.setState({
                userFilter: result.data!, userFilterID: 0
            })
        )
        // and products
        this.props.statsAPI.fetchProductFilter().then(result =>
            this.setState({
                productFilter: result.data!, productsSelected: [],
                isLoadingUsersProducts: false,
                userSearchInputValue: ""
            })
        )

        this.refetchData()
    }

    componentDidUpdate(prevProps: Readonly<UserProductRankingProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.state.userFilterID != prevState.userFilterID || 
            this.state.productsSelected != prevState.productsSelected ||
            this.state.periodSelection != prevState.periodSelection || 
            this.state.resolutionSelection != prevState.resolutionSelection){
            this.refetchData();
        }
    }

    componentWillUnmount() {
        this.observer();
    }


    // Search Input fields
    onSearchInputChange = (_event, newValue) => {
        if (this.state.userFilter === undefined)
            return;

        if ( newValue !== '' && this.searchInputRef) {
            let options = this.state.userFilter
                .filter((option) => (option.text.toLowerCase().includes(newValue.toLowerCase())))
                .map((option) => (
                    <MenuItem itemId={option.id} key={option.text}>
                        {option.text}
                    </MenuItem>
                    )
                );
            if (options.length > 10) {
                options = options.slice(0, 10);
            }

            this.setState({
                userSelAutocompleteHint: options.length === 1 ? options[0].props.key : '',
                userSelAutocompleteOpen: true,
                userSelAutocompleteOptions: options,
                userSearchInputValue: newValue,
                isLoading: true
            })
        } else {
            this.setState({ userSelAutocompleteOpen: false, userSearchInputValue: newValue })
        }
    }

    onSearchInputClear = () => {
        this.setState({
            userSearchInputValue: "",
            userFilterID: 0,
            userSelAutocompleteOpen: false,
            isLoading: true
        })
    }

    onAutoCompleteSelect = (e, itemId) => {
        e.stopPropagation();
        let foundUser = this.state.userFilter?.find(( {id} ) => id == itemId);
        this.setState({ 
            userFilterID: itemId, 
            userSearchInputValue: foundUser ? foundUser.text : "",
            userSelAutocompleteOpen: false,
            isLoading: true,
        })
        this.searchInputRef.current.focus();
    }

    handleUserSelectionKeys = (event) => {
        if (this.state.userSelAutocompleteHint && (event.key === 'Tab' || event.key === 'ArrowRight') && this.searchInputRef.current === event.target) {
            this.setState({
                userSelAutocompleteHint: "",
                userSearchInputValue: "",
                userFilterID: 0,
                userSelAutocompleteOpen: false
            })
            if (event.key === 'ArrowRight')
                event.preventDefault();
        } else if (this.state.userSelAutocompleteOpen && this.searchInputRef.current && this.searchInputRef.current == event.target) {
            if (event.key === 'Escape') {
                this.setState({ userSelAutocompleteOpen: false });
                this.searchInputRef.current.focus();
            } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                const firstElement = this.autocompleteRef.current.querySelector('li > button:not(:disabled)');
                firstElement && firstElement.focus();
                event.preventDefault();
            } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
                this.setState({ userSelAutocompleteOpen: false });
                if (event.key === 'Enter' || event.key === 'Space') {
                    event.preventDefault();
                }
            }
        } else if (this.state.userSelAutocompleteOpen && this.autocompleteRef.current.contains(event.target) && event.key === 'Tab') {
            event.preventDefault();
            this.setState({ userSelAutocompleteOpen: false });
            this.searchInputRef.current.focus();
        }
    }


    // Products filter functions
    onProductSelectionMenuSelect = (_event, itemId) => {
        if (typeof itemId === 'undefined')
            return;

        const productSelected = this.state.productFilter!.find((p) => {
            return p.id == itemId || p.text == itemId;
        });

        if (productSelected) {
            let newSelection: StatsFilterEntry[];
            if (this.state.productsSelected!.includes(productSelected)) { // schon da dann weg
                newSelection = this.state.productsSelected!.filter((s) => s.id !== productSelected.id);
            } else {
                newSelection = [productSelected, ...this.state.productsSelected!]
            }

            this.setState({
                productsSelected: newSelection,
                isLoading: true
            })
        }
    }

    onProductSelectionMenuToggleClick = (_event) => {
        _event.stopPropagation();
        this.setState({
            productsMenuOpen: !this.state.productsMenuOpen
        })
    }

    getProductsMenuList = () => {
        if (!this.state.isLoadingUsersProducts) {
            return this.state.productFilter!.map((entry, idx) => {
                return <MenuItem 
                    hasCheckbox 
                    isSelected={(this.state.productsSelected?.some((p) => p.id === entry.id))}
                    itemId={entry.id.toString()}
                    key={entry.id}
                >
                    {entry.text}
                </MenuItem>
            })
        }
        return <MenuItem hasCheckbox isSelected={false} itemId="0">Loading ...</MenuItem>
    }

    // Helper
    // Source: https://weeknumber.com/how-to/javascript

    // Returns the ISO week of the date.
    getWeek = (d: Date) => {
        let date = new Date(d)
        date.setHours(0, 0, 0, 0);
        // Thursday in current week decides the year.
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        // January 4 is always in week 1.
        var week1 = new Date(date.getFullYear(), 0, 4);
        // Adjust to Thursday in week 1 and count number of weeks from date to week1.
        return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
            - 3 + (week1.getDay() + 6) % 7) / 7);
    }

    // Returns the four-digit year corresponding to the ISO week of the date.
    getWeekYear = (d) => {
        let date = Object.assign({}, d)
        date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
        return date.getFullYear();
    }

    // Online stuff
    refetchData = () => {
        let minProductCount: number = 10;
        if(this.state.productsSelected.length > 0)
            minProductCount = 0;
        if(this.state.userFilterID != 0)
            minProductCount = 0;

        this.props.statsAPI.fetchProductsStats(this.state.userFilterID, 
            this.state.productsSelected.map((v) => (v.id)),
            this.state.periodSelection, this.state.resolutionSelection, 
            minProductCount).then(result => {
                let grouped: Map<string, StatsEntryForFrontend[]> = new Map();
                grouped = this.groupBy(result.data!, entry => entry.productOrName);

                this.setState({
                    data: grouped,
                    isLoading: false, 
                    noResults: grouped.size == 0
                })
            });
    }


    // Chart things
    createEmptyChart = () => {
        let xdata: any[] = [];

        let periodStartDate = new Date();  // set to today and then subtract

        switch(this.state.periodSelection) {
            case StatsPeriod.All:
                periodStartDate.setFullYear(2008); break;
            case StatsPeriod.Last5Years:
                periodStartDate.setMonth(periodStartDate.getMonth() - ((5 * 12) + 11)); break;
            case StatsPeriod.LastYear:
                if (this.state.resolutionSelection == StatsResolution.Daily)
                    periodStartDate.setDate(periodStartDate.getDate() - 366);
                else
                    periodStartDate.setMonth(periodStartDate.getMonth() - 13);
                break;
            case StatsPeriod.LastMonth:
                periodStartDate.setDate(-31); break;
            case StatsPeriod.LastWeek: default:
                periodStartDate.setDate(-7); break;
        }

        // reset and convert to date
        let nowDate = new Date();

        switch(this.state.resolutionSelection){
            case StatsResolution.Daily:
                const dayDiff = Math.round((nowDate.valueOf() - periodStartDate.valueOf()) / (1000 * 3600 * 24))
                for (let cnt = 0; cnt < dayDiff; cnt++) {
                    periodStartDate.setDate(periodStartDate.getDate())
                    xdata.push({ key: cnt, x: `${periodStartDate.getFullYear()}-${periodStartDate.getDate()}`, y: 0, name: "placeholder" })
                }
                break;
            case StatsResolution.Weekly:
                const weekDiff = Math.round((nowDate.valueOf() - periodStartDate.valueOf()) / (1000 * 3600 * 24 * 7))
                for (let cnt = 0; cnt < weekDiff; cnt++) {
                    periodStartDate.setDate(periodStartDate.getDate() + 7)
                    const week = this.getWeek(periodStartDate);
                    xdata.push({ key: cnt, x: `${periodStartDate.getFullYear()}-${week}`, y: 0, name: "placeholder" })
                }
                break;
            case StatsResolution.Monthly:
                const monthDiff = ((nowDate.getFullYear() - periodStartDate.getFullYear()) * 12) + (nowDate.getMonth() - periodStartDate.getMonth());
                for (let cnt = 0; cnt < monthDiff; cnt++) {
                    periodStartDate.setMonth(periodStartDate.getMonth() + 1)
                    xdata.push({ key: cnt, x: `${periodStartDate.getFullYear()}-${periodStartDate.getMonth() + 1}`, y: 0, name: "placeholder" })
                }
                break;
            case StatsResolution.Yearly:
                const numYears = nowDate.getFullYear() - periodStartDate.getFullYear();
                for (let cnt = 0; cnt < numYears; cnt++) {
                    periodStartDate.setFullYear(periodStartDate.getFullYear() + 1)
                    xdata.push({ key: cnt, x: `${periodStartDate.getFullYear()}`, y: 0, name: "placeholder" })
                }
                break;
            default:
        }

        return <ChartBar key="standard-fields" data={xdata} />
    }


    renderUserLines = () => {
        var bar = this.createEmptyChart();

        let productBars: JSX.Element[] = [];
        productBars.push(bar);

        if (! this.state.isLoading) {
            this.state.data!.forEach((v, k) => { // k=name, v=entry
                let chartbardata: any[] = [];

                v.forEach((entry, idx) => {
                    chartbardata.push({ key: idx, x: entry.datetext, y: entry.count, name: entry.productOrName,
                        label: `${entry.datetext}: ${entry.count}x ${k}` })
                })
                
                productBars.push(
                    <ChartBar key={k} width={12} barWidth={12} data={chartbardata} labelComponent={<ChartTooltip constrainToVisibleArea />}/>
                )
            })
        } else {
            return <ChartBar key="no-data" data={[{ key: 0, x: 0, y: 0 }]} labelComponent={<ChartTooltip constrainToVisibleArea />} />;
        }
        return productBars;
    }

    getChartXLabel = () => {
        switch(this.state.resolutionSelection) {
            case StatsResolution.Daily:
                return "Jahrestag";
            case StatsResolution.Weekly:
                return "Kalenderwoche";
            case StatsResolution.Monthly:
                return "Monat";
            case StatsResolution.Yearly:
            default:
                return "Jahr";
        }
    }

    getLegendData = () => {
        var legendData: any[] = []

        if(! this.state.isLoading) {
            this.state.data!.forEach((v, k) => {
                legendData.push({'name': k})
            })
        } else {
            legendData = [{'name': ""}]
        }
        return legendData
    }

    // Resoultion and Period
    onPeriodSelect = (_event, value) => {
        this.setState({
            periodSelectionOpen: false,
            periodSelection: value,
            isLoading: true,
        })
    }

    onResolutionSelect = (_event, value) => {
        this.setState({
            resolutionSelectionOpen: false,
            resolutionSelection: value,
            isLoading: true,
        })
    }

    // Click outside handler
    handleProductSelectionClickOutside = (event: MouseEvent) => {
        if (this.state.productsMenuOpen && !this.productsSelectionMenuRef.current?.contains(event.target as Node)) {
            this.setState({productsMenuOpen: false});
        }
    }
    
    handleUserSelectionClickOutside = (event: MouseEvent) => {
        if (this.state.userSelAutocompleteOpen && !this.searchInputRef.current?.contains(event.target as Node)) {
            this.setState({userSelAutocompleteOpen: false});
        }
    }


    render() {
        const { width, isLoading, noResults, userFilterID,
            userSearchInputValue, userSelAutocompleteOpen, userSelAutocompleteHint, 
            userSelAutocompleteOptions, productsMenuOpen, productsSelected,
            periodSelection, periodSelectionOpen, resolutionSelection, resolutionSelectionOpen } = this.state;
        const height = this.getHeight(400);

        window.addEventListener('click', this.handleUserSelectionClickOutside);
        window.addEventListener('keydown', this.handleUserSelectionKeys);
        window.addEventListener('click', this.handleProductSelectionClickOutside);

        const userSearchInput = (
            <SearchInput
                value={userSearchInputValue}
                onChange={this.onSearchInputChange}
                onClear={this.onSearchInputClear}
                ref={this.searchInputRef}
                hint={userSelAutocompleteHint}
                id="user-autocomplete-search"
            />
        );

        const autocomplete = (
            <Menu ref={this.autocompleteRef} onSelect={this.onAutoCompleteSelect}>
                <MenuContent>
                    <MenuList>{userSelAutocompleteOptions}</MenuList>
                </MenuContent>
            </Menu>
        );

        const userSearch = (
            <Popper
                triggerRef={this.searchInputRef}
                trigger={userSearchInput}
                popperRef={this.autocompleteRef}
                popper={autocomplete}
                isVisible={userSelAutocompleteOpen}
                enableFlip={false}
                appendTo={() => document.querySelector("#user-autocomplete-search")}
            />
        )

        const productsToggle = (
            <MenuToggle
                ref={this.productsToggleRef}
                onClick={this.onProductSelectionMenuToggleClick}
                isExpanded={productsMenuOpen}
                {...(productsSelected.length > 0 && { badge: <Badge isRead>{productsSelected?.length}</Badge>})}
                icon={<FilterIcon />}
                style={{width: '300px'} as React.CSSProperties}
            >Produkte</MenuToggle>
        )

        const productsMenu = (
            <Menu
                ref={this.productsSelectionMenuRef}
                id="products-menu"
                onSelect={this.onProductSelectionMenuSelect}
                selected={(productsSelected?.map((v) => {return v.text}))}
                isScrollable={true}
            >
                <MenuContent>
                    <MenuList>
                        {this.getProductsMenuList()}
                    </MenuList>
                </MenuContent>
            </Menu>
        )

        const productsSelect = (
            <div ref={this.productsContainerRef}>
                <Popper
                    trigger={productsToggle}
                    triggerRef={this.productsToggleRef}
                    popper={productsMenu}
                    popperRef={this.productsSelectionMenuRef}
                    appendTo={this.productsContainerRef.current || undefined}
                    isVisible={productsMenuOpen}
                />
            </div>
        );

        const periodToggle = (toggleRef) => (
            <MenuToggle ref={toggleRef} onClick={() => {this.setState({ periodSelectionOpen: !periodSelectionOpen})}} 
                    isExpanded={periodSelectionOpen} style={{ width: '200px' } as React.CSSProperties}>
                {StatsPeriodStrings[periodSelection]}
            </MenuToggle>
        )

        const resolutionToggle = (toggleRef) => 
        (
            <MenuToggle ref={toggleRef} onClick={() => {this.setState({ resolutionSelectionOpen: !resolutionSelectionOpen})}} 
                    isExpanded={resolutionSelectionOpen} style={{width: '200px'} as React.CSSProperties}>
                {StatsResolutionStrings[resolutionSelection]}
            </MenuToggle>
        )

        const periodSelectionOptions = StatsPeriodStrings.map((val, i) => {return <SelectOption value={i} key={i}>{val}</SelectOption> });
        const resolutionSelectionOptions = StatsResolutionStrings.map((val, i) => {return <SelectOption value={i} key={i}>{val}</SelectOption> });

        return (
            <React.Fragment>
            <Toolbar clearAllFilters={() => {
                this.setState({ productsSelected: [], userSearchInputValue: "", userFilterID: 0, isLoading: true })
            }}>
            <ToolbarContent>
                <ToolbarItem variant="label">User:</ToolbarItem>
                    <ToolbarFilter
                        chips={userFilterID != 0 ? [userSearchInputValue] : ([] as string[])}
                        deleteChip={() => { this.setState({ userFilterID: 0, userSearchInputValue: "", isLoading: true }) }}
                        deleteChipGroup={() => { this.setState({ userFilterID: 0, userSearchInputValue: "", isLoading: true }) }}
                        categoryName="User"
                    >
                        {userSearch}
                    </ToolbarFilter>
                <ToolbarItem>
                </ToolbarItem>
                <ToolbarItem variant="label">Produkte:</ToolbarItem>
                <ToolbarItem>
                    <ToolbarFilter
                        chips={(productsSelected?.map((v) => {return v.text}))}
                        categoryName="Produkte"
                        deleteChip={(category, chip) => this.onProductSelectionMenuSelect(undefined, chip as string)}
                        deleteChipGroup={() => { this.setState({ productsSelected: [], isLoading: true }) }}
                    >
                        {productsSelect}
                    </ToolbarFilter>
                </ToolbarItem>
                <ToolbarItem variant="label">Zeitraum:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={periodToggle}
                        isOpen={periodSelectionOpen}
                        onSelect={this.onPeriodSelect}
                        selected={StatsPeriodStrings[periodSelection]}
                        onOpenChange={(isOpen) => this.setState({periodSelectionOpen: isOpen})}
                    >
                        {periodSelectionOptions}
                    </Select>
                </ToolbarItem>
                <ToolbarItem variant="label">Auflösung:</ToolbarItem>
                <ToolbarItem>
                    <Select
                        toggle={resolutionToggle}
                        isOpen={resolutionSelectionOpen}
                        onSelect={this.onResolutionSelect}
                        selected={StatsResolutionStrings[resolutionSelection]}
                        onOpenChange={(isOpen) => this.setState({resolutionSelectionOpen: isOpen})}
                    >
                        {resolutionSelectionOptions}
                    </Select>
                </ToolbarItem>
            </ToolbarContent>
            </Toolbar>
            <div ref={this.containerRef} style={{ height: height + "px" }}>
                {isLoading && 
                    <Bullseye>
                        <Spinner size="xl" />
                    </Bullseye>
                }
                {!isLoading && noResults &&
                    <Bullseye>
                        <EmptyState variant={EmptyStateVariant.lg}>
                            <EmptyStateIcon icon={SearchIcon} />
                            <Title headingLevel="h2" size="lg">
                                No data found
                            </Title>
                            <EmptyStateBody>Clear all filters and try again.</EmptyStateBody>
                        </EmptyState>
                    </Bullseye>
                }
                {!isLoading && !noResults &&
                <Chart
                    legendAllowWrap={this.handleLegendAllowWrap}
                    legendPosition="bottom"
                    legendComponent={<ChartLegend data={ this.getLegendData() }/>}
                    domainPadding={{ x: [25, 25] }}
                    containerComponent={<ChartVoronoiContainer />}
                    height={height}
                    name="chartuser"
                    padding={{
                        bottom: 60,
                        left: 70,
                        right: 20,
                        top: 20
                    }}
                    themeColor={ChartThemeColor.multiOrdered}
                    width={width}
                >
                    <ChartAxis label={this.getChartXLabel} tickCount={10}/>
                    <ChartAxis dependentAxis showGrid label="Anzahl"/>
                    <ChartStack>
                        { this.renderUserLines() }
                    </ChartStack>
                </Chart>
                }
            </div>
            </React.Fragment>
        );
    }
}

export const ProductRankingChart = withTranslation()(ProductRankingChartImpl);
