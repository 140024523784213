import React from 'react';
import {
    Button,
    ButtonVariant,
    Dropdown,
    DropdownItem,
    MenuToggle,
    Masthead,
    MastheadToggle,
    MastheadMain,
    MastheadBrand,
    MastheadContent,
    Toolbar,
    ToolbarContent,
    ToolbarGroup,
    ToolbarItem,
    PageToggleButton,
    DropdownList,
    ToggleGroup,
    ToggleGroupItem,
    Icon
} from '@patternfly/react-core';
import BarsIcon from '@patternfly/react-icons/dist/esm/icons/bars-icon';
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { IUser } from '@app/model/IUserAPI';
import { MoonIcon, SunIcon } from '@patternfly/react-icons';
import Flag from 'react-flagkit';
import { resolveLanguageCode, supportedLanguages } from '@app/i18n';

export interface DashboardHeaderProps extends WithTranslation, WithRouter {
    currentUser?: IUser,
    onAboutClick: () => void,
    onLogout: () => void
}

class DashboardHeaderImpl extends React.Component<DashboardHeaderProps> {

    public state = {
        userDropDownOpen: false,
        lightDarkSelection: "light-dark-toggle-light",
        languageDropDownOpen: false,
        languageSelection: "en"
    };

    onUserDropdownClick = () => {
        this.setState({ userDropDownOpen: !this.state.userDropDownOpen })
    }

    onUserDropdownSelect = (_event, _value) => {
        this.setState({ userDropDownOpen: false })
    }

    onLanguageDropdownClick = () => {
        this.setState({ languageDropDownOpen: !this.state.languageDropDownOpen })
    }

    onLanguageDropdownSelect = (_event, value) => {
        this.props.i18n.changeLanguage(value);
        this.setState({
            languageDropDownOpen: false,
            languageSelection: value
        })
        this.setLanguagePreference(value)
    }

    // Guide followed: https://web.dev/articles/building/a-theme-switch-component?hl=de#javascript
    getDarkModePreference = () => {
        const darkModeStorageKey = 'theme-preference'
        if (localStorage.getItem(darkModeStorageKey))
            return localStorage.getItem(darkModeStorageKey)
        else
            return window.matchMedia("(prefers-color-scheme: dark)").matches ? 
                'light-dark-toggle-dark' : 
                'light-dark-toggle-light'
    }

    getLanguagePreference = () => {
        const languageStorageKey = 'language-preference'
        if (localStorage.getItem(languageStorageKey))
            return localStorage.getItem(languageStorageKey)
        else
            return false
    }

    setDarkModePreference = (value) => {
        localStorage.setItem('theme-preference', value)
    }

    setLanguagePreference = (value) => {
        localStorage.setItem('language-preference', value)
    }

    componentDidMount(): void {
        this.setState({ lightDarkSelection: this.getDarkModePreference() })   
        const lng = this.getLanguagePreference();
        if(lng) this.props.i18n.changeLanguage(lng);
    }

    onDarkModeChange = (event, _isSelected: boolean) => {
        const id = event.currentTarget.id;
        this.setDarkModePreference(id);
        this.setState({ lightDarkSelection: id })
    }

    render() {
        const { userDropDownOpen, lightDarkSelection, languageDropDownOpen, languageSelection} = this.state;
        const { currentUser, navigate, onAboutClick, t, i18n } = this.props;

        const userDropdownItems = [
                <DropdownItem key="ud-about" onClick={onAboutClick}>{t("berta:dashboard.about")}</DropdownItem>,
                <DropdownItem key="ud-logout" onClick={() => this.props.onLogout()}>{t("berta:dashboard.logout")}</DropdownItem>
        ];

        
        const languageDropdownItems = supportedLanguages.map((lng) => {
            return (
                <DropdownItem key={lng["code"]} value={lng["code"]}>
                    <Flag country={lng["flagIcon"]} /> {lng["name"]}
                </DropdownItem>
            )
        })

        // Auto change dark mode
        window.matchMedia("(prefers-color-scheme: dark")
            .addEventListener("change", ({matches:isDark}) => {
                const newVal = isDark ? "light-dark-toggle-dark" : "light-dark-toggle-light"
                this.setState( {lightDarkSelection: newVal} )
                this.setDarkModePreference(newVal);
            })

        if (lightDarkSelection === "light-dark-toggle-light")
            document.querySelector('html')?.classList.remove('pf-v5-theme-dark');
        else
            document.querySelector('html')?.classList.add('pf-v5-theme-dark');

        const headerToolbar = (
            <Toolbar isFullHeight isStatic>
                <ToolbarContent>
                    <ToolbarGroup
                        align={{ default: "alignRight" }}
                        variant="icon-button-group"
                        spacer={{ default: 'spacerNone', md: 'spacerMd' }}
                    >
                        <ToolbarItem>
                            {currentUser ? currentUser.balance.toFixed(2) + ' €' : ""}
                        </ToolbarItem>
                        <ToolbarGroup variant="icon-button-group" visibility={{ default: 'hidden', lg: 'visible' }}>
                            <ToolbarItem>
                                <Button variant={ButtonVariant.plain}
                                    onClick={() => navigate("/me")}>
                                    <CogIcon />
                                </Button>
                            </ToolbarItem>
                        </ToolbarGroup>
                    </ToolbarGroup>
                    <ToolbarItem>
                        <ToggleGroup>
                            <ToggleGroupItem 
                                buttonId="light-dark-toggle-light"
                                isSelected={lightDarkSelection === "light-dark-toggle-light"}
                                onChange={this.onDarkModeChange}
                                aria-label='light-mode'
                                icon={<Icon size='md'><SunIcon /></Icon>}
                            />
                            <ToggleGroupItem 
                                buttonId="light-dark-toggle-dark"
                                isSelected={lightDarkSelection === "light-dark-toggle-dark"}
                                onChange={this.onDarkModeChange}
                                aria-label='dark-mode'
                                icon={<Icon size='md'><MoonIcon /></Icon>}
                            />
                        </ToggleGroup>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Dropdown
                            onSelect={this.onLanguageDropdownSelect}
                            onOpenChange={(isOpen) => {this.setState({languageDropDownOpen: isOpen})}}
                            toggle={(toggleRef) => (
                                <MenuToggle ref={toggleRef}
                                    variant="plain"
                                    onClick={this.onLanguageDropdownClick}
                                    isExpanded={languageDropDownOpen}>
                                <Flag country={resolveLanguageCode(i18n.language)["flagIcon"]}/>
                                </MenuToggle>  
                            )}
                            isOpen={languageDropDownOpen}
                            shouldFocusToggleOnSelect
                        >
                            <DropdownList>
                            {languageDropdownItems}
                            </DropdownList>
                        </Dropdown>
                    </ToolbarItem>
                    <ToolbarItem>
                        <Dropdown
                            onSelect={this.onUserDropdownSelect}
                            onOpenChange={(isOpen) => (this.setState({userDropDownOpen: isOpen}))}
                            toggle={(toggleRef) => (
                                <MenuToggle ref={toggleRef} 
                                    variant="plain" 
                                    onClick={this.onUserDropdownClick} 
                                    isExpanded={userDropDownOpen}>
                                {currentUser ? currentUser.firstname : ""}
                                </MenuToggle>
                            )}
                            isOpen={userDropDownOpen}
                            shouldFocusToggleOnSelect
                        >
                            <DropdownList>
                                {userDropdownItems}
                            </DropdownList>
                        </Dropdown>
                    </ToolbarItem>
                </ToolbarContent>
            </Toolbar>
        );

        const masthead = (
            <Masthead className="berta-masthead">
                <MastheadToggle>
                    <PageToggleButton variant="plain" aria-label="Global navigation">
                        <BarsIcon />
                    </PageToggleButton>
                </MastheadToggle>
                <MastheadMain>
                    <MastheadBrand onClick={() => this.props.navigate("/")} >
                        BERTA
                    </MastheadBrand>
                </MastheadMain>
                <MastheadContent>{headerToolbar}</MastheadContent>
            </Masthead>
        );

        return masthead;
    }
}

export const DashboardHeader = withTranslation()(withRouter(DashboardHeaderImpl));