import '@patternfly/react-core/dist/styles/base.css';
import '@app/styles/app.css';


import React from 'react';
import {
    Page,
    PageSidebar,
    PageSidebarBody,
} from '@patternfly/react-core';

import { DashboardHeader } from '@app/ui/DashboardHeader';

import { AboutDialog } from './pages/MainPage/AboutDialog';
import { matchPath, Outlet, PathMatch } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithRouter, withRouter } from '@app/RouterComponent';
import { FRONTEND_VERSION } from '@app/config';
import { ISystemAPI } from '@app/model/ISystemAPI';

/**
 * Breadcrumb helper code
 */

export interface BreadCrumbMatcherConfig {
    path: string,
    crumb: (path: string, match: PathMatch<string>) => BreadcrumbData[]
}

export interface BreadcrumbData {
    name: string,
    to: string
}

export function matchBreadCrumb(path: string, config: BreadCrumbMatcherConfig[]): BreadcrumbData[] {
    for (const value of config) {
        const match = matchPath({path: value.path, end: false}, path);
        if (match) {
            return value.crumb(path.substring(match.pathnameBase.length), match);
        }
    }
    return [];
}

/**
 * Navigation builder helper code
 */


export interface NavMatcherConfig {
    path: string,
    pathExact?: boolean,
    nav: (path: string) => [boolean, NavData]
}


export interface NavData {
    name: string,
    to: string,
    role?: string,
    isActive?: boolean,
    children: NavData[]
}


function findNavSet(path: string, config: NavMatcherConfig[][], defaultSet = 0): number {
    for (let index = 0; index < config.length; index++) {
        const set = config[index];
        for (const value of set) {
            const match = matchPath({path: value.path, end: value.pathExact ? true : false}, path);
            if (match) {
                return index;
            }
        }
    }

    return defaultSet;
}

export function matchNav(path: string, config: NavMatcherConfig[][], defaultSet = 0): NavData[] {
    let result = [] as NavData[];

    const activeSet = findNavSet(path, config, defaultSet);
    for (const value of config[activeSet]) {
        const match = matchPath({path: value.path, end: value.pathExact ? true : false}, path);
        const subPath = match ? path.substring(match.pathnameBase.length) : "";
        const [replace, entry] = value.nav(subPath);

        if (replace) {
            return entry.children;
        }
        else {
            entry.isActive = match ? true : false;
            result.push(entry);
        }
    }

    return result;
}

export function filterNav(func: (nav: NavData) => boolean, data: NavData[]): NavData[] {
    let filtered = data.filter(func);
    for (let elem of filtered) {
        elem.children = filterNav(func, elem.children);
    }
    return filtered;
}

/**
 * The dashboard
 */
export interface DashBoardProps extends WithTranslation, WithRouter {
    currentUser?: IUser,
    systemAPI: ISystemAPI,
    breadCrumb?: React.ReactNode,
    navigation?: React.ReactNode,
    onLogout: () => void,
    onAlert: (title: string, variant: string) => void
}


class DashBoardImpl extends React.Component<DashBoardProps> {
    public state = {
        isDropdownOpen: false,
        isKebabDropdownOpen: false,
        isAboutOpen: false,
        backendVersion: ""
    };

    onDropdownToggle = isDropdownOpen => {
        this.setState({ isDropdownOpen });
    };

    onDropdownSelect = event => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    onKebabDropdownToggle = isKebabDropdownOpen => {
        this.setState({ isKebabDropdownOpen });
    };

    onKebabDropdownSelect = event => {
        this.setState({ isKebabDropdownOpen: !this.state.isKebabDropdownOpen });
    };

    onNavSelect = result => {
        this.setState({
            activeItem: result.itemId,
            activeGroup: result.groupId
        });
    };

    render() {
        const { backendVersion } = this.state;
        const { navigate, breadCrumb, navigation, systemAPI, onAlert} = this.props;

        const sideBar = (
            <PageSidebar isSidebarOpen={true}>
                <PageSidebarBody>
                    {navigation}
                </PageSidebarBody>
            </PageSidebar>
        );
        const { isAboutOpen } = this.state;

        return (
            <Page
                header={<DashboardHeader
                    onLogout={this.props.onLogout}
                    currentUser={this.props.currentUser}
                    onAboutClick={() => {
                        systemAPI.fetchServerInfo().then(result => {
                            this.setState({isAboutOpen: true, backendVersion: result.data!.version});
                        }).catch(error => {
                            onAlert(`Could not load server info: ${JSON.stringify(error)}`, "danger");
                        });
                    }}
                    />}
                sidebar={sideBar}
                isManagedSidebar
                breadcrumb={breadCrumb}
            >
                <AboutDialog
                    isOpen={isAboutOpen}
                    onClose={() => this.setState({ isAboutOpen: false })}
                    frontendVersion={FRONTEND_VERSION}
                    backendVersion={backendVersion}
                />
                <Outlet/>
            </Page>
        );
    }
}

export const DashBoard = withTranslation()(withRouter(DashBoardImpl));